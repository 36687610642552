import { toast } from 'react-toastify';

export const reportTypes = {
  TIMELINE_REPORT: 'Index',
  WRITTEN_REPORT: 'Written Report',
  NOTES_TABLE: 'Notes Table',
} as const;

export const displayReportType = (reportType: keyof typeof reportTypes) => {
  return reportTypes[reportType] ?? 'Unknown Report Type';
};

export const validateName = (name: string): boolean => {
  const invalidChars = /[\\/?*:|"<>]/;
  return !invalidChars.test(name);
};

export const validateNameWithToast = (name: string, context: string): boolean => {
  const isValid = validateName(name);
  if (!isValid) {
    toast.error(`${context} cannot contain any of the following characters: / \\ : * ? " < > |`);
  }
  return isValid;
};
export const validateFileOrReportName = (fileName: string): boolean => {
  return validateNameWithToast(fileName, 'Name');
};
