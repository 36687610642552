import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { getBodyPartTagsForDocument, getBodyPartTagsForCase, updateBodyPartTags } from '../../api';
import { useIsFileProcessor } from '../../containers/AccountSettings/useFileProcessing';

export type BodyPartTag = {
  extraction_id: bigint;
  value: string;
};

async function fetchBodyPartTagsForDocument(documentID: bigint): Promise<BodyPartTag[]> {
  const res = await getBodyPartTagsForDocument(documentID);
  return res.data as BodyPartTag[];
}

export default function useBodyPartTags(documentID: bigint) {
  return useQuery<BodyPartTag[]>(['documentBodyPartTags', documentID], () =>
    fetchBodyPartTagsForDocument(documentID),
  );
}

async function fetchBodyPartTagsForCase(caseID: string): Promise<BodyPartTag[]> {
  const res = await getBodyPartTagsForCase(caseID);
  return res.data as BodyPartTag[];
}

export function useBodyPartTagsForCase(caseID: string) {
  return useQuery<BodyPartTag[]>(['caseBodyPartTags', caseID], () =>
    fetchBodyPartTagsForCase(caseID),
  );
}

export function useUpdateBodyPartTags() {
  const queryClient = useQueryClient();
  const isFileProcessor = useIsFileProcessor();

  return useMutation(updateBodyPartTags, {
    onMutate: async ({
      caseID,
      documentID,
      tags,
    }: {
      caseID: string;
      documentID: bigint;
      tags: BodyPartTag[];
    }) => {
      await queryClient.cancelQueries(['documentBodyPartTags', documentID]);
      await queryClient.cancelQueries(['caseBodyPartTags', caseID]);

      queryClient.setQueryData(['documentBodyPartTags', documentID], tags);

      const previousCaseTags: BodyPartTag[] | undefined = await queryClient.getQueryData([
        'caseBodyPartTags',
        caseID,
      ]);
      if (!previousCaseTags) {
        return;
      }
      let newCaseTags = [...previousCaseTags];
      const tagsToAdd = tags.filter(
        (tag) => !previousCaseTags.map((previousTag) => previousTag.value).includes(tag.value),
      );

      if (tagsToAdd.length > 0) {
        newCaseTags = [...newCaseTags, ...tagsToAdd];
        queryClient.setQueryData(['caseBodyPartTags', caseID], newCaseTags);
      }
    },
    onSuccess: () => {
      toast.success(
        'Body Part tags updated successfully',
        isFileProcessor
          ? { position: 'bottom-right', autoClose: 500 }
          : {
              autoClose: 500,
              hideProgressBar: true,
            },
      );
    },
    onError: async ({ documentID, caseID }) => {
      toast.error('A problem occurred when assigning a new body part tag');
      queryClient.invalidateQueries(['documentBodyPartTags', documentID]);
      queryClient.invalidateQueries(['caseBodyPartTags', caseID]);
    },
  });
}
