import { useContext, useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Button, Chip, Switch } from '@mui/material';
import { ZoomIn, ZoomOut, RotateLeft, RotateRight } from '@mui/icons-material';
import {
  DocumentPreviewer,
  IndexPreviewGoToSource,
  TimelineDetailsProps,
} from '../ReportEditor/IndexReports/DocumetPreviewer/DocumentPreviewer';
import CaseHeader from '../Timeline/CaseHeader';
import CaseContext from '../Case/CaseContext';
import { useTimelineList } from '../Timeline/useTimeline';
import { PreviewEntryDetails } from '../ReportEditor/IndexReports/IndexReportTable';
import { EditDocumentDetails } from '../ReportEditor/IndexReports/DocumetPreviewer/EditDocumentDetails';
import useEntryPages from '../Timeline/useEntryPages';
import NavBar from '../../components/common/PdfNavigation/NavBar';
import { useFetchTimelineEntryDocumentDetails } from '../ReportEditor/api-queries/useGetTimelineEntriesForReportSection';
import { useGetDocumentIDs } from './api-hooks/useGetDocumentIDs';
import UpArrowWithTail from '../../components/icons/UpArrowWithTail';
import { useUpdatePageRotationMutation } from '../../__generated__/graphql';
import NavigationButton from '../../components/common/PdfNavigation/Components/NavigationButton';
import Theme from '../../theme';
import useDisplayStore from '../Timeline/useDisplayStore';
import { updateTimelineEntriesForReportSection } from '../../api';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';
import ReportsIcon from '../../components/icons/ReportsIcon';
import DocumentTabs from './DocumentTabs';
import { formatSegmentDate } from '../../library/utilities/useDates';
import DocumentCard from './DocumentCard';

type PageRotation = { id: string; rotation_angle: number };

function Tagging() {
  const { caseInstance } = useContext(CaseContext);
  const logUserActivity = useActivityLog();
  const { caseID, documentID } = useParams();
  const [searchParams] = useSearchParams();
  const currentFileID = searchParams.get('fileID');
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [documentDetails, setDocumentDetails] = useState<{ [key: string]: any }>({});

  const { data: timelineList } = useTimelineList(caseID);
  const timelineID = timelineList?.find((timeline) => timeline.isDefault).id;
  const [zoom, setZoom] = useState(1);
  const [hideBlanks, setHideBlanks] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState('TAGGING');
  const windowSize = useDisplayStore((state) => state.windowSize);

  const { data, isFetching: isFetchingEntryDetails } = useFetchTimelineEntryDocumentDetails(
    caseID ?? '',
    documentID ?? '',
  );

  const {
    data: documentIDs,
    isLoading: areDocumentIDsLoading,
    refetch: refetchDocumentIDs,
  } = useGetDocumentIDs(caseID ?? '', currentFileID ?? '');

  const [previewEntryDetails, setPreviewEntryDetails] = useState<PreviewEntryDetails>({
    sourceID: 0,
    contentTags: [],
    sectionId: '',
    sectionName: '',
    entryDate: '',
    documentName: '',
    author: { id: null, name: null, label: null },
    organization: { id: null, name: null, label: null },
    isHidden: false,
    monetary_total: '',
    extracted_dates: [],
    file_id: '',
  });

  const { isLoading: isLoadingRotations, data: entryPages } = useEntryPages({
    entryID: documentID,
  });

  const [currentPageID, setCurrentPageID] = useState<string | null>(
    entryPages?.length > 0 ? entryPages[0].id : null,
  );

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);

  useEffect(() => {
    if (documentIDs != null && documentIDs.length > 0 && !documentID) {
      const currentEntry = documentIDs[0];
      navigate(`${currentEntry.timelineEntryId}?fileID=${currentFileID}`);
    }
  }, [documentIDs]);

  const { filteredDocuments, totalApproved, totalTagging, totalQaRequired } = useMemo(() => {
    return (
      documentIDs?.reduce(
        (acc, doc) => {
          if (doc.status === selectedTab && doc.status !== 'GROUPING') {
            acc.filteredDocuments.push(doc);
          }
          if (doc.status === 'APPROVED') {
            acc.totalApproved += 1;
          }
          if (doc.status === 'TAGGING') {
            acc.totalTagging += 1;
          }
          if (doc.status === 'QA_REQUIRED') {
            acc.totalQaRequired += 1;
          }
          return acc;
        },
        {
          filteredDocuments: [],
          totalApproved: 0,
          totalTagging: 0,
          totalQaRequired: 0,
        },
      ) || {}
    );
  }, [documentIDs, selectedTab]);

  const viewTaggingTab =
    (filteredDocuments && filteredDocuments.length > 0) ||
    totalApproved > 0 ||
    totalTagging > 0 ||
    totalQaRequired > 0;
  const hasDocuments = filteredDocuments && filteredDocuments.length > 0;

  useEffect(() => {
    if (filteredDocuments?.length) {
      const detailsMap: { [key: string]: any } = {};

      filteredDocuments.forEach((doc) => {
        detailsMap[doc.timelineEntryId] = {
          documentName: doc.documentName,
          entryDate: doc.entryDate,
          totalPages: doc.totalPages,
          pageIDs: doc.pageIDs,
        };
      });

      setDocumentDetails((prev) => ({ ...prev, ...detailsMap }));
    }
  }, [filteredDocuments, caseID]);

  const currentDocument = documentIDs?.find((doc) => doc.timelineEntryId === documentID);

  useEffect(() => {
    if (filteredDocuments?.length) {
      const index = filteredDocuments.findIndex((doc) => doc.timelineEntryId === documentID);
      setCurrentPage(index + 1);
      setNumberOfPages(filteredDocuments.length);
    }
  }, [filteredDocuments, documentID]);

  useEffect(() => {
    if (data) {
      const currentEntry = data;

      setPreviewEntryDetails({
        ...previewEntryDetails,
        author: {
          id: Number(currentEntry.author_id),
          name: currentEntry.author_name,
          label: currentEntry.author_name,
        },
        contentTags:
          currentEntry.content_tags?.map((tag) => ({ value: tag.id, label: tag.value })) ?? [],
        entryDate: currentEntry.entry_date,
        organization: {
          id: Number(currentEntry.org_id),
          name: currentEntry.organization_name,
          label: currentEntry.organization_name,
        },
        sourceID: Number(currentEntry.source_id),
        monetary_total: currentEntry.monetary_total || '',
        extracted_dates: currentEntry.extracted_dates ?? [],
        file_id: currentEntry.file_id ?? '',
        documentName: currentEntry.document_name ?? '',
      });
      setSelectedDate(currentEntry.entry_date);
    }
  }, [data]);
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const previousDocument = filteredDocuments[currentPage - 2];
      handleChangeEntry(previousDocument.timelineEntryId);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numberOfPages) {
      const nextDocument = filteredDocuments[currentPage];
      if (nextDocument) {
        handleChangeEntry(nextDocument.timelineEntryId);
      }
    }
  };

  const handleChangeEntry = (newDocumentID: string) => {
    setCurrentPageID(null);
    setZoom(1);

    const currentEntry = documentIDs?.find((entry) => entry.timelineEntryId === newDocumentID);
    if (currentEntry) {
      navigate(`${currentEntry.timelineEntryId}?fileID=${currentFileID}`);
    }
  };

  const handleDocumentClick = (documentId: string) => {
    navigate(`${documentId}?fileID=${currentFileID}`);
  };

  const onUpdate = async (
    caseID: string,
    entryID: bigint,
    valuesToUpdate: TimelineDetailsProps,
  ) => {
    await updateTimelineEntriesForReportSection({ caseID, entryID, valuesToUpdate });
  };

  const queryClient = useQueryClient();

  const [pageRotationMutation] = useUpdatePageRotationMutation();

  const saveRotation = async (pageID: string, newRotation: number) => {
    try {
      pageRotationMutation({
        variables: {
          data: { pageID, rotation_angle: newRotation },
        },
      });
      queryClient.cancelQueries(['pages', documentID]);
      const previousPages = queryClient.getQueryData(['pages', documentID]);
      const newPagesArr = previousPages as Array<PageRotation>;
      const pageIndex = newPagesArr.findIndex((p) => +p.id === +pageID);
      newPagesArr[pageIndex] = {
        ...newPagesArr[pageIndex],
        rotation_angle: newRotation,
      };
      queryClient.setQueryData(['pages', documentID], newPagesArr);
      toast.success('Page rotation saved');
    } catch (error) {
      toast.error('Failed to save page rotation');
      Sentry.captureException(error);
    }
  };

  const getVisiblePageId = useCallback(() => {
    const images = Array.from(document.querySelectorAll('.image')) as HTMLImageElement[];

    const container = document.getElementById('scrollable-viewer')!;
    const containerRect = container.getBoundingClientRect();
    const containerArea = containerRect.width * containerRect.height;
    let maxVisibleArea = 0;
    let mostVisiblePage: HTMLImageElement | null = null;
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const rect = image.getBoundingClientRect();
      const visibleArea =
        ((Math.min(rect.right, containerRect.right) - Math.max(rect.left, containerRect.left)) *
          (Math.min(rect.bottom, containerRect.bottom) - Math.max(rect.top, containerRect.top))) /
        containerArea;
      if (visibleArea > maxVisibleArea) {
        maxVisibleArea = visibleArea;
        mostVisiblePage = image;
      }
    }
    return mostVisiblePage?.id;
  }, []);

  const rotateClockwise = () => {
    const pageID = getVisiblePageId();
    const currentRotation =
      (entryPages as Array<PageRotation>)?.find((p) => String(p.id) === pageID)?.rotation_angle ??
      0;
    if (pageID) {
      saveRotation(pageID, currentRotation === 270 ? 0 : currentRotation + 90);
    }
  };

  const rotateCounterClockwise = () => {
    const pageID = getVisiblePageId();
    const currentRotation =
      (entryPages as Array<PageRotation>)?.find((p) => String(p.id) === pageID)?.rotation_angle ??
      0;
    if (pageID) {
      saveRotation(pageID, currentRotation === 0 ? 270 : currentRotation - 90);
    }
  };

  const getCurrentPageID = useCallback(
    () => setCurrentPageID(getVisiblePageId() ?? null),
    [getVisiblePageId],
  );

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }
    scrollRef.current.addEventListener('scrollend', getCurrentPageID);
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scrollend', getCurrentPageID);
      }
    };
  }, [scrollRef, entryPages]);

  useEffect(() => {
    if (!currentPageID && entryPages?.length > 0) {
      setCurrentPageID(entryPages[0].id);
    }
  }, [entryPages]);

  const handleSelectedDateChange = (date) => {
    setPreviewEntryDetails((prevDetails) => ({
      ...prevDetails,
      entryDate: date,
    }));
    setSelectedDate(date);
  };

  useEffect(() => {
    if (caseID && currentFileID) {
      logUserActivity({
        activity: 'case:tagging',
        case_id: caseID,
        file_id: currentFileID,
      });
    }
  }, [caseID, currentFileID]);
  const navigateAndRefetch = (caseID: string) => {
    navigate(`../files`);

    queryClient.invalidateQueries(['files', caseID]);
  };

  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
      {viewTaggingTab && documentID ? (
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
            <div style={{ backgroundColor: 'selectedGrey.main' }}>
              <Button
                className="sm-back-button"
                onClick={() => {
                  navigateAndRefetch(caseID ?? '');
                }}
              >
                <UpArrowWithTail
                  style={{
                    color: '#1E407D',
                    marginRight: '0.5rem',
                  }}
                  transform="rotate(270)"
                />
                <span className="sm-back-button-text sm-button-text">Back to files</span>
              </Button>
            </div>
            <DocumentTabs
              totalApproved={totalApproved ?? 0}
              totalTagging={totalTagging ?? 0}
              totallQaRequired={totalQaRequired ?? 0}
              hasDocuments={hasDocuments ?? false}
              selectedTab={selectedTab}
              onChangeTab={(event, newValue) => setSelectedTab(newValue)}
            />
            <div
              style={{
                backgroundColor: 'selectedGrey.main',
                overflowX: 'scroll',
                marginRight: '2px',
                marginLeft: '2px',
                paddingBottom: '65px',
                paddingTop: '0.5rem',
              }}
            >
              {filteredDocuments?.map((entry) => (
                <div key={entry.timelineEntryId}>
                  {documentDetails[entry.timelineEntryId] && (
                    <DocumentCard
                      documentName={documentDetails[entry.timelineEntryId]?.documentName ?? 'N/A'}
                      displayDate={
                        formatSegmentDate(documentDetails[entry.timelineEntryId]?.entryDate) ??
                        'N/A'
                      }
                      totalPages={documentDetails[entry.timelineEntryId]?.totalPages ?? 0}
                      pages={entryPages}
                      expanded={documentID === entry.timelineEntryId}
                      onClick={() => handleDocumentClick(entry.timelineEntryId)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div id="document-display" style={{ width: '50%' }}>
            <div className="viewer-buttons-container">
              <NavigationButton
                onClick={() => rotateCounterClockwise()}
                sx={{ mr: 0.5, opacity: '100%', backgroundColor: 'white !important' }}
                icon={
                  <RotateLeft
                    sx={{
                      fontSize: '1rem',
                    }}
                  />
                }
              />
              <NavigationButton
                onClick={() => rotateClockwise()}
                sx={{ mr: 1.5, opacity: '100%', backgroundColor: 'white !important' }}
                icon={
                  <RotateRight
                    sx={{
                      fontSize: '1rem',
                    }}
                  />
                }
              />

              <NavigationButton
                onClick={() => setZoom(zoom - 0.1)}
                sx={{ mr: 0.5, opacity: '100%', backgroundColor: 'white !important' }}
                icon={
                  <ZoomOut
                    sx={{
                      fontSize: '1rem',
                      color: zoom <= 0.5 ? 'inherit' : '#344054',
                    }}
                  />
                }
                disabled={zoom <= 0.5}
              />
              <NavigationButton
                onClick={() => setZoom(zoom + 0.1)}
                sx={{ mr: 1, opacity: '100%', backgroundColor: 'white !important' }}
                icon={
                  <ZoomIn
                    sx={{
                      fontSize: '1rem',
                      color: zoom >= 1.4 ? 'inherit' : '#344054',
                    }}
                  />
                }
                disabled={zoom >= 1.4}
              />
            </div>
            <NavBar
              currentPage={currentPage}
              numberOfPages={numberOfPages}
              onChangePage={(index: number) => {
                if (filteredDocuments) {
                  handleChangeEntry(filteredDocuments[index - 1].timelineEntryId);
                }
              }}
              page={!isFetchingEntryDetails}
              onPreviousButtonClick={handlePreviousPage}
              onNextButtonClick={handleNextPage}
              nextButtonDisabled={true}
              previousButtonDisabled={true}
              showContentToolbar={false}
              showFileProcessorToolbar={false}
              showZoomRotateToolbar={false}
              nextPageTitle="Document"
            />

            <div
              id="scrollable-viewer"
              className="scrollable-viewer"
              ref={scrollRef}
              style={{
                maxHeight: `${windowSize.height - windowSize.height / 4.8}px`,
                height: '100%',
              }}
            >
              <DocumentPreviewer
                isDocumentDuplicate={false}
                documentId={documentID}
                zoom={zoom}
                currentPageID={currentPageID}
                isLoadingRotations={isLoadingRotations}
                hideBlanks={hideBlanks}
                caseId={caseID ?? ''}
                extractedDates={previewEntryDetails?.extracted_dates ?? []}
                selectedDate={selectedDate}
                setSelectedDate={handleSelectedDateChange}
                pageHeader={false}
              />
            </div>
          </div>

          <div
            style={{
              width: '30%',
              backgroundColor: 'white',
            }}
          >
            <div style={{ marginLeft: '10px', padding: '10px', paddingBottom: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '0.85rem',
                  fontWeight: 600,
                  color: Theme.palette.primary.light,
                  marginRight: '0.5rem',
                  marginBottom: '-0.7rem',
                }}
              >
                Hide Blank Pages and Cover Sheets
                <Switch
                  checked={hideBlanks}
                  onChange={() => {
                    setHideBlanks(!hideBlanks);
                  }}
                  sx={{
                    mr: '0.5rem',
                    color: Theme.palette.primary.light,
                  }}
                />
              </div>
              <IndexPreviewGoToSource
                isFileProcessor={true}
                file_id={previewEntryDetails.file_id}
                documentID={documentID}
                firstPageID={data?.first_page_id ?? null}
                isInTaggingView={true}
              />
            </div>
            <EditDocumentDetails
              handleNextPage={handleNextPage}
              docStatus={currentDocument?.status}
              entryID={Number(documentID)}
              pageID={currentPageID ?? ''}
              caseID={caseID ?? ''}
              currentEntryDetails={previewEntryDetails}
              setCurrentEntryDetails={setPreviewEntryDetails}
              onUpdate={(caseID, entryID, valuesToUpdate) => {
                onUpdate(caseID ?? '', entryID, valuesToUpdate);
              }}
              isFileProcessor={true}
              isFetchingEntryDetails={isFetchingEntryDetails}
              setSelectedDate={handleSelectedDateChange}
              isInTaggingTab={true}
              refetchDocumentIDs={refetchDocumentIDs}
            />
          </div>
        </div>
      ) : (
        areDocumentIDsLoading === false &&
        !viewTaggingTab && (
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              marginTop: '5rem',
              marginBottom: '5rem',
              width: '100%',
              padding: '2rem',
            }}
          >
            <ReportsIcon />
            <div style={{ fontWeight: 600, fontSize: '0.9rem' }}>
              No Documents Ready for Tagging.
            </div>
            <div style={{ fontWeight: 600, fontSize: '0.9rem' }}>
              Once some groupings are built, documents will appear here.
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default Tagging;
