import { useQuery } from '@tanstack/react-query';
import { getCasesForAdmin } from '../../../api';

export function useAdminCases() {
  const { data, isLoading, isError } = useQuery(['getCasesForAdmin'], () => getCasesForAdmin(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
  });

  return { data: data?.data, isLoading, isError };
}
