import { create } from 'zustand';
import _ from 'lodash';
import { persist, createJSONStorage } from 'zustand/middleware';
import { ListPagesSortByEnum } from './types/timelineTypes';

export const initialDocumentSearchStoreState = {
  searchStr: '',
  filters: {
    documentOrder: ListPagesSortByEnum.DateAscending,
    hideDuplicates: false,
    hideViewed: false,
    showFavourited: false,
    filtersApplied: false,
    contentTypes: [],
    subContentTypes: undefined,
    sources: [],
    filteredOverridePages: [],
    confidenceScores: undefined,
    beforeDate: null,
    afterDate: null,
    documentID: [],
  },
  currentCaseID: undefined,
  areFiltersUpdating: false,
  goingToSource: false,
};

const useDocumentSearchStore = create(
  persist(
    (set, get) => ({
      ...initialDocumentSearchStoreState,
      hideDuplicates: true,

      // actions
      setSearchStr: (searchStr) => set(() => ({ searchStr })),
      setFilters: (filters) => set(() => ({ filters })),
      setGoingToSource: (goingToSource) => set(() => ({ goingToSource })),
      areFiltersEqual: async (newFilters) => _.isEqual(get().filters, newFilters),
      setCurrentCaseID: (currentCaseID) => set(() => ({ currentCaseID })),
      setAreFiltersUpdating: (areFiltersUpdating) => set(() => ({ areFiltersUpdating })),
    }),
    {
      name: 'document-timeline-filters-storage-v2',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useDocumentSearchStore;
