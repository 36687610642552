import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from '../../../hooks/useAsync';
import { getIndexListForCase } from '../../../api';

export function useGetIndexList() {
  const [indexList, setIndexList] = useState<
    { id: string; reportName: string; reportType: string; creationDate: Date }[]
  >([]);
  const { caseID = '' } = useParams();
  const [indexListResp] = useAsync(() => getIndexListForCase(caseID), [caseID]);

  useEffect(() => {
    if (indexListResp.status === 'resolved') {
      setIndexList(
        indexListResp.data.data.map((index) => ({
          id: index.id,
          reportName: index.name,
          reportType: 'TIMELINE_REPORT',
          creationDate: index.created_at,
        })),
      );
    }
  }, [indexListResp.status]);

  return {
    indexList,
    isLoading: indexListResp.status === 'pending',
  };
}
