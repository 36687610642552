import { toast } from 'react-toastify';
import InlineEditableText from '../../../components/common/InlineEditableText';

type EditableTimelineEntriesReportTitleProps = {
  value: string;
  updateIndexReportName: (newReportName: string) => Promise<any>;
};

export function EditableTimelineEntriesReportTitle({
  value,
  updateIndexReportName,
}: EditableTimelineEntriesReportTitleProps) {
  return (
    <InlineEditableText
      value={value}
      onSubmit={updateIndexReportName}
      typographyProps={{ fontSize: '1.2rem', fontWeight: 600, marginY: 'auto' }}
      textFieldProps={{ sx: { input: { fontSize: '1.2rem', fontWeight: 600 }, pl: '0.5rem' } }}
    />
  );
}
