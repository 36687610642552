import axios from 'axios';
import { toast } from 'react-toastify';
import apiUrl from '../../library/utilities/apiUrl';
import { validateFileOrReportName } from '../../utils/reportUtils';

export function deleteDocument(caseID, documentID) {
  return axios.post(
    `${apiUrl}deleteDocument`,
    {
      caseID,
      documentID,
    },
    { baseURL: apiUrl },
  );
}

export function renameDocument(caseID, fileID, newFileName) {
  if (!validateFileOrReportName(newFileName)) {
    return Promise.reject('Invalid file name.');
  }
  try {
    return axios.post(
      `${apiUrl}renameDocument`,
      { caseID, fileID, newFileName },
      { baseURL: apiUrl },
    );
  } catch (error) {
    toast.error('Failed to rename document.');
    return Promise.reject(error);
  }
}
