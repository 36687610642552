import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import { GridSortItem } from '@mui/x-data-grid';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { validateFileOrReportName } from '../../../utils/reportUtils';

import {
  getIndexReport,
  updateDuplicateDocument,
  updateTimelineEntriesForReportSection,
  updateDocumentTags,
  renameIndexReport,
  renameIndexReportSection,
} from '../../../api';
import { ChangeTimelineEntryDetailsInput } from '../../../__generated__/graphql';
import useReportsStore from '../useReportsStore';
import { customMultiSort, formatEntryDateToString, shouldUpdateTableRow } from './utils/tableUtils';
import { useAsync } from '../../../hooks/useAsync';
import { useUpdateDocumentName } from '../api-queries/useUpdateDocumentName';
import { useUpdateMonetaryTotal } from '../api-queries/useUpdateMonetaryTotal';
import useUpdateTimelineEntry from '../../Timeline/gql/updateTimelineEntry';
import { TimelineDetailsProps } from './DocumetPreviewer/DocumentPreviewer';
import { useUpdateMarkedImportant } from '../../../components/DocumentScrolling/useUpdateMarkedImportant';

export function useIndexReport(reportId: string) {
  const [shouldShowDuplicates, setShouldShowDuplicates] = useState(false);
  const [areSectionsGrouped, setAreSectionsGrouped] = useState(false);
  const [shouldShowEmptySections, setShouldShowEmptySections] = useState(false);
  const [indexReport, setIndexReport] = useState<any>(null);
  const [uploadDates, setUploadDates] = useState<string[]>([]);
  const [areIndexSectionsEmpty, setAreIndexSectionsEmpty] = useState<
    Record<string, boolean> | undefined
  >(undefined);
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: 'entry_date',
      sort: 'asc',
    },
  ]);
  const [
    { isFirstComparisonDocumentDuplicate, isSecondComparisonDocumentDuplicate },
    setComparisonDocumentsIsDuplicate,
  ] = useState<{
    isFirstComparisonDocumentDuplicate?: boolean;
    isSecondComparisonDocumentDuplicate?: boolean;
  }>({});
  const [documentComparerDocumentIds, setDocumentComparerDocumentIds] = useState<{
    firstDocumentID?: string;
    secondDocumentID?: string;
  }>({});
  const [isDocumentComparerModalOpen, setIsDocumentComparerModalOpen] = useState<boolean>(false);
  const [documentMap, setDocumentMap] = useState<Record<string, any>>({});

  const editDocumentName = useUpdateDocumentName();
  const editMonetaryTotal = useUpdateMonetaryTotal();
  const [editTimelineEntry] = useUpdateTimelineEntry();

  const { currentReport, setCurrentReport } = useReportsStore(
    (state) => ({
      currentReport: state.currentReport,
      setCurrentReport: state.setCurrentReport,
    }),
    shallow,
  );
  const { caseID = '' } = useParams<{ caseID: string }>();
  const queryClient = useQueryClient();
  const [indexReportResp, refreshIndexReport] = useAsync(
    () => getIndexReport(caseID, reportId, !shouldShowDuplicates, areSectionsGrouped, uploadDates),
    [caseID, reportId, shouldShowDuplicates, uploadDates, areSectionsGrouped],
  );

  useEffect(() => {
    if (indexReportResp.status === 'resolved') {
      setIndexReport(indexReportResp.data?.data);
    }
    const documentById: Record<string, any> = {};
    indexReportResp.data?.data?.groups?.forEach((group: any) => {
      group.sections?.forEach((section: any) => {
        section.rows?.forEach((document: any) => {
          documentById[document.id] = {
            ...document,
            section: section,
            group: group,
          };
        });
      });
    });
    setDocumentMap(documentById);
  }, [indexReportResp]);

  useEffect(() => {
    if (indexReport) {
      const areSectionsEmpty = indexReport.groups.every((group: any) =>
        group.sections.every((section: any) => section.rows.length === 0),
      );
      setAreIndexSectionsEmpty(areSectionsEmpty);
    }
  }, [indexReport]);

  const updateIndexReportName = useCallback(
    async (reportName: string) => {
      if (!validateFileOrReportName(reportName)) {
        return;
      }
      try {
        await renameIndexReport(caseID, reportId, reportName);

        setCurrentReport({
          ...currentReport,
          reportName: reportName,
        });
        setIndexReport({
          ...indexReport,
          name: reportName,
        });
        toast.success('Successfully updated report name.');
      } catch (error) {
        toast.error('There was an error updating the report name.');
      }
    },
    [caseID, reportId, currentReport, indexReport],
  );

  const updateIndexReportSectionName = useCallback(
    async (sectionId: string, sectionName: string) => {
      if (!sectionName) {
        return;
      }
      try {
        await renameIndexReportSection(caseID, reportId, sectionId, sectionName);

        const updatedIndexReport = {
          ...indexReport,
          groups: indexReport.groups.map((group) => ({
            ...group,
            sections: group.sections.map((section: any) => {
              if (section.id === sectionId) {
                return {
                  ...section,
                  name: sectionName,
                };
              }
              return section;
            }),
          })),
        };

        setIndexReport(updatedIndexReport);
        toast.success('Successfully updated section name.');
      } catch (error) {
        toast.error('There was an error updating the section name.');
      }
    },
    [indexReport, caseID, reportId],
  );

  const updateIndexRow = async (updatedRow: any, originalRow: any) => {
    // 1. If shouldn't update, return
    if (!shouldUpdateTableRow(updatedRow, originalRow)) {
      return originalRow;
    }

    // 2. If the tags have changed, update them, then trigger refresh as
    // sections may change
    if (!_.isEqual(updatedRow.content_tags, originalRow.content_tags)) {
      try {
        await updateDocumentTags({
          entryID: updatedRow.id,
          tags: updatedRow.content_tags.map((tag) => {
            return { tag_id: Number(tag.id) };
          }),
          tagType: ['Content', 'Specialist'],
        });
        refreshIndexReport();
        toast.success('Successfully updated tags.');
        return updatedRow;
      } catch (error) {
        toast.error('There was an error updating the tags.');
        return originalRow;
      }
    }

    // 3. if the document name has changed, update it
    if (updatedRow.document_name !== originalRow.document_name) {
      try {
        await editDocumentName(updatedRow.id, updatedRow.document_name);
        if (updatedRow.document_name === '' || updatedRow.document_name == null) {
          refreshIndexReport();
        } else {
          updateIndexRowCache(updatedRow.id, {
            document_name: updatedRow.document_name,
          });
        }
        toast.success('Successfully updated document name.');
        return updatedRow;
      } catch (error) {
        toast.error('There was an error updating the document name.');
        return originalRow;
      }
    }

    // 4. if the monetary value has changed, update it
    if (updatedRow.monetary_total !== originalRow.monetary_total) {
      try {
        await editMonetaryTotal(updatedRow.id, updatedRow.monetary_total);
        updateIndexRowCache(updatedRow.id, {
          monetary_total: updatedRow.monetary_total,
        });
        toast.success('Successfully updated monetary total.');
        return updatedRow;
      } catch (error) {
        toast.error('There was an error updating the monetary total.');
        return originalRow;
      }
    }

    // org, author, or date has changed,
    try {
      const formattedEntryDate = formatEntryDateToString(updatedRow.entry_date);
      const updateData: ChangeTimelineEntryDetailsInput = {
        caseID: caseID,
        entryDate: formattedEntryDate,
        entryID: updatedRow.id,
        timelineID: indexReport.timeline_id,
        sourceID: updatedRow.source_id,
        organization: {
          name: updatedRow.organization_name,
        },
        author: {
          name: updatedRow.author_name,
        },
      };
      const resp = await editTimelineEntry(updateData);
      const updatedEntry = resp?.data?.changeTimelineEntryDetails;
      if (updatedEntry) {
        updateIndexRowCache(updatedRow.id, {
          entry_date: formattedEntryDate,
          organization_name: updatedEntry?.organizations?.value ?? null,
          org_id: updatedEntry?.organizations?.id ?? null,
          author_name: updatedEntry?.authors?.value ?? null,
          author_id: updatedEntry?.authors?.id ?? null,
        });
      }
      toast.success('Successfully updated entry details.');
      queryClient.invalidateQueries(['entities', caseID]);
      return { ...updatedRow, entry_date: formattedEntryDate };
    } catch (error) {
      toast.error('There was an error updating the entry details.');
      return originalRow;
    }
  };

  /*
    Custom table sort change implementation, removes 'unselected' sort model
    And instead toggles between 'asc' and 'desc' sort
  */
  const handleSortChange = (newSortModel: GridSortItem[]) => {
    // if unsorted, set to ascending instead
    if (newSortModel.length === 0) {
      newSortModel.push({
        field: sortModel[0].field,
        sort: 'asc',
      });
    }
    setSortModel(newSortModel);
  };

  const updateIndexRowCache = (rowId: string, updatedValues: any) => {
    const { group: selectedGroup, section: selectedSection, ...document } = documentMap[rowId];

    const updatedRow = {
      ...document,
      ...updatedValues,
    };
    const rowIndex = selectedSection.rows.findIndex((document) => document.id === rowId);

    selectedSection.rows[rowIndex] = updatedRow;
    const updatedGroups = indexReport.groups.map((group) => {
      if (group.value !== selectedGroup.value) {
        return group;
      }

      return {
        ...group,
        sections: group.sections.map((section: any) => {
          if (section.id === selectedSection.id) {
            return selectedSection;
          }
          return section;
        }),
      };
    });

    setDocumentMap({
      ...documentMap,
      [rowId]: {
        ...updatedRow,
        group: selectedGroup,
        section: selectedSection,
      },
    });

    setIndexReport({
      ...indexReport,
      groups: updatedGroups,
    });
  };

  const handleUpdateDuplicateDocumentV1Cases = async (entryId: string, isDuplicate: boolean) => {
    await updateDuplicateDocument({
      entryId: entryId,
      isDuplicate,
    });

    if (entryId === documentComparerDocumentIds.firstDocumentID) {
      setComparisonDocumentsIsDuplicate((prevState) => ({
        ...prevState,
        isFirstComparisonDocumentDuplicate: isDuplicate,
      }));
    } else if (entryId === documentComparerDocumentIds.secondDocumentID) {
      setComparisonDocumentsIsDuplicate((prevState) => ({
        ...prevState,
        isSecondComparisonDocumentDuplicate: isDuplicate,
      }));
    }
    updateIndexRowCache(entryId, {
      all_pages_marked_duplicate: isDuplicate,
    });
  };

  const handleSetFirstDocumentComparisonID = useCallback(
    (documentID: bigint, isDocumentDuplicate: boolean) => {
      setDocumentComparerDocumentIds({
        firstDocumentID: String(documentID),
      });
      setComparisonDocumentsIsDuplicate({
        isFirstComparisonDocumentDuplicate: isDocumentDuplicate,
      });
      // this needs to be the section id
    },
    [],
  );

  const handleSetSecondDocumentComparisonIDAndOpenModal = useCallback(
    (documentID: string, isDocumentDuplicate: boolean) => {
      setDocumentComparerDocumentIds({
        ...documentComparerDocumentIds,
        secondDocumentID: String(documentID),
      });
      setComparisonDocumentsIsDuplicate((prevState) => ({
        ...prevState,
        isSecondComparisonDocumentDuplicate: isDocumentDuplicate,
      }));

      setIsDocumentComparerModalOpen(true);
    },
    [documentComparerDocumentIds],
  );

  const clearDocumentComparisonIDs = useCallback(() => {
    setDocumentComparerDocumentIds({});
    setComparisonDocumentsIsDuplicate({});
  }, []);

  const handleUpdateTimelineEntryFromDocumentPreview = async (
    caseID: string,
    entryID: bigint,
    valuesToUpdate: TimelineDetailsProps,
  ) => {
    try {
      await updateTimelineEntriesForReportSection({
        caseID,
        entryID,
        valuesToUpdate,
      });
      // may have to map the the cache values here
      if (
        Object.prototype.hasOwnProperty.call(valuesToUpdate, 'documentName') &&
        (valuesToUpdate.documentName === '' || valuesToUpdate.documentName == null)
      ) {
        refreshIndexReport();
      } else {
        updateIndexRowCache(String(entryID), {
          ...valuesToUpdate,
          ...(valuesToUpdate.organization && {
            organization_name: valuesToUpdate.organization?.label,
            org_id: valuesToUpdate.organization?.id,
          }),
          ...(valuesToUpdate.author && {
            author_name: valuesToUpdate.author?.label,
            author_id: valuesToUpdate.author?.id,
          }),
          ...(valuesToUpdate.documentName && {
            document_name: valuesToUpdate.documentName,
          }),
          ...(valuesToUpdate.date && {
            entry_date: valuesToUpdate.date,
          }),
          ...(valuesToUpdate.source && {
            source_id: valuesToUpdate.source,
          }),
        });
      }
      toast.success('Successfully updated timeline entry.');
    } catch (error) {
      toast.error('There was an error updating the document.');
    }
  };

  const closeDocumentComparerModal = useCallback(() => {
    setIsDocumentComparerModalOpen(false);
    clearDocumentComparisonIDs();
  }, [clearDocumentComparisonIDs]);

  const sortedIndexReport = useMemo(() => {
    const sortField = sortModel[0].field;
    const sortDirection = sortModel[0].sort;
    return {
      ...indexReport,
      groups: indexReport?.groups?.map((group) => ({
        ...group,
        sections: group?.sections?.map((section: any) => {
          const sortedRows = section.rows
            ? customMultiSort(section.rows, sortField, sortDirection)
            : [];
          return {
            ...section,
            rows: sortedRows,
          };
        }),
      })),
    };
  }, [indexReport, sortModel]);

  const markImportant = useUpdateMarkedImportant();

  const handleChangeImportanceMark = useCallback(
    async (documentId: string, newImportance: boolean) => {
      try {
        await markImportant.mutateAsync({
          entryID: documentId,
          markedImportant: newImportance,
        });
        refreshIndexReport();
        toast.success(`Document flagged as ${newImportance ? 'important' : 'not important'}`);
      } catch (error) {
        Sentry.captureException(error);
        toast.error('Error updating importance');
      }
    },
    [],
  );

  const getNextDocument = useCallback(
    (direction: 'next' | 'previous', currentDocumentId: string, sectionId: string) => {
      if (!sortedIndexReport?.groups) {
        return;
      }

      const groupValue = documentMap[currentDocumentId].group.value;

      let currentGroupIndex = sortedIndexReport.groups.findIndex(
        (group) => group.value === groupValue,
      );
      if (currentGroupIndex === -1) {
        return;
      }

      let currentGroup = sortedIndexReport.groups[currentGroupIndex];
      let currentSectionIndex = sortedIndexReport.groups[currentGroupIndex].sections.findIndex(
        (s) => s.id === sectionId,
      );
      if (currentSectionIndex === -1) {
        return;
      }
      let currentSection = currentGroup.sections[currentSectionIndex];
      let documentIndex = currentSection.rows.findIndex(
        (document) => document.id === currentDocumentId,
      );

      if (documentIndex === -1) {
        return;
      }

      if (direction === 'next') {
        documentIndex++;
        while (
          currentSectionIndex < currentGroup.sections.length &&
          currentGroupIndex < sortedIndexReport.groups.length
        ) {
          if (documentIndex < currentSection.rows.length) {
            break;
          } else {
            currentSectionIndex++;
            if (currentSectionIndex < currentGroup.sections.length) {
              currentSection = currentGroup.sections[currentSectionIndex];
              documentIndex = 0;
            } else {
              currentGroupIndex++;
              if (currentGroupIndex < sortedIndexReport.groups.length) {
                currentGroup = sortedIndexReport.groups[currentGroupIndex];
                currentSectionIndex = 0;
                currentSection = currentGroup.sections[currentSectionIndex];
              } else {
                break;
              }
            }
          }
        }
      } else {
        documentIndex--;
        while (currentGroupIndex >= 0 && currentSectionIndex >= 0) {
          if (documentIndex >= 0) {
            break;
          } else {
            currentSectionIndex--;
            if (currentSectionIndex >= 0) {
              currentSection = currentGroup.sections[currentSectionIndex];
              documentIndex = currentSection.rows.length - 1;
            } else {
              currentGroupIndex--;
              if (currentGroupIndex >= 0) {
                currentGroup = sortedIndexReport.groups[currentGroupIndex];
                currentSectionIndex = currentGroup.sections.length - 1;
                if (currentSectionIndex >= 0) {
                  currentSection = currentGroup.sections[currentSectionIndex];
                  documentIndex = currentSection.rows.length - 1;
                }
              } else {
                return;
              }
            }
          }
        }
      }

      return currentSection.rows[documentIndex];
    },
    [sortedIndexReport, documentMap],
  );

  const lastDocumentOfReport = useMemo(
    () =>
      sortedIndexReport?.groups
        ?.slice(-1)[0]
        .sections?.slice()
        .reverse()
        .find((section) => section.rows.length > 0)
        ?.rows.slice(-1)[0],
    [sortedIndexReport, reportId],
  );

  const firstDocumentOfReport = useMemo(
    () =>
      sortedIndexReport?.groups?.[0].sections?.find((section) => section.rows.length > 0)?.rows[0],
    [sortedIndexReport, reportId],
  );

  const configureOptions = [
    {
      label: 'Show Duplicate Documents',
      onClick: () => setShouldShowDuplicates(!shouldShowDuplicates),
      checked: shouldShowDuplicates,
    },
    {
      label: 'Show Empty Sections',
      onClick: () => setShouldShowEmptySections(!shouldShowEmptySections),
      checked: shouldShowEmptySections,
    },
    {
      label: 'Group by Upload Date',
      onClick: () => setAreSectionsGrouped(!areSectionsGrouped),
      checked: areSectionsGrouped,
    },
  ];

  return {
    data: sortedIndexReport,
    isLoading: indexReportResp.status === 'pending' && sortedIndexReport == null,
    isError: indexReportResp.status === 'rejected',
    shouldShowDuplicates,
    setShouldShowDuplicates,
    areSectionsGrouped,
    setAreSectionsGrouped,
    shouldShowEmptySections,
    setShouldShowEmptySections,
    configureOptions,
    uploadDates,
    setUploadDates,
    updateIndexReportName,
    updateIndexReportSectionName,
    areIndexSectionsEmpty,
    sortModel,
    handleSortChange,
    updateIndexRow,
    updateIndexRowCache,
    handleUpdateDuplicateDocumentV1Cases,
    isFirstComparisonDocumentDuplicate,
    isSecondComparisonDocumentDuplicate,
    setComparisonDocumentsIsDuplicate,
    clearDocumentComparisonIDs,
    closeDocumentComparerModal,
    handleSetFirstDocumentComparisonID,
    handleSetSecondDocumentComparisonIDAndOpenModal,
    isDocumentComparerModalOpen,
    documentComparerDocumentIds,
    setDocumentComparerDocumentIds,
    handleUpdateTimelineEntryFromDocumentPreview,
    refreshIndexReport,
    handleChangeImportanceMark,
    getNextDocument,
    lastDocumentOfReport,
    firstDocumentOfReport,
  };
}
