import { KeyboardArrowDown } from '@mui/icons-material';
import { Autocomplete, Box, Paper, Stack, TextField, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlledFilterCheckbox } from './ConfigurableViewFilter';

function FilterMenuAutoComplete(props) {
  const { autoCompleteOptions, control, name, getSubItemStatuses, itemNames, getItemStatus } =
    props;
  const { setValue, getValues } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = (e) => {
    if (!e.defaultPrevented) {
      setIsOpen(false);
    }
  };

  const formattedName = `${name}${name !== 'source' && name !== 'file' ? '' : 's'}`;

  const selectAll = (event) => {
    event.preventDefault();
    itemNames.forEach((item) => setValue(item, true, { shouldDirty: true }));
  };

  const clearAll = (event) => {
    event.preventDefault();
    itemNames?.forEach((item) => setValue(item, false, { shouldDirty: true }));
  };

  const currentSelectedValues = getValues();
  const selectedValuesPlaceholder = useMemo(() => {
    const arrayOfSelections = Object.entries(currentSelectedValues[name]?.items ?? []);
    const selected = [];
    const subSelected = [];
    arrayOfSelections.forEach((item) => {
      if (item[1] === true) {
        const selectedOption = autoCompleteOptions.find((option) =>
          typeof option.value === 'number'
            ? option.value === Number(item[0])
            : option.value === item[0],
        );
        if (selectedOption) {
          selected.push(selectedOption.label);
        }
      }
    });

    if (selected.length === autoCompleteOptions.length) {
      return `All ${formattedName}`;
    }

    //Get names of the sub content values selected for display
    if (name === 'content') {
      const subItems = currentSelectedValues.content?.subItems;

      if (subItems) {
        const contentNames = Object.keys(subItems);

        // eslint-disable-next-line no-restricted-syntax
        for (const contentName of contentNames) {
          const content = subItems[contentName];

          Object.entries(content).forEach(([subContentName, isSelected]) => {
            if (isSelected) {
              const parentContent = autoCompleteOptions.find(
                (option) => option.tag_id === Number(contentName),
              );
              const selectedSubOption = parentContent?.subItems.find(
                (subContent) => subContent.tag_id === Number(subContentName),
              );
              if (selectedSubOption) {
                subSelected.push(selectedSubOption.label);
              }
            }
          });
        }
      }
    }
    if (selected.concat(subSelected).length > 0) {
      return selected.concat(subSelected).join(', ');
    }
    return `No ${formattedName} selected`;
  }, [currentSelectedValues, name, autoCompleteOptions]);

  const handleChange = (e) => {
    e.preventDefault();
  };

  const allOptionsForSearching = useMemo(() => {
    let options = [];
    const sortedMainOptions = autoCompleteOptions.sort((a, b) =>
      a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
    );
    sortedMainOptions.forEach((option) => {
      options.push(option);
      if (option.subItems?.length > 0) {
        options = [...options, ...option.subItems];
      }
    });
    return options;
  }, [autoCompleteOptions, currentSelectedValues]);

  const renderPaperComponent = (paperProps) => {
    const { children, ...restPaperProps } = paperProps;
    return (
      <Paper {...restPaperProps}>
        <Box
          sx={{
            display: 'flex',
            ml: '0.5rem',
            mt: '0.5rem',
            flexDirection: 'row',
          }}
          onMouseDown={(e) => e.preventDefault()}
        >
          <Typography
            sx={{
              color: 'primary.light',
              fontSize: '0.7rem',
              cursor: 'pointer',
            }}
            onClick={selectAll}
          >
            Select all
          </Typography>
          <Typography
            sx={{
              color: 'primary.light',
              fontSize: '0.7rem',
              ml: '1.5rem',
              cursor: 'pointer',
            }}
            onClick={clearAll}
          >
            Clear all
          </Typography>
        </Box>
        {children}
      </Paper>
    );
  };

  const getItemIndeterminateStatus = (item) => {
    const itemName = item.value;
    if (item.parent_tag_id != null || getItemStatus(itemName) === true) {
      return false;
    }
    return item.subItems && getSubItemStatuses(item).some((subItem) => subItem);
  };

  return (
    <Autocomplete
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      options={allOptionsForSearching}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Stack ml={4} mt={0} key={option.value}>
          <Box
            sx={{ marginLeft: option.parent_tag_id && '2rem' }}
            onClick={() => {
              if (option.subItems?.length) {
                const selected = getSubItemStatuses(option).some((subItem) => subItem);
                option.subItems.forEach((subItem) =>
                  setValue(`${name}.subItems.${option.value}.${subItem.value}`, !selected),
                );
              } else {
                const selected = getValues(
                  `${name}.subItems.${option.parent_tag_id}.${option.value}`,
                );
                setValue(`${name}.subItems.${option.parent_tag_id}.${option.value}`, !selected);
              }
            }}
          >
            <ControlledFilterCheckbox
              key={option.id}
              name={
                option.parent_tag_id
                  ? `${name}.subItems.${findParentValue(
                      allOptionsForSearching,
                      option.parent_tag_id,
                    )}.${option.value}`
                  : `${name}.items.${option.value}`
              }
              indeterminate={getItemIndeterminateStatus(option)}
              label={<Box onClick={(e) => e.preventDefault()}>{option.label}</Box>}
              control={control}
            />
          </Box>
        </Stack>
      )}
      popupIcon={<KeyboardArrowDown />}
      freeSolo
      forcePopupIcon
      onChange={handleChange}
      PaperComponent={renderPaperComponent}
      renderInput={(params) => (
        <TextField
          {...params}
          name="filter-search"
          onClick={(e) => {
            e.preventDefault();
          }}
          placeholder={isOpen ? `Select ${formattedName} or search` : selectedValuesPlaceholder}
        />
      )}
      sx={{
        ...filterMenuStyles.autocomplete,
      }}
    />
  );
}

export default FilterMenuAutoComplete;

const filterMenuStyles = {
  autocomplete: {
    pb: '4px',
    '& .MuiInputBase-root': {
      fontSize: '0.8rem',
      margin: '0.2rem 0 0 0',
      marginBottom: 0,
      padding: 0,
      paddingLeft: 0.7,
    },
    '& .MuiAutocomplete-endAdornment svg': {
      color: '#667085',
    },
    '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
      fontSize: '12px',
    },
  },
};

const findParentValue = (options, parentID) =>
  options.find((option) => option.tag_id === parentID)?.value;
