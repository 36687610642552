import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export default function IconUnlock(props: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.83337 8.33333V6.66667C5.83337 4.36548 7.69885 2.5 10 2.5C11.7086 2.5 13.177 3.52841 13.82 5M10 12.0833V13.75M7.33337 17.5H12.6667C14.0668 17.5 14.7669 17.5 15.3017 17.2275C15.7721 16.9878 16.1545 16.6054 16.3942 16.135C16.6667 15.6002 16.6667 14.9001 16.6667 13.5V12.3333C16.6667 10.9332 16.6667 10.2331 16.3942 9.69836C16.1545 9.22795 15.7721 8.8455 15.3017 8.60582C14.7669 8.33333 14.0668 8.33333 12.6667 8.33333H7.33337C5.93324 8.33333 5.23318 8.33333 4.6984 8.60582C4.22799 8.8455 3.84554 9.22795 3.60586 9.69836C3.33337 10.2331 3.33337 10.9332 3.33337 12.3333V13.5C3.33337 14.9001 3.33337 15.6002 3.60586 16.135C3.84554 16.6054 4.22799 16.9878 4.6984 17.2275C5.23318 17.5 5.93324 17.5 7.33337 17.5Z"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
