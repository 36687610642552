import { useMemo } from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  Divider,
  TextField,
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import AutoCompleteRenderOption from '../../../../components/common/Autocomplete/AutoCompleteRenderOption';
import useEntities from '../../../Timeline/gql/useEntities';

export function EntityEditCell(
  params: GridRenderCellParams & {
    entityType: 'organization' | 'author';
    caseID: string;
    entryID?: string;
  },
) {
  const filter = createFilterOptions();

  const entityLabelByType = {
    organization: 'Organization',
    author: 'Author',
  } as const;

  const {
    sortedOrgsForCase,
    sortedOrgsForEntry,
    allOrgs,
    sortedAuthorsForCase: sortedPeopleForCase,
    sortedAuthorsForEntry: sortedPeopleForEntry,
    allAuthors: allPeople,
    isLoading: entitiesLoading,
  } = useEntities(params.caseID, params.row.id);

  const entityLabel = useMemo(
    () => entityLabelByType[params.entityType] ?? 'Entities',
    [params.entityType],
  );

  const allOptions = useMemo(() => {
    if (params.entityType === 'organization') {
      return allOrgs;
    }
    return allPeople;
  }, [allOrgs, allPeople, params.entityType]);

  const sortedEntitiesForCase = useMemo(() => {
    if (params.entityType === 'organization') {
      return sortedOrgsForCase;
    }
    return sortedPeopleForCase;
  }, [sortedOrgsForCase, sortedPeopleForCase, params.entityType]);

  const sortedEntitiesForEntry = useMemo(() => {
    if (params.entityType === 'organization') {
      return sortedOrgsForEntry;
    }
    return sortedPeopleForEntry;
  }, [sortedOrgsForEntry, sortedPeopleForEntry, params.entityType]);

  const groupedOptions = useMemo(() => {
    const allOptions = [];

    if (sortedEntitiesForEntry) {
      sortedEntitiesForEntry.forEach((option) => {
        allOptions.push({ ...option, source: `Suggested ${entityLabel}s` });
      });
    }
    if (sortedEntitiesForCase) {
      sortedEntitiesForCase.forEach((option) => {
        allOptions.push({ ...option, source: `Other ${entityLabel}s` });
      });
    }

    return allOptions;
  }, [sortedEntitiesForCase, sortedEntitiesForEntry]);

  const selectedValue = useMemo(() => {
    return allOptions?.find((option) => option.value === params.value);
  }, [params.value, allOptions]);

  const selectedValueInGroupedOptions = useMemo(() => {
    return groupedOptions.find((option) => option.value === selectedValue?.value) ?? null;
  }, [groupedOptions, selectedValue]);

  const handleCommit = () => {
    params.api.stopCellEditMode({ id: params.id, field: params.field });
  };

  const handleInputChange = (event, newValue) => {
    params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue }, event);
  };

  const groupBySource = (option) => option.source;

  if (entitiesLoading) {
    return <CircularProgress />;
  }

  return (
    <Autocomplete
      freeSolo
      handleHomeEndKeys
      onBlur={handleCommit}
      onInputChange={handleInputChange}
      value={selectedValueInGroupedOptions}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const matchingOption = options.find((option) => option.value === params.inputValue);

        if (matchingOption || params.inputValue === '') {
          return filtered;
        }
        filtered.push({
          value: params.inputValue,
          source: `New ${entityLabel}`,
          isNewValue: true,
        });
        return filtered;
      }}
      groupBy={groupBySource}
      renderGroup={(params) => [
        <Box
          key={params.key}
          sx={{ fontSize: '0.9rem', ml: '0.5rem', mt: '0.5rem', color: 'dropdown.subgroupHeader' }}
        >
          {params.group}
        </Box>,
        <Divider sx={{ color: 'dropdown.subgroupHeader' }} />,
        params.children,
      ]}
      sx={{
        minWidth: '100%',
        minHeight: 'fit-content',
        overflowY: 'visible',
        my: 'auto',
        border: 'none',
        alignContent: 'center',
        mx: 'auto',
        '& .MuiAutocomplete-inputRoot': {
          border: 'none',
          paddingY: 0,
          paddingX: 0,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none', // Removes border
          },
          '&:hover fieldset': {
            border: 'none', // Removes border on hover
          },
          '&.Mui-focused fieldset': {
            border: 'none', // Removes border when focused
          },
        },
      }}
      options={groupedOptions}
      renderInput={(params) => <TextField autoFocus {...params} />}
      getOptionLabel={(option: any) => option?.value ?? option ?? ''}
      renderOption={(props, option: any) => <AutoCompleteRenderOption option={option} {...props} />}
    />
  );
}
