import { Divider, Tooltip, Stack, AppBar, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import PageNavigator from './Components/PageNavigator';
import HorizontalToolBar from '../../DocumentScrolling/HorizontalToolbar';
import PageContentToolbar from '../../DocumentScrolling/PageContentToolbar';
import NavigationButton from './Components/NavigationButton';
import FileProcessorToolbar from '../../DocumentScrolling/FileProcessorToolbar';
import { CachedTimelineEntryWrapper } from '../../DocumentScrolling/hooks/useCachedTimelineEntry';

export default function NavBar({
  onPreviousButtonClick,
  onNextButtonClick,
  previousButtonDisabled,
  nextButtonDisabled,
  currentPage,
  numberOfPages,
  onChangePage,
  nextPageButtonDisabled = null,
  previousPageButtonDisabled = null,
  page,
  showContentToolbar,
  showFileProcessorToolbar,
  showZoomRotateToolbar,
  nextTitle = 'Document',
  nextPageTitle = 'Page',
}) {
  return (
    <CachedTimelineEntryWrapper entryID={page?.entryID}>
      <AppBar
        position="static"
        sx={{
          width: '100%',
          margin: 'auto',
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: 'none',
          height: showFileProcessorToolbar ? '130px' : '100px',
          borderRadius: 0,
          position: 'relative',
          left: '-2px',
          backgroundColor: 'selectedGrey.main',
        }}
      >
        <Stack direction="row" justifyContent="center" pt=".5rem" mb="2px">
          <Box
            sx={{
              color: 'selectedGrey.main',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Tooltip title={`Previous ${nextTitle}`} placement="top">
              <span>
                <NavigationButton
                  id="navbar-previous-button"
                  onClick={onPreviousButtonClick}
                  icon={<KeyboardDoubleArrowLeft sx={{ fontSize: '1rem' }} />}
                  disabled={previousButtonDisabled}
                  sx={{ marginRight: '.5rem' }}
                />
              </span>
            </Tooltip>
            <PageNavigator
              numOfPages={numberOfPages}
              currentPage={currentPage}
              onChangePage={onChangePage}
              nextPageButtonDisabled={nextPageButtonDisabled}
              previousPageButtonDisabled={previousPageButtonDisabled}
              navigateType={nextPageTitle}
            />
            <Tooltip title={`Next ${nextTitle}`} placement="top">
              <span>
                <NavigationButton
                  id="navbar-next-button"
                  onClick={onNextButtonClick}
                  icon={<KeyboardDoubleArrowRight sx={{ fontSize: '1rem' }} />}
                  disabled={nextButtonDisabled}
                  sx={{ marginLeft: '.5rem' }}
                />
              </span>
            </Tooltip>
          </Box>
          {showZoomRotateToolbar && (
            <>
              <Divider orientation="vertical" sx={{ marginX: '15px' }} />
              <HorizontalToolBar />
            </>
          )}
        </Stack>
        <Divider sx={{ marginX: '14px', marginY: '4px' }} />
        <Stack direction="row" justifyContent="space-between">
          <Box sx={{ flex: '50%', height: '28px' }}>
            {page && showFileProcessorToolbar && <FileProcessorToolbar page={page} />}
            {page && showContentToolbar && <PageContentToolbar page={page} />}
          </Box>
        </Stack>
      </AppBar>
    </CachedTimelineEntryWrapper>
  );
}

NavBar.defaultProps = {
  previousButtonText: 'Previous Entry',
  nextButtonText: 'Next Entry',
  currentPage: 1,
  numberOfPages: 1,
  previousButtonDisabled: false,
  nextButtonDisabled: false,
  onPreviousButtonClick: null,
  onNextButtonClick: null,
  onChangePage: null,
  smallButtons: false,
  barWidth: '794px',
};

NavBar.propTypes = {
  previousButtonText: PropTypes.string,
  nextButtonText: PropTypes.string,
  currentPage: PropTypes.number,
  numberOfPages: PropTypes.number,
  previousButtonDisabled: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool,
  onPreviousButtonClick: PropTypes.func,
  onNextButtonClick: PropTypes.func,
  onChangePage: PropTypes.func,
  smallButtons: PropTypes.bool,
  barWidth: PropTypes.string,
};
