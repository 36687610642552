import { useQuery } from '@tanstack/react-query';
import { getCaseFileDocumentIDs } from '../../../api';

type DocumentIDEntry = {
  timelineEntryId: string;
  status: string;
  documentName: string;
  entryDate: Date;
  totalPages: number;
  pageIDs: number[];
};

export function useGetDocumentIDs(
  caseID?: string,
  fileID?: string,
): {
  data: DocumentIDEntry[] | undefined;
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
} {
  const { data, isLoading, isError, refetch } = useQuery(
    ['getCaseFileDocumentIDs', fileID],
    () => getCaseFileDocumentIDs(fileID as string, caseID as string),
    {
      enabled: !!fileID,
    },
  );

  return { data: data?.data, isLoading, isError, refetch };
}
