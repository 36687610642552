import { useState, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Box, Button, Stack, Typography } from '@mui/material';
import HideSourceIcon from '@mui/icons-material/HideSource';
import Loading from '../common/Loading';
import useEntities from '../../containers/Timeline/gql/useEntities';
import { apiBaseUrl } from '../../config/apiConfig';
import useTimelineStore from '../../containers/Timeline/useTimelineStore';

interface Org {
  id: string;
  value: string;
  origin: string;
  hidden: boolean;
}

type PropTypes = {
  caseID: string;
};

export default function TableOrg(props: PropTypes) {
  const { caseID } = props;
  const { hiddenEntities, setHiddenEntities } = useTimelineStore(
    (state) => ({
      hiddenEntities: state.hiddenEntities,
      setHiddenEntities: state.setHiddenEntities,
    }),
    shallow,
  );

  const [orgSetData, setOrgData] = useState<Org[]>([]);
  const [loading, setLoading] = useState(true);

  const columnsOrg = ['Organization Name', 'Created By', 'Action'];

  const { allOrgs, isLoading: loadingOrgData } = useEntities(caseID);

  const [hiddenOrgs, setHiddenOrgs] = useState<string[]>([]);

  useEffect(() => {
    if (allOrgs) {
      const updatedOrgData = allOrgs.map((org: Org) => {
        if (!Array.isArray(hiddenOrgs) || !org.value) {
          return org;
        }
        const isHiddenFromBackend = org.hidden;
        const isHiddenFromLocal = hiddenOrgs.includes(org.value);
        return { ...org, hidden: isHiddenFromBackend || isHiddenFromLocal };
      });

      setOrgData(updatedOrgData);
      setLoading(false);
    } else {
      setOrgData([]);
      setLoading(false);
    }
  }, [allOrgs, hiddenOrgs]);

  useEffect(() => {
    const fetchHiddenEntities = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/v1/cases/${caseID}/entities/hidden-organizations`,
          {
            headers: { Authorization: `Bearer ${axios.defaults.headers.common.Authorization}` },
          },
        );

        setHiddenOrgs(response.data.hiddenEntities);
      } catch (error) {
        console.error('Error fetching hidden entities:', error);
      }
    };

    fetchHiddenEntities();
  }, [caseID]);

  if (loadingOrgData || loading) {
    return <Loading text="Loading Organizations..." />;
  }

  const handleToggleHideEntity = async (entityType: string, value: string, entityValue: string) => {
    try {
      if (entityType === 'organization') {
        const updatedData = orgSetData.map((org) => {
          if (org.value === entityValue) {
            return { ...org, hidden: true };
          }
          return org;
        });

        setOrgData(updatedData);

        const isEntityHidden = hiddenEntities.includes(entityValue);

        if (isEntityHidden) {
          toast.info('Entity is already hidden', {
            toastId: 'entity-already-hidden',
          });
          return;
        }

        const updatedHiddenEntities = [...hiddenEntities, entityValue];
        setHiddenEntities(updatedHiddenEntities);

        const response = await axios.put(
          `${apiBaseUrl}/api/v1/cases/${caseID}/entities/updateOrganizationStatus`,
          {
            caseID,
            value: entityValue,
            hidden: true,
          },
          { headers: { Authorization: `Bearer ${axios.defaults.headers.common.Authorization}` } },
        );

        if (response.data && response.data.hidden) {
          const updatedHiddenEntity = entityValue;
          const updatedHiddenEntities = [...hiddenEntities, updatedHiddenEntity];
          setHiddenEntities(updatedHiddenEntities);
        }
        toast.success('Entity visibility toggled successfully', {
          toastId: 'entity-toggle-success',
        });
      }
    } catch (error) {
      console.error('Error toggling entity visibility:', error);
      toast.error('Failed to toggle entity visibility...', {
        toastId: 'entity-toggle-fail',
      });
    }
  };

  const handleToggleUnhideEntity = async (
    entityType: string,
    value: string,
    entityValue: string,
  ) => {
    try {
      if (entityType === 'organization') {
        const updatedData = orgSetData.map((org) => {
          if (org.value === entityValue) {
            return { ...org, hidden: false };
          }
          return org;
        });

        setOrgData(updatedData);

        const updatedHiddenEntities = hiddenEntities.filter((id) => id !== entityValue);
        setHiddenEntities(updatedHiddenEntities);

        const response = await axios.put(
          `${apiBaseUrl}/api/v1/cases/${caseID}/entities/updateOrganizationStatus`,
          {
            caseID,
            value: entityValue,
            hidden: false,
          },
          { headers: { Authorization: `Bearer ${axios.defaults.headers.common.Authorization}` } },
        );

        if (response.data && !response.data.hidden) {
          const updatedHiddenEntities = hiddenEntities.filter((id) => id !== entityValue);
          setHiddenEntities(updatedHiddenEntities);
        }

        toast.success('Entity visibility toggled successfully', {
          toastId: 'entity-toggle-success',
        });
      }
    } catch (error) {
      console.error('Error toggling entity visibility:', error);
      toast.error('Failed to toggle entity visibility...', {
        toastId: 'entity-toggle-fail',
      });
    }
  };

  return (
    <>
      <TableOrgHeader columnsOrg={columnsOrg} />
      <Box
        sx={{
          maxHeight: { xs: '27rem', md: '27rem', lg: '32rem', xl: '35rem' },
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          borderRight: '0.5px solid',
          borderLeft: '0.5px solid',
          borderBottom: '0.5px solid',
          borderColor: 'primary.border',
          borderRadius: '1px',
          marginBottom: '2rem',
        }}
      >
        {loadingOrgData ? (
          <Loading text="Loading Entities" />
        ) : (
          <Box>
            {orgSetData.length > 0 &&
              [...new Map(orgSetData.map((org) => [org.value, org])).values()]
                .sort((a, b) => {
                  if (a.hidden === b.hidden) {
                    return a.value.localeCompare(b.value);
                  }
                  return a.hidden ? 1 : -1;
                })
                .map((org) => (
                  <TableOrgRow
                    key={org.id}
                    org={org}
                    handleToggleHideEntity={handleToggleHideEntity}
                    loadingOrgData={loadingOrgData}
                    handleToggleUnhideEntity={handleToggleUnhideEntity}
                    orgSetData={orgSetData}
                  />
                ))}
          </Box>
        )}
      </Box>
    </>
  );
}

type TableRowPropTypes = {
  columnsOrg: string[];
};

function TableOrgHeader({ columnsOrg }: TableRowPropTypes) {
  return (
    <>
      <Stack
        direction="row"
        spacing={4}
        sx={{
          paddingBottom: '1.2rem',
          width: '100%',
        }}
      >
        <Typography fontSize="1rem" fontWeight={600} sx={{ display: 'inline' }}>
          Organizations
        </Typography>
      </Stack>
      <Box
        id="table-header"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'primary.column',
          width: '100%',
          border: '0.5px solid ',
          borderColor: 'primary.border',
        }}
      >
        {columnsOrg.map((column, index) => (
          <Box
            key={index}
            sx={{
              fontSize: '0.8rem',
              fontWeight: 700,
              padding: '1rem',
              opacity: '80%',
              cursor: 'pointer',
              width: `${100 / columnsOrg.length}%`,
            }}
          >
            {column}
          </Box>
        ))}
      </Box>
    </>
  );
}

function TableOrgRow({
  org,
  handleToggleHideEntity,
  handleToggleUnhideEntity,
  loadingOrgData,
  orgSetData,
}: {
  org: Org;
  handleToggleHideEntity: Function;
  handleToggleUnhideEntity: Function;
  loadingOrgData: boolean;
  orgSetData: Org[];
}) {
  const isEntityHidden = orgSetData.find((o) => o.value === org.value)?.hidden;
  return (
    <Box
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'center',
        borderBottom: '0.5px solid',
        borderColor: 'primary.border',
      }}
    >
      <Box
        sx={{
          padding: '1rem',
          flex: '1',
          fontWeight: 600,
          opacity: isEntityHidden ? 0.4 : 0.8,
        }}
      >
        {org.value}
      </Box>
      <Box
        sx={{
          padding: '1rem',
          flex: '1',
          fontWeight: 600,
          opacity: isEntityHidden ? 0.4 : 0.8,
        }}
      >
        {org.origin}
      </Box>

      <Box
        sx={{
          padding: '1rem',
          flex: '1',
          fontWeight: 600,
          opacity: isEntityHidden ? '40%' : '80%',
        }}
      >
        <Button
          onClick={() => handleToggleHideEntity('organization', org.id, org.value)}
          disabled={loadingOrgData}
        >
          <HideSourceIcon width={19} height={19} />
        </Button>
        {isEntityHidden && (
          <Button
            onClick={() => handleToggleUnhideEntity('organization', org.id, org.value)}
            disabled={loadingOrgData}
          >
            Show
          </Button>
        )}
      </Box>
    </Box>
  );
}
