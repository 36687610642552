/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Chip,
  Dialog,
  Box,
  Link,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Tooltip,
  Radio,
  CircularProgress,
  FormLabel,
} from '@mui/material';
import { ArrowUpward, ArrowDownward, ArrowForwardIos } from '@mui/icons-material';
import { shallow } from 'zustand/shallow';
import { useState, useEffect, SetStateAction, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { formatFormalDate } from '../../library/utilities/useDates';
import Loading from '../../components/common/Loading';
import ReportsIcon from '../../components/icons/ReportsIcon';
import useNotes from '../Notes/gql/useNotes';
import useNotesStore from '../Notes/useNotesStore';
import useDocumentNames from '../Case/gql/useDocumentNames';
import NewSearchBar from '../../components/Searchbar/NewSearchBar';
import Export from '../../components/icons/ExportWhite';
import FeedbackPopup from '../../components/common/FeedbackPopup';
import { Direction, compareDates, compareStrings, nullishCompare } from '../../utils/compare';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';

type NotesTableProps = {
  loading: boolean | null | undefined;
  isOpen: boolean;
  columns: string[];
  onCloseNotesTable: () => void;
  onOpenReportsTable: () => void;
};

export default function NotesTable(props: NotesTableProps) {
  const {
    loading,
    isOpen,
    columns = ['Title', 'Name', 'Date', 'Note', 'Tags', 'Note Source'],
    onCloseNotesTable,
    onOpenReportsTable,
  } = props;
  const { afterDate, beforeDate, physician } = useNotesStore(
    (state) => ({
      afterDate: state.afterDate,
      beforeDate: state.beforeDate,
      physician: state.physician,
    }),
    shallow,
  );
  const logUserActivity = useActivityLog();
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [sortOrder, setSortOrder] = useState<Direction>('asc');

  const { caseID } = useParams();
  const documentNames = useDocumentNames(caseID!);

  useEffect(() => {
    if (caseID) {
      logUserActivity({
        activity: 'case:notes',
        case_id: caseID,
      });
    }
  }, [caseID]);

  const changeSorting = (orderNameClicked: SetStateAction<string>) => {
    if (orderBy === orderNameClicked) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(orderNameClicked);
      setSortOrder('asc');
    }
  };

  const { data: notes } = useNotes({
    case_id: caseID,
  });

  const sortedNotes = [...notes].sort((a, b) => {
    if (orderBy === 'name') {
      return compareStrings[sortOrder](a.physician, b.physician);
    }

    if (orderBy === 'note') {
      return compareStrings[sortOrder](a.body, b.body);
    }

    if (orderBy === 'title') {
      return compareStrings[sortOrder](a.title, b.title);
    }

    if (orderBy === 'note source') {
      const aSource = `${a.documentID}_${a.pageID}`;
      const bSource = `${b.documentID}_${b.pageID}`;
      return compareStrings[sortOrder](aSource, bSource);
    }

    return nullishCompare(sortOrder, compareDates[sortOrder])(
      a.date ? new Date(a.date) : null,
      b.date ? new Date(b.date) : null,
    );
  });

  const filteredNotes = sortedNotes.filter((note) => {
    let isNoteValid = true;

    if (afterDate && note.date && new Date(note.date) < afterDate) {
      isNoteValid = false;
    }

    if (beforeDate && note.date && new Date(note.date) > beforeDate) {
      isNoteValid = false;
    }

    if (physician && note.physician !== physician) {
      isNoteValid = false;
    }

    if (searchTerm) {
      isNoteValid =
        note.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note.physician.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note.body.toLowerCase().includes(searchTerm.toLowerCase());
    }

    return isNoteValid;
  });

  const insertNoteSourceLink = (note: {
    documentID: string | number;
    pageID: string;
    pageNumber: string | number;
  }) => {
    const currentUrl = window.location.href;
    const pageUrl = currentUrl.replace('reports', `documents/${note.documentID}/${note.pageID}`);
    return (
      <Link href={pageUrl} sx={{ textDecoration: 'none', color: '#0056b3' }}>
        Page {note.pageNumber} of {documentNames?.[note.documentID]}
      </Link>
    );
  };

  const calculateDynamicColumnWidth = (
    totalWidth: number,
    fixedColumnWidths: number[],
    numDynamicColumns: number,
  ) => {
    const totalFixedWidth = fixedColumnWidths.reduce((acc, width) => acc + width, 0);
    const availableWidth = totalWidth - totalFixedWidth;
    return `${availableWidth / numDynamicColumns}%`;
  };

  const totalWidth = 90;
  const fixedColumnWidths = [10, 35, 12];
  const numDynamicColumns = columns.length - 3;
  const dynamicColumnWidth = calculateDynamicColumnWidth(
    totalWidth,
    fixedColumnWidths,
    numDynamicColumns,
  );

  const [showExportDialog, setShowExportDialog] = useState(false);

  const handleExport = () => {
    setShowExportDialog(true);
  };

  const handleCloseExportDialog = () => {
    setShowExportDialog(false);
  };

  const handleGoToReports = () => {
    onCloseNotesTable();
    onOpenReportsTable();
  };

  const handleSearchTermChange = (event: { target: { value: SetStateAction<string> } }) => {
    setSearchTerm(event.target.value);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <Typography variant="h6" onClick={handleGoToReports} sx={{ cursor: 'pointer' }}>
          Reports{' '}
        </Typography>
        <ArrowForwardIos
          sx={{
            fontSize: '0.9rem',
            opacity: '40%',
            marginTop: '0.2rem',
            marginLeft: '1rem',
            marginRight: '1rem',
          }}
        />
        <Box
          sx={{
            color: 'primary.light',
            fontSize: '0.9rem',
            fontWeight: 600,
          }}
        >
          {' '}
          All Notes
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h3">All Notes</Typography>
          <Tooltip title="Filters do not apply to the export.">
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'primary.light',
                fontSize: '0.8rem',
                marginLeft: '2rem',
                borderRadius: '8px',
              }}
              onClick={handleExport}
            >
              <Export />
              <Typography fontSize="small" sx={{ marginLeft: '0.5rem' }}>
                Export
              </Typography>
            </Button>
          </Tooltip>

          <Dialog open={showExportDialog} onClose={handleCloseExportDialog}>
            <ExportNotesTable
              onClose={handleCloseExportDialog}
              notes={notes}
              documentNames={documentNames}
            />
          </Dialog>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              padding: '.8rem',
              backgroundColor: 'selectedGrey',
              paddingTop: '.3rem',
            }}
            id="notes-search-container"
          >
            <NewSearchBar
              label="Search Notes"
              searchStr={searchTerm}
              handleChange={handleSearchTermChange}
              searchingInProgress={loading}
              customStyles={{ flex: 90 }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        id="table-header"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'primary.columnHeader',
          width: '100%',
          border: '0.5px solid ',
          borderRadius: '3px',
          borderColor: 'primary.border',
        }}
      >
        {columns.map((column, index) => (
          <Box
            key={index}
            sx={{
              fontSize: '0.8rem',
              fontWeight: 600,
              padding: '1rem',
              opacity: '80%',
              cursor: 'pointer',
              width:
                column === 'Date'
                  ? '10%'
                  : column === 'Note'
                  ? '35%'
                  : column === 'Note Source'
                  ? '12%'
                  : dynamicColumnWidth,
            }}
            onClick={() => changeSorting(column.toLowerCase())}
          >
            {column}
            {orderBy === column.toLowerCase() && (
              <Box
                component={sortOrder === 'asc' ? ArrowUpward : ArrowDownward}
                sx={{
                  fontSize: '1rem',
                  opacity: '50%',
                  verticalAlign: 'top',
                  marginLeft: '0.2rem',
                }}
              />
            )}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          width: '100%',
          overflowY: 'auto',
          borderLeft: '0.5px solid ',
          borderRight: '0.5px solid',
          borderBottom: '0.5px solid',
          borderColor: 'primary.border',
          marginBottom: '1.5rem',
        }}
      >
        {loading ? (
          <Loading text="Loading Notes..." />
        ) : filteredNotes.length > 0 ? (
          filteredNotes.map((note, index) => (
            <Box
              key={index}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                flexWrap: 'wrap',
                alignItems: 'center',
                borderBottom: '0.5px solid',
                borderColor: 'primary.border',
              }}
            >
              {columns.map((column, columnIndex) => (
                <Box
                  key={columnIndex}
                  sx={{
                    padding: '1rem',
                    fontSize: '0.9rem',
                    width:
                      column === 'Date'
                        ? '10%'
                        : column === 'Note'
                        ? '35%'
                        : column === 'Note Source'
                        ? '12%'
                        : dynamicColumnWidth,
                  }}
                >
                  {column === 'Title' && note.title}
                  {column === 'Name' && note.physician}
                  {column === 'Date' && note.date && formatFormalDate(note.date)}
                  {column === 'Note' && <NoteText noteBody={note.body} searchTerm={searchTerm} />}
                  {column === 'Tags' &&
                    Array.isArray(note.tags) &&
                    note.tags?.map((tag, tagIndex) => (
                      <Chip
                        key={tagIndex}
                        label={tag.title}
                        variant="outlined"
                        style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
                      />
                    ))}
                  {column === 'Note Source' && insertNoteSourceLink(note)}
                </Box>
              ))}
            </Box>
          ))
        ) : searchTerm.length > 0 ? (
          <Box
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
              marginTop: '5rem',
              marginBottom: '5rem',
              width: '100%',
              padding: '2rem',
            }}
          >
            <ReportsIcon />
            <Typography sx={{ fontWeight: 600, fontSize: '0.9rem' }}>No Notes Found</Typography>
            <Typography
              sx={{
                fontWeight: 600,
                opacity: '50%',
                fontSize: '0.8rem',
              }}
            >
              Please try updating your search or filters
            </Typography>
          </Box>
        ) : null}
        {!searchTerm && filteredNotes.length === 0 && notes.length === 0 ? (
          <>
            <NoNotes onClose={() => {}} />
            <Box
              sx={{
                textAlign: 'center',
                justifyContent: 'center',
                marginTop: '5rem',
                marginBottom: '5rem',
                width: '100%',
                padding: '2rem',
              }}
            >
              <ReportsIcon />
              <Typography sx={{ fontWeight: 600, fontSize: '0.9rem' }}>No Notes Created</Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  opacity: '50%',
                  fontSize: '0.8rem',
                }}
              >
                As notes are created they will be automatically added to the “Notes Table”. Please
                create notes in the “Review” tab.
              </Typography>
            </Box>
          </>
        ) : null}
      </Box>
    </>
  );
}

type NoteTextProps = {
  noteBody: string;
  searchTerm: string | number;
};

function NoteText(props: NoteTextProps) {
  const { noteBody, searchTerm } = props;
  const [showFullNote, setShowFullNote] = useState(false);
  const [truncated, setTruncated] = useState<boolean>(false);
  const noteRef = useRef<HTMLDivElement>(null); // Use HTMLDivElement for better type safety

  useEffect(() => {
    const noteElement = noteRef.current;
    if (noteElement) {
      const lineHeight: number = parseInt(window.getComputedStyle(noteElement).lineHeight, 10);
      const maxHeight = 3 * lineHeight;
      const isTruncated = noteElement.scrollHeight > maxHeight;
      setTruncated(isTruncated);
    }
  }, [noteBody]);

  const toggleShowFullNote = () => {
    setShowFullNote(!showFullNote);
  };

  const highlightSearchTerm = (text: string, term: string | number) => {
    if (!term) {
      return text;
    }
    const regex = new RegExp(`(${term})`, 'gi');
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: 'yellow' }}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  return (
    <div>
      <Typography
        ref={noteRef}
        sx={{
          flex: '1 1 auto',
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitLineClamp: showFullNote ? 'inherit' : 3,
          WebkitBoxOrient: 'vertical',
          marginBottom: '1rem',
          fontSize: '0.9rem',
        }}
      >
        {highlightSearchTerm(noteBody, searchTerm)}
      </Typography>
      {truncated && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Link
            component="button"
            sx={{ textDecoration: 'none', fontSize: '0.9rem', color: 'primary.light' }}
            onClick={toggleShowFullNote}
          >
            {showFullNote ? 'Show less...' : 'Show more...'}
          </Link>
        </Box>
      )}
    </div>
  );
}

type NoNotesProps = {
  onClose: () => void;
};
export function NoNotes({ onClose }: NoNotesProps) {
  const [open, setOpen] = useState(true);

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
      aria-label="dialogTitle"
      open={open}
      maxWidth="sm"
      fullWidth={true}
      sx={{ justifyContent: 'center' }}
      PaperProps={{
        sx: { height: '50%', maxHeight: '30rem', width: '60%' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          p: '4rem',
        }}
      >
        <ReportsIcon />
        <Typography variant="h3" sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
          {' '}
          No Notes Created{' '}
        </Typography>
        <Typography sx={{ fontSize: '1rem' }} /> As notes are created they will be automatically
        added to the “Notes Table”. Please create notes in the “Review” tab.
        <Button
          variant="outlined"
          onClick={() => setOpen(false)}
          title="Close"
          sx={{ marginTop: '1rem' }}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
}

type DocumentNames = Record<string, string> | null;

type ExportNotesTableProps = {
  onClose: () => void;
  notes: Object[];
  documentNames: DocumentNames;
};
export function ExportNotesTable({ onClose, notes, documentNames }: ExportNotesTableProps) {
  const [exportInProgress, setExportInProgress] = useState(false);
  const [exportSuccess, setExportSuccess] = useState(false);

  useEffect(() => {
    let successTimer: ReturnType<typeof setTimeout> | undefined;
    if (exportSuccess) {
      successTimer = setTimeout(() => {
        setExportSuccess(false);
      }, 1000);
    } else {
      clearTimeout(successTimer);
    }

    return () => clearTimeout(successTimer);
  }, [exportSuccess]);

  const convertToCSV = (data: string[] | number[] | Object[] | any[]) => {
    const headers = ['Title', 'Name', 'Date', 'Note', 'Tags', 'Note Source'];

    const header = headers.join(',');

    const body = data
      .map((row) => {
        const { physician, body: noteBody, tags, documentID, pageNumber, ...rest } = row;

        const noteSource = (
          documentID: string | number,
          pageNumber: string | number,
          documentNames: DocumentNames,
        ) => {
          const documentName = documentNames?.[documentID];
          return `Page ${pageNumber} of ${documentName}`;
        };

        const source = noteSource(documentID, pageNumber, documentNames);

        const tagsString =
          tags && tags.length > 0 ? `"${tags.map((tag) => tag.title).join('\n')}"` : ' ';

        const formattedNoteBody = noteBody ? `"${noteBody.replace(/"/g, '""')}"` : 'N/A';

        return [
          `"${rest.title}"` || ' ',
          `"${physician}"` || ' ',
          rest.date || ' ',
          formattedNoteBody,
          tagsString,
          source,
        ].join(',');
      })
      .join('\n');

    return `${header}\n${body}`;
  };

  const downloadCSV = () => {
    setExportInProgress(true);

    setTimeout(() => {
      const csvData = convertToCSV(notes);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'All Notes.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setExportInProgress(false);
      setExportSuccess(true);

      setTimeout(() => {
        onClose();
        setExportSuccess(false);
      }, 1000);
    }, 1000);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          paddingTop: '3rem',
          paddingBottom: '3rem',
          paddingRight: '2rem',
          paddingLeft: '2rem',
        }}
      >
        <Typography variant="h3" sx={{ marginBottom: '.5rem' }}>
          Export Notes Table
        </Typography>
        <FormControl>
          <FormLabel sx={{ marginBottom: '2rem' }}>
            <Typography sx={{ fontSize: '1rem' }}>
              Please select a format to export your Notes.
            </Typography>
          </FormLabel>
          <RadioGroup defaultValue="csv" name="radio-buttons-group">
            <FormControlLabel value="csv" control={<Radio />} label="CSV" />
          </RadioGroup>
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              marginTop: '2rem',
              marginRight: '1rem',
              paddingRight: '4rem',
              paddingLeft: '4rem',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={downloadCSV}
            sx={{
              marginTop: '2rem',
              paddingRight: '4rem',
              paddingLeft: '4rem',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
            }}
          >
            Export
          </Button>
        </Box>
      </Box>
      <FeedbackPopup
        text={
          <div>
            <div>Exporting Notes...</div>
            <div style={{ fontSize: '12px' }}>Please do not leave this page</div>
          </div>
        }
        severity="info"
        icon={<CircularProgress size={24} sx={{ color: 'white', margin: 'auto 15px auto 0px' }} />}
        verticalLocation="bottom"
        horizontalLocation="center"
        open={exportInProgress}
      />
      <FeedbackPopup
        text="Notes successfully exported"
        severity="success"
        verticalLocation="bottom"
        horizontalLocation="center"
        open={exportSuccess}
      />
    </>
  );
}
