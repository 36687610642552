import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Tag } from '../types/timelineTypes';
import usePageTagContentTypes from './useContentTypes';
import useSpecialities from './useSpecialities';
import { DocumentTag, getAllDocumentTags } from '../../../api';

// @TODO: create a hook that completely works with API
const useContentTypesAndSpecialities = () => {
  const pageTagsQuery = usePageTagContentTypes();
  // const specialitiesQuery = useSpecialities(); gets specialties from page tags
  const { data: documentTagsQuery } = useQuery(['allDocumentTags'], () => getAllDocumentTags(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return useMemo((): {
    pageTagList: Tag[];
    documentTagList: DocumentTag[];
    pageTagMap: Record<string, Tag>;
    documentTagMap: Record<string, Tag>;
    content: DocumentTag[];
    specialities: DocumentTag[];
    deprecated: Tag[];
  } => {
    const pageTags = pageTagsQuery?.data ?? [];
    const documentTypesData = documentTagsQuery?.data?.documentType ?? [];
    const specialitiesData = documentTagsQuery?.data?.specialty ?? [];

    const processPageTags = (items: Tag[] = []) => {
      const nonDeprecated = [];
      const deprecated = [];
      const map = {};

      items.forEach((item) => {
        map[item.id] = item;

        if (item.origin === 'deprecated') {
          deprecated.push(item);
        } else {
          nonDeprecated.push(item);
        }
      });

      return [nonDeprecated, deprecated, map];
    };

    const processDocumentTags = (items) => {
      const nonDeprecated = [];
      const deprecated = [];
      const map = {};

      items.forEach((item) => {
        map[item.tag_id] = item;

        if (item.deprecated) {
          deprecated.push(item);
        } else {
          nonDeprecated.push(item);
        }
      });

      return [nonDeprecated, deprecated, map];
    };

    const [nonDeprecatedPageContentTypes, deprecatedPageContentTypes, pageContentTypesMap] =
      processPageTags(pageTags);
    const [nonDeprecatedDocumentTypes, deprecatedDocumentTypes, documentTypesMap] =
      processDocumentTags(documentTypesData);
    const [nonDeprecatedSpecialities, deprecatedSpecialities, specialitiesMap] =
      processDocumentTags(specialitiesData);

    const documentTagMap = {
      ...documentTypesMap,
      ...specialitiesMap,
    };

    return {
      pageTagList: Object.values(pageContentTypesMap),
      documentTagList: Object.values(documentTagMap),
      pageTagMap: pageContentTypesMap,
      documentTagMap,
      content: Object.values(nonDeprecatedDocumentTypes),
      specialities: Object.values(nonDeprecatedSpecialities),
      deprecated: [...deprecatedDocumentTypes, ...deprecatedSpecialities],
    };
  }, [pageTagsQuery, documentTagsQuery]);
};

export default useContentTypesAndSpecialities;
