import { isEqual } from 'lodash';
import { formatUTCDateToDateString } from '../../../../library/utilities/useDates';
import {
  compareAlphaNumeric,
  compareNumbers,
  compareStrings,
  Direction,
  mapCompare,
  multiCompare,
} from '../../../../utils/compare';
import { TimelineEntryRow } from '../../api-queries/useGetTimelineEntriesForReportSection';

export const processorFileStatusLabels = {
  GROUPING: 'Grouping',
  TAGGING: 'Tagging',
  QA_REQUIRED: 'QA Required',
  APPROVED: 'Approved',
  COMPLETE: 'Complete',
  PENDING: 'Not Started',
  ERROR: 'Error',
  CLOSED: 'Closed',
  UPLOADING: 'Uploading',
} as const;

export const orderedFileStatusSteps = {
  CLOSED: 0,
  ERROR: 1,
  UPLOADING: 2,
  PENDING: 3,
  GROUPING: 4,
  TAGGING: 5,
  QA_REQUIRED: 6,
  APPROVED: 7,
  COMPLETE: 8,
} as const;

export const customMultiSort = (rows: TimelineEntryRow[], sortField, sortDirection: Direction) => {
  const primarySortField = sortField;
  const secondarySortField = 'entry_date';

  const primarySortDirection = sortDirection;
  const secondarySortDirection: Direction = 'asc';

  let primaryComparer: (a: TimelineEntryRow, b: TimelineEntryRow) => number;

  switch (primarySortField) {
    case 'content_tags':
      primaryComparer = mapCompare<TimelineEntryRow, string>(
        (item) => item.tag_names ?? '',
        compareAlphaNumeric[primarySortDirection],
      );
      break;
    case 'page_count':
      primaryComparer = mapCompare<TimelineEntryRow, number>(
        (item) => item[primarySortField] ?? 0,
        compareNumbers[primarySortDirection],
      );
      break;
    case 'file_status':
      primaryComparer = mapCompare<TimelineEntryRow, number>(
        (item) => orderedFileStatusSteps[item[primarySortField]] ?? 0,
        compareNumbers[primarySortDirection],
      );
      break;
    case 'id':
      primaryComparer = mapCompare<TimelineEntryRow, number>(
        (item) => item[primarySortField] ?? 0,
        compareNumbers[primarySortDirection],
      );
      break;
    case 'document_name':
      primaryComparer = mapCompare<TimelineEntryRow, string>(
        (item) => item[primarySortField] ?? '',
        compareAlphaNumeric[primarySortDirection],
      );
      break;
    default:
      primaryComparer = mapCompare<TimelineEntryRow, string>(
        (item) => item[primarySortField] ?? '',
        compareStrings[primarySortDirection],
      );
      break;
  }

  const secondaryComparer = mapCompare<TimelineEntryRow, string>(
    (item) => item[secondarySortField],
    compareStrings[secondarySortDirection],
  );

  const markedImportantComparer: (a: TimelineEntryRow, b: TimelineEntryRow) => number = (a, b) => {
    if (a.marked_important !== b.marked_important) {
      return Number(b.marked_important) - Number(a.marked_important);
    }

    return 0;
  };

  const combinedComparer = multiCompare(
    markedImportantComparer,
    primaryComparer,
    secondaryComparer,
  );

  const sortedRows = [...rows].sort(combinedComparer);
  return sortedRows;
};

export const shouldUpdateTableRow = (updatedRow: any, originalRow: any) => {
  // table takes value as date, so need to convert back to original type string
  const updatedRowCopy = {
    ...updatedRow,
    // if updated row is cleared, it is equivalent to unknown date
    entry_date: formatEntryDateToString(updatedRow.entry_date),
    // handles case where a blank field is edited but not changed
    organization_name: updatedRow.organization_name ? updatedRow.organization_name : null,
    author_name: updatedRow.author_name ? updatedRow.author_name : null,
  };

  // this happens when the source has been edited
  if (typeof updatedRow.source_name === 'object') {
    updatedRowCopy.source_name = updatedRow.source_name.label;
    updatedRowCopy.source_id = String(updatedRow.source_name.value);
  }
  return !isEqual(updatedRowCopy, originalRow);
};

export const formatEntryDateToString = (originalDate: string | Date): string => {
  if (originalDate == null) {
    return '1900-01-01';
  }
  if (typeof originalDate === 'string') {
    return originalDate;
  }
  return formatUTCDateToDateString(originalDate);
};

export function formatDate(date: Date): string {
  // Check if `date` is not a valid date object
  if (!date || isNaN(date.getTime()) || date.toISOString().startsWith('1900-01-01')) {
    return 'Unknown';
  }

  // Extract the parts of the date
  const month = date.getMonth() + 1; // Adjust month from 0-indexed to 1-indexed
  const day = date.getDate();
  const year = date.getFullYear();

  // Pad month and day with leading zeros if needed
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${year}-${formattedMonth}-${formattedDay}`;
}
