import { useMemo } from 'react';
import { TagType, useGetPageTagsWithChildrenQuery } from '../../../__generated__/graphql';
import { DisplayableTag, Tag } from '../types/timelineTypes';
import { toDisplayableTagSavingOriginalValues } from './utils/pageTagUtils';

const usePageTagContentTypes = () => {
  const query = useGetPageTagsWithChildrenQuery({
    variables: {
      where: {
        type: {
          equals: TagType.ContentType,
        },
      },
    },
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data?.tags.map(
        (tag): Tag & DisplayableTag & Required<Pick<DisplayableTag, 'subItems'>> => ({
          ...toDisplayableTagSavingOriginalValues(tag),
          subItems: tag.sub_tags?.map(toDisplayableTagSavingOriginalValues),
        }),
      ),
    }),
    [query],
  );
};

export default usePageTagContentTypes;
