import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Button,
  Typography,
} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import { useGroupingLock } from './api-hooks/useGroupingLock';

export default function GroupingLock(props: { caseID: string; fileID: string; children: any }) {
  const { caseID, fileID } = props;
  const {
    accessLoading,
    refreshLoading,
    isError: lockError,
    access: reportAccess,
    currentUser,
    refresh,
  } = useGroupingLock(caseID, fileID);
  const navigate = useNavigate();

  const dialogText = lockError
    ? `A problem has occurred while checking access. Please contact support if the problem persists.`
    : `Documents in grouping can only be accessed by one user at a time. ${
        currentUser ? 'Please contact the user below to gain access to this report.' : ''
      }`;
  return (
    <>
      {props?.children}
      {!accessLoading && (
        <Dialog
          open={!reportAccess || lockError}
          sx={{
            '.MuiDialog-paper': {
              borderRadius: 4,
              height: 350,
              minWidth: 500,
              width: '50%',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
          }}
        >
          <LockIcon
            sx={{
              color: 'primary.light',
              marginTop: 7,
              fontSize: 40,
            }}
          />
          <DialogTitle
            sx={{
              color: 'primary.light',
              marginBottom: '-10px',
              fontWeight: 700,
              fontSize: '1.1rem',
            }}
          >
            <IconButton
              aria-label="close"
              disabled={refreshLoading}
              onClick={refresh}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <AutorenewIcon />
            </IconButton>
            Unable to Access Grouping Tab
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                marginTop: '-2px',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                fontSize: '0.75rem',
                paddingLeft: '3rem',
                paddingRight: '3rem',
                marginBottom: 3,
              }}
            >
              {dialogText}
            </DialogContentText>
            <div style={{ textAlign: 'center' }}>
              {currentUser && (
                <Typography style={{ opacity: '60%', fontWeight: 700, fontSize: 'small' }}>
                  {currentUser}
                </Typography>
              )}
              <Button
                color="primary"
                variant="contained"
                startIcon={<ArrowBackIosNewIcon />}
                sx={{
                  borderRadius: 3,
                  background: '#3658ad',
                  fontSize: '0.9rem',
                  marginBottom: 0.5,
                  marginRight: -1,
                  marginTop: 2,
                }}
                onClick={() => navigate('../files')}
              >
                Back to Files
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
