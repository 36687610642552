/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Typography, Box } from '@mui/material';
import Theme from '../../theme';
import UploadFiles from '../icons/UploadFiles';

/**
 * Drag and drop file upload component.
 *
 * Required Props:
 * @param files - The array of files this component uploads to.
 * @param handleFileUpload - The function to add files to the files array.
 * @param handleFileDelete - The function to delete files from the files array.
 */
function FileUploadBox(props) {
  const { uploadFiles } = props;

  return (
    <Box
      style={{
        maxWidth: '100%',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <DragAndDrop {...props} handleDrop={uploadFiles}>
        <Box
          sx={{
            height: 300,
            width: 250,
            display: 'block',
            border: 'thin solid black',
          }}
        />
      </DragAndDrop>
    </Box>
  );
}

/*
    
*/
class DragAndDrop extends React.Component {
  state = {
    drag: false,
  };

  dropRef = React.createRef();

  // eslint-disable-next-line class-methods-use-this
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isNaN(this.dragCounter)) {
      this.dragCounter = 0;
    }
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  render() {
    return (
      <Box
        sx={{
          backgroundColor: this.state.drag ? '#DCE4FE' : '#FFFFF',
          margin: 'auto',
          position: 'relative',
          width: '100%',
          border: '1.5px solid',
          borderRadius: '10px',
          borderColor: Theme.palette.borderGrey.main,
          padding: '25px',
        }}
        ref={this.dropRef}
      >
        <div
          style={{
            alignSelf: 'stretch',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '0.25rem',
            display: 'flex',
          }}
        >
          <div
            style={{
              alignSelf: 'stretch',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '0.25rem',
              display: 'inline-flex',
            }}
          >
            <UploadFiles />
          </div>
          <div
            style={{
              alignSelf: 'stretch',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '0.25rem',
              display: 'inline-flex',
            }}
          >
            <input
              id="click-file-upload"
              type="file"
              style={{ display: 'none' }}
              onChange={(event) => this.props.handleDrop(event.target.files)}
              multiple
            />
            <label htmlFor="click-file-upload">
              <Typography
                variant="h6"
                color={Theme.palette.primary.dark}
                sx={{
                  fontWeight: '700',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                Click to upload
              </Typography>
            </label>
            <Typography
              variant="h6"
              sx={{
                color: `${Theme.palette.subHeading.main}`,
              }}
            >
              or drag and drop
            </Typography>
          </div>
          <Typography variant="subtitle" sx={{ marginTop: '-5px' }}>
            PDF and JPEG files
          </Typography>
        </div>
      </Box>
    );
  }
}

export default FileUploadBox;
