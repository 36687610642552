import { TagType, PageDate } from '../../../__generated__/graphql';
import { DocumentTag, DocumentTagFilter } from '../../../api';

export type TimelineEntry = {
  id: number;
  author_id: number;
  org_id: number;
  subject_id: number;
  createdDate: string; // date
  documentID: string; // uuid
  entryDate: string; // date
  lastModifiedDate: string; // date
  sourceID: bigint;
  documentTags: DocumentTag[];
  timeline_id: number;
  pages: Page[];
  order: number;
  marked_important: boolean;
};

export type TimelineEntryDescriptor = {
  id: string;
  pages: Array<{ id: number; pageNumber: number; documentID: string }>;
};

export type DocumentEntry = {
  caseID: string;
  docFileName: string;
  documentID: string;
  isReady: boolean;
  numberOfPages: number;
  uploadDate: string; // date but not ISO
  pages: Array<Pick<Page, 'id' | 'pageNumber' | 'documentID' | 'entryID'>>;
};

export type Page = {
  tags: Tag[];
  id: number;
  entryID: number;
  pageID: string;
  documentFileName: string;
  documentID: string;
  hasDuplicates: boolean;
  isDuplicate: number | null;
  isFavourite: boolean | null;
  pageDate: string; // date YYYY-MM-DD
  pageNumber: number;
  timelineID: number;
  sourceID: bigint;
  viewedAt: string | null; // date
  contentTypes: Tag[];
  specialities: Tag[]; // @TODO: fix
  confidenceScores: number[] | null;
  extracted_dates?: PageDate[];
};

export type Tag = {
  label: string;
  id: number;
  name: string;
  type: TagType;
  value: string;
  parent_tag_id?: number;
  sub_tags?: Omit<Tag, 'sub_tags'>[];
  origin: string;
};

export type TimelineFilters = {
  showFavourited: boolean;
  hideViewed: boolean;
  hideDuplicates: boolean;
  sources?: DocumentTagFilter[];
  contentTypes?: DocumentTagFilter[];
  subContentTypes?: DocumentTagFilter[];
  specialities?: DocumentTagFilter[];
  documentOrder: ListPagesSortByEnum;
  beforeDate: Date;
  afterDate: Date;
  documentID: string[];
};

export enum ListPagesSortByEnum {
  DateAscending = 'Date Ascending',
  DateDescending = 'Date Descending',
  Document = 'Document',
  Source = 'Source',
}

export type RawKeyworkSearchResult = { totalOccurancesFound: number };

export type PreProcessedKeywordSearchResult = {
  totalCount: number;
  totalIncludingHiddenDocumentsAndPages: number;
};

export type KeywordSearchFilteredDocuments = {
  [documentKey: string]: {
    [pageNumberKey: number]: Array<{
      text: string;
      Width: number;
      Height: number;
      Left: number;
      Top: number;
    }>;
    numOfMatchesInDocument: number;
  };
};

export type KeywordSearchConstraints = Partial<
  (RawKeyworkSearchResult | PreProcessedKeywordSearchResult) & KeywordSearchFilteredDocuments
>;

export type KeywordSearchForTimelineEntries = {
  [timelineEntryId: number]: {
    [pageNumber: number]: Array<{
      text: string;
      Width: number;
      Height: number;
      Left: number;
      Top: number;
    }>;
    numOfMatchesInEntry: number;
  };
};

export type TimelineKeywordSearchResults = Partial<
  RawKeyworkSearchResult & PreProcessedKeywordSearchResult & KeywordSearchForTimelineEntries
>;

export type UseTimelineDocumentsParams = {
  caseId: string;
  filters: TimelineFilters;
  keywordSearchConstraints: KeywordSearchConstraints;
  searchKeyword: string;
};
