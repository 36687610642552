import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import NavBar from '../../components/common/PdfNavigation/NavBar';
import usePDFViewerStore from '../Case/usePDFViewerStore';
import { getQueryParams } from '../Timeline/LinkWithQuery';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import { useGetPageByIdQuery } from '../../__generated__/graphql';

const documentWidth = 794;
export default function TimelinePDFHeader({
  pageList = [],
  timelineEntriesDisplayed = [],
  timelineEntryDescriptors = [],
}) {
  const navigate = useNavigate();
  const params = useParams();
  const isFileProcessor = useIsFileProcessor();
  const { timelineID } = params;
  const pageID = +params.pageID;
  const entryID = +params.entryID;
  const pageFromEntries = useMemo(() => {
    return timelineEntriesDisplayed
      ?.find((entry) => entry.id === entryID)
      ?.pages.find((page) => page.id === pageID);
  }, [timelineEntriesDisplayed, pageID, entryID, pageList]);
  const { data: pageObject } = useGetPageByIdQuery({
    variables: {
      id: pageID,
    },
    skip: pageFromEntries != null,
  });
  const page = useMemo(() => {
    if (pageFromEntries != null) {
      return pageFromEntries;
    }
    if (pageObject?.page != null) {
      return { ...pageObject?.page, id: pageID, entryID };
    }
    return null;
  }, [pageFromEntries, pageObject, pageID, entryID]);

  const entryIndex = useMemo(
    () => timelineEntryDescriptors.findIndex((entry) => entry.id === entryID),
    [timelineEntryDescriptors, entryID],
  );

  const [searchParams] = useSearchParams();
  const searchParamsObj = Object.fromEntries(searchParams.entries());
  const searchParamString = getQueryParams(searchParamsObj);
  const documentZoom = usePDFViewerStore((state) => state.documentZoom);

  const pageIndex = useMemo(() => pageList.findIndex((p) => p.id === pageID), [pageList, pageID]);

  function changeTimelineEntry(next) {
    const nextEntry = next
      ? timelineEntryDescriptors[entryIndex + 1]
      : timelineEntryDescriptors[entryIndex - 1];
    const nextEntryPage = nextEntry?.pages[0];
    navigate(`timeline/${timelineID}/${nextEntry.id}/${nextEntryPage.id}${searchParamString}`);
  }

  const onChangePage = (nextIndex) => {
    const newPage = pageList[nextIndex - 1];
    navigate(`timeline/${timelineID}/${newPage.entryID}/${newPage.id}${searchParamString}`);
  };

  return (
    <NavBar
      currentPage={pageIndex + 1 || 0}
      page={page}
      numberOfPages={pageList?.length || 0}
      onChangePage={onChangePage}
      smallButtons={true}
      barWidth={`${documentWidth * documentZoom}px`}
      onPreviousButtonClick={() => changeTimelineEntry(false)}
      onNextButtonClick={() => changeTimelineEntry(true)}
      nextButtonDisabled={entryIndex === (timelineEntryDescriptors?.length ?? 0) - 1}
      previousButtonDisabled={entryIndex === 0 || entryIndex === -1}
      showContentToolbar={true}
      showFileProcessorToolbar={isFileProcessor}
      showZoomRotateToolbar={true}
    />
  );
}
