import React, { useMemo } from 'react';
import { Menu, MenuItem, Box, CircularProgress, Divider, Typography } from '@mui/material';
import useSources from '../gql/useSources';
import useUpdateEntryDetails from '../gql/useUpdateEntryDetails';
import { TimelineEntry as TimelineEntryType, Page } from '../types/timelineTypes';
import OverflowText from '../../../components/common/OverflowText';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import { TagDisplayObject } from '../../../__generated__/graphql';

export default function CategorySelector({ entry, showCheckboxes, caseID }: Props) {
  const { id: entryID, sourceID, pages } = entry;
  const sources = useSources();
  const { updateEntrySource, sourceState } = useUpdateEntryDetails();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isFileProcessor = useIsFileProcessor();

  const highestScore = useMemo(() => getHighestConfidenceScore(pages), [pages]);

  const onItemClick = (source: TagDisplayObject) => {
    updateEntrySource({
      source,
      caseID,
      entryID,
    });
    setAnchorEl(null);
  };

  const renderSourceList = () => {
    if (!sources || !anchorEl) {
      return null;
    }
    return sources
      .filter((source) => !source.deprecated)
      .map((source) => (
        <MenuItem key={source.tag_id} onClick={() => onItemClick(source)}>
          {source.name}
        </MenuItem>
      ));
  };

  const pageSourceObj = sources && sources.find((source) => source.tag_id === sourceID);

  const openSourceMenu = (e: any) => {
    if (sourceState.loading) {
      return;
    }
    setAnchorEl(e.target);
  };

  return (
    <Box
      onClick={(e) => e.preventDefault()}
      sx={{
        width: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        verticalAlign: 'top',
      }}
    >
      <Box
        onClick={openSourceMenu}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: sourceState.loading ? 'initial' : 'pointer',
        }}
      >
        <OverflowText
          sx={{
            fontSize: '0.85rem',
            fontWeight: 500,
            maxWidth: '75%',
            display: 'flex',
            marginLeft: showCheckboxes && '-0.0.5rem',
            '&:hover': {
              color: sourceState.loading ? 'inherit' : 'primary.light',
            },
          }}
          className="unmask"
        >
          {pageSourceObj?.name}
        </OverflowText>
        {sourceState.loading && <CircularProgress size={12} sx={{ ml: 0.5 }} />}
        {/* {isFileProcessor && ( TODO: Display confidence scores with document tags
          <>
            <Divider
              orientation="vertical"
              sx={{
                width: '8px',
                height: '15px',
                marginTop: '0.2rem',
                marginRight: '0.5rem',
              }}
            />
            <Typography
              color="#98A2B3"
              sx={{
                fontSize: '0.75rem',
                fontWeight: 500,
                pt: '0.1rem',
                display: 'flex',
              }}
            >
              {highestScore}
            </Typography>
          </>
        )} */}
      </Box>
      {anchorEl && (
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          {renderSourceList()}
        </Menu>
      )}
    </Box>
  );
}

type Props = {
  entry: TimelineEntryType;
  showCheckboxes: boolean;
  caseID: string;
};

const getHighestConfidenceScore = (pages: Page[]) => {
  let highestScore = 0;
  let hasConfidenceScores = false;

  pages.forEach((page) => {
    if (page.confidenceScores !== null) {
      hasConfidenceScores = true;

      if (page.confidenceScores[0] > highestScore) {
        highestScore = page.confidenceScores[0];
      }
    }
  });

  if (!hasConfidenceScores) {
    return 'No confidence scores';
  }

  return `${(highestScore * 100).toFixed(1)}%`;
};
