import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export default function IconNewLock(props: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.3676 7.07573V5.6604C12.3676 3.70623 10.7835 2.12207 8.82929 2.12207C6.87513 2.12207 5.29097 3.70623 5.29097 5.6604V7.07573M8.82929 10.2602V11.6756M6.56476 14.8601H11.0938C12.2828 14.8601 12.8773 14.8601 13.3314 14.6287C13.7309 14.4251 14.0557 14.1003 14.2592 13.7009C14.4906 13.2467 14.4906 12.6522 14.4906 11.4633V10.4725C14.4906 9.28354 14.4906 8.68904 14.2592 8.23491C14.0557 7.83544 13.7309 7.51066 13.3314 7.30712C12.8773 7.07573 12.2828 7.07573 11.0938 7.07573H6.56476C5.37577 7.07573 4.78128 7.07573 4.32715 7.30712C3.92768 7.51066 3.6029 7.83544 3.39936 8.23491C3.16797 8.68904 3.16797 9.28354 3.16797 10.4725V11.4633C3.16797 12.6522 3.16797 13.2467 3.39936 13.7009C3.6029 14.1003 3.92768 14.4251 4.32715 14.6287C4.78128 14.8601 5.37577 14.8601 6.56476 14.8601Z"
        stroke="white"
        strokeWidth="1.41533"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
