import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useUser } from '../../library/contexts/AuthContext';
import { getUser } from '../Case/CaseDisplays/useCaseLocks';
import { authenticatedRequest } from '../../api';

async function checkReportsLock(reportID: bigint, caseID: string) {
  const res = await authenticatedRequest({
    method: 'GET',
    url: `/case/${caseID}/lock/reports`,
    params: { report_id: reportID },
  });
  return res.data;
}

async function removeReportsLock(reportID: bigint, caseID: string) {
  const res = await authenticatedRequest({
    method: 'DELETE',
    url: `/case/${caseID}/lock/reports`,
    params: { report_id: reportID },
    keepalive: true,
  });
  return res.data;
}

export default function useReportsLocks(caseID: string, reportID: bigint) {
  const { user } = useUser();

  useEffect(
    () => async () => {
      await removeReportsLock(reportID, caseID);
    },
    [],
  );

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      removeReportsLock(reportID, caseID);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [reportID, caseID]);

  const { data, isLoading, isError } = useQuery(
    ['checkReportsLock', reportID],
    async (): Promise<{ access: boolean; currentUser: any }> => {
      const groupingLock = await checkReportsLock(reportID, caseID);
      return {
        access: groupingLock?.access,
        currentUser: !groupingLock?.access
          ? await getUser(user, caseID, groupingLock?.userInCase)
          : undefined,
      };
    },
    {
      refetchInterval: 60 * 1000, //1 minute
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
      enabled: !!caseID && !!reportID,
    },
  );

  const [refreshLoading, setRefreshLoading] = useState(false);
  const queryClient = useQueryClient();

  const refresh = async () => {
    setRefreshLoading(true);
    await queryClient.invalidateQueries(['checkReportsLock', reportID]);
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  return {
    access: data?.access,
    currentUser: data?.currentUser
      ? `${data.currentUser.given_name} ${data.currentUser.family_name}`
      : '',
    accessLoading: isLoading,
    isError,
    refresh,
    refreshLoading,
  };
}
