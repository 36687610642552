import React from 'react';
import { Tooltip } from '@mui/material';
import CloseIcon from '../../../icons/Close';
import './chip.css';

type Props = {
  label: string;
  onClick: (event) => void;
  style?: React.CSSProperties & { chipMaxWidth?: string; labelMaxWidth?: string };
  chipIcon?: any;
};

export default function Chip({ label, onClick, style, chipIcon }: Props) {
  const chipWidth = style?.chipMaxWidth ?? '120px';
  const labelWidth = style?.labelMaxWidth ?? '100px';
  return (
    <Tooltip title={label}>
      <div style={{ padding: '0.15rem' }}>
        <div
          className="chip"
          style={{
            ...style,
            maxWidth: chipWidth,
          }}
        >
          <span className="chip-label" style={{ maxWidth: labelWidth }}>
            {label}
          </span>
          <div className="chip-button" onClick={onClick}>
            {chipIcon ?? <CloseIcon size="12px" style={{ marginBottom: '5px' }} />}
          </div>
        </div>
      </div>
    </Tooltip>
  );
}
