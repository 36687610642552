import { useContext, useEffect } from 'react';
import { DuplicatesByDocument, useDuplicatesByDocument } from './DuplicatesByDocument';
import DocumentComparer from '../../components/DocumentComparer/DocumentComparer';
import CaseHeader from '../Timeline/CaseHeader';
import CaseContext from '../Case/CaseContext';
import { useTimelineList } from '../Timeline/useTimeline';
import DuplicatesList from '../../components/DuplicatesByDocument/DuplicatesList';
import DocumentCompareHeader from './DocumentCompareHeader';
import useDisplayStore from '../Timeline/useDisplayStore';
import Theme from '../../theme';
import { useSyncDocumentsScroll } from '../../components/DocumentComparer/useSyncDocumentsScroll';
import ReportsIcon from '../../components/icons/ReportsIcon';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';

export default function DuplicatesByDocumentContainer() {
  return (
    <DuplicatesByDocument>
      <DuplicatesByDocumentTab />
    </DuplicatesByDocument>
  );
}

function DuplicatesByDocumentTab() {
  const {
    caseID,
    documentSimilaritiesList,
    documentSimilarity,
    markAsDuplicate,
    markAsNotDuplicate,
    navigateToSelectedSet,
    count,
    nextPage,
    previousPage,
    currentPage,
    showResolved,
    showUnresolved,
    hideResolved,
    zoom,
    setZoom,
    createAllDocumentSimilarities,
    isRequestInProgress,
    duplicatesProcessing,
    documentSimilaritiesListLoading,
  } = useDuplicatesByDocument();

  const { caseInstance } = useContext(CaseContext);
  const logUserActivity = useActivityLog();
  const { data: timelineList } = useTimelineList(caseID);
  const timelineID = timelineList?.find((timeline) => timeline.isDefault).id;
  const windowSize = useDisplayStore((state) => state.windowSize);
  const {
    areDocumentScrollsSynced,
    setAreDocumentScrollsSynced,
    setFirstDocumentPageCount,
    setSecondDocumentPageCount,
  } = useSyncDocumentsScroll(true);
  const isFileProcessor = useIsFileProcessor();
  const allResolved = documentSimilaritiesList?.every((set) => set.status === 'NotDuplicate');

  useEffect(() => {
    if (caseID) {
      logUserActivity({
        activity: 'case:duplicates',
        case_id: caseID,
      });
    }
  }, [caseID]);

  const isFirstDocumentDuplicate =
    documentSimilarity?.document_similarity.status === 'Duplicate' &&
    documentSimilarity.document_similarity.duplicate_of === documentSimilarity.document.id;

  const isSecondDocumentDuplicate =
    documentSimilarity?.document_similarity.status === 'Duplicate' &&
    documentSimilarity.document_similarity.duplicate_of === documentSimilarity.compare_with.id;

  const firstDocumentBorderColor = isFirstDocumentDuplicate
    ? Theme.palette.duplicatesByDocumentRed.main
    : Theme.palette.duplicatesByDocumentBlue.main;

  const firstDocumentBackgroundColor = isFirstDocumentDuplicate
    ? Theme.palette.duplicatesByDocumentRed.light
    : Theme.palette.duplicatesByDocumentBlue.light;

  const secondDocumentBorderColor = isSecondDocumentDuplicate
    ? Theme.palette.duplicatesByDocumentRed.main
    : Theme.palette.duplicatesByDocumentBlue.main;

  const secondDocumentBackgroundColor = isSecondDocumentDuplicate
    ? Theme.palette.duplicatesByDocumentRed.light
    : Theme.palette.duplicatesByDocumentBlue.light;

  return (
    <div style={{ height: '100%', overflow: 'hidden', backgroundColor: 'white' }}>
      <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
      <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '17%',
            backgroundColor: 'white',
          }}
        >
          <DuplicatesList
            documentSimilaritiesList={documentSimilaritiesList}
            navigateTo={(original, compare) => {
              navigateToSelectedSet(original, compare);
            }}
            currentSimilarity={documentSimilarity?.document_similarity ?? null}
            count={count}
            nextPage={nextPage}
            previousPage={previousPage}
            currentPage={currentPage}
            showResolved={showResolved}
            showUnresolved={showUnresolved}
            hideResolved={hideResolved}
            createAllDocumentSimilarities={createAllDocumentSimilarities}
            isRequestInProgress={isRequestInProgress}
            duplicatesProcessing={duplicatesProcessing}
            isFileProcessor={isFileProcessor}
          />
        </div>
        <div
          style={{
            width: '79.5%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {(documentSimilaritiesList?.length === 0 || (!isFileProcessor && allResolved)) &&
          !documentSimilaritiesListLoading ? (
            <div
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                marginTop: '5rem',
                marginBottom: '5rem',
                width: '100%',
                padding: '2rem',
              }}
            >
              <ReportsIcon />
              <div style={{ fontWeight: 600, fontSize: '0.9rem' }}>
                {hideResolved ? 'No Unresolved Duplicates Found' : 'No Duplicates Found'}
              </div>
              {duplicatesProcessing && (
                <div
                  style={{
                    fontWeight: 600,
                    opacity: '50%',
                    fontSize: '0.8rem',
                  }}
                >
                  Duplicates are processing. Please check back later.
                </div>
              )}
            </div>
          ) : (
            <>
              <div style={{ height: '8%', backgroundColor: 'white' }}>
                <DocumentCompareHeader
                  hideResolved={hideResolved}
                  notDuplicate={() =>
                    markAsNotDuplicate({
                      document_id: String(documentSimilarity?.document.id),
                      duplicate_of: String(documentSimilarity?.compare_with.id),
                    })
                  }
                  currentSimilarityStatus={
                    documentSimilarity?.document_similarity.status ?? 'Unset'
                  }
                />
              </div>
              <div style={{ height: '92%', marginLeft: '1%' }}>
                <DocumentComparer
                  isFirstDocumentDuplicate={isFirstDocumentDuplicate}
                  isSecondDocumentDuplicate={isSecondDocumentDuplicate}
                  handleMarkAsDuplicate={markAsDuplicate}
                  firstDocumentID={documentSimilarity?.compare_with.id}
                  secondDocumentID={documentSimilarity?.document.id}
                  firstDocumentStyle={{
                    maxHeight: `${windowSize.height - windowSize.height / 5}px`,
                    height: '100%',
                    border: '1.5px solid',
                    borderColor: firstDocumentBorderColor,
                    borderRadius: '10px',
                    backgroundColor: firstDocumentBackgroundColor,
                  }}
                  secondDocumentStyle={{
                    maxHeight: `${windowSize.height - windowSize.height / 5}px`,
                    height: '100%',
                    border: '1.5px solid',
                    borderColor: secondDocumentBorderColor,
                    borderRadius: '10px',
                    backgroundColor: secondDocumentBackgroundColor,
                  }}
                  zoom={zoom}
                  setZoom={setZoom}
                  setFirstDocumentPageCount={setFirstDocumentPageCount}
                  setSecondDocumentPageCount={setSecondDocumentPageCount}
                  areDocumentScrollsSynced={areDocumentScrollsSynced}
                  setAreDocumentScrollsSynced={setAreDocumentScrollsSynced}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
