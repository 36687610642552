import axios from 'axios';
import * as Sentry from '@sentry/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useApolloClient, gql } from '@apollo/client';
import { toast } from 'react-toastify';
import apiUrl from '../../library/utilities/apiUrl';
import { useUser } from '../../library/contexts/AuthContext';
import { assignCase } from '../../api';
import { GetCasesDocument } from '../../__generated__/graphql';

async function getUsers(userID: string) {
  return axios.post(`${apiUrl}getUsersForGroup`, {
    userID: userID,
  });
}

export function useCaseAssignmentMutation() {
  const apolloClient = useApolloClient();
  return useMutation(assignCase, {
    onMutate: async ({ caseID, userID }: AssignmentMutationInput) => {
      const cacheID = apolloClient.cache.identify({
        __typename: 'CasesObject',
        id: caseID,
      });
      const caseObject = apolloClient.readFragment({
        id: cacheID,
        fragment: GET_CASE_OBJECT,
      });

      apolloClient.writeFragment({
        id: cacheID,
        fragment: GET_CASE_OBJECT,
        data: { ...caseObject, user_assigned: userID },
      });
    },
    onSuccess: (_, variables) => {
      toast.success('Case assigned successfully');
      addUserToCase(variables.userID, variables.caseID);
    },
    onError: async () => {
      toast.error('A problem occurred when assigning the case');
      apolloClient.refetchQueries({
        include: [GetCasesDocument],
      });
    },
  });
}

export function useCaseAssignment() {
  const { user } = useUser();
  const { data: userList } = useQuery(['getUsersInGroup'], () => getUsers(user.username), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: 60 * 1000 * 15, // 15 minutes
  });

  return {
    userList: userList?.data ?? [],
  };
}

const GET_CASE_OBJECT = gql`
  fragment CaseAssignmentFragment on CasesObject {
    user_assigned
  }
`;

type AssignmentMutationInput = {
  caseID: string;
  userID: string;
};

const addUserToCase = async (username: string, caseID: string) => {
  try {
    await axios.post(`${apiUrl}addUserRoleForCase`, {
      userID: username,
      caseID: caseID,
      role: 'OWNER',
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};
