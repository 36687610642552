import { useTheme, Box, Card, Checkbox, Typography, Tooltip, Skeleton } from '@mui/material';
import { green, blue } from '@mui/material/colors';
import { shallow } from 'zustand/shallow';
import { memo, useCallback, useMemo, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import PageCardDuplicate from '../../../components/icons/PageCardDuplicate';
import OverflowText from '../../../components/common/OverflowText';
import Truncate from '../../../components/common/Truncate';
import LinkWithQuery from '../LinkWithQuery';
import useTimelineStore from '../useTimelineStore';
import CaseContext from '../../Case/CaseContext';
import PageDuplicateControl from '../../Page/components/PageDuplicateControl';
import PageFlagControl from '../../Page/components/PageFlagControl';
import PageNoteControl from '../../Page/components/PageNoteControl';
import PageViewedControl from '../../Page/components/PageViewedControl';
import ThumbnailPage from './ThumbnailPage';
import { useFileStatusForPage } from '../../Documents/useFileStatus';

const smallPageCareStyles = {
  pageCard: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '95%',
    paddingX: '6px',
    paddingY: 0,
    my: 0,
    border: 'solid 1px #FFFFFF',
    borderRadius: 1,
    mx: '1rem',
    '&:hover': {
      backgroundColor: 'selectedGrey.main',
      borderColor: 'selectedGrey.main',
    },
  },
  bgGreen: {
    backgroundColor: '#d6f5d6',
  },
  selected: {
    backgroundColor: blue[50],
    borderColor: blue[900],
    color: blue[900],
    '&:hover': {
      backgroundColor: blue[50],
      borderColor: blue[900],
      color: blue[900],
    },
  },
  selectedText: {
    color: blue[900],
    fontWeight: 600,
  },
  highlighted: {
    borderColor: green[300],
    '&:hover': {
      borderColor: green[300],
    },
  },
};

function SmallPageCard({
  page,
  isCurrentPage,
  customStyle,
  isInTimelineView,
  showThumbnails,
  noteCount,
  pageControls: { handleToggleViewed, handleToggleFavourite },
  currentDocumentID,
  isFileProcessor = false,
  sources,
  isPageSelected = false,
  areMaximumPagesForSplitSelected = false,
}) {
  const params = useParams();
  const { caseID, timelineID } = params;
  const currentPageID = +params.pageID;
  const theme = useTheme();

  const [searchParams] = useSearchParams();
  const currentTimelineID = searchParams.get('timelineID') || timelineID;

  const pageSourceObj = sources && sources.find((tag) => tag.tag_id === page?.source_id);

  const pageFileStatus = useFileStatusForPage(page);

  const { caseInstance } = useContext(CaseContext);
  const allowDuplicatesAccess =
    caseInstance?.caseStatus === 'READY' ||
    (caseInstance?.caseStatus === 'REQUIRES_PROCESSOR' && isFileProcessor);
  const caseVersion = caseInstance?.version ?? 1;

  const isPageUnconfirmedDuplicate = (caseVersion, page) => {
    if (caseVersion === 2) {
      return false;
    }
    if (caseVersion === 1) {
      return Boolean(page.hasDuplicates) && allowDuplicatesAccess && page.isDuplicate === null;
    }
    return page.isDuplicate === 0;
  };
  const {
    toggleSelectPage,
    showCheckboxes,
    mergeInProgress,
    timelineIDForSplitCheckboxes,
    splitInProgress,
    toggleTooManyError,
    tooManyError,
  } = useTimelineStore(
    (state) => ({
      toggleSelectPage: state.toggleSelectPage,
      showCheckboxes: state.showCheckboxes,
      mergeInProgress: state.mergeInProgress,
      timelineIDForSplitCheckboxes: state.timelineIDForSplitCheckboxes,
      splitInProgress: state.splitInProgress,
      toggleTooManyError: state.toggleTooManyError,
      tooManyError: state.tooManyError,
    }),
    shallow,
  );

  const handlePageSelection = useCallback(() => {
    if (splitInProgress && areMaximumPagesForSplitSelected && !isPageSelected) {
      if (!tooManyError) {
        toggleTooManyError();
      }
    } else {
      if (tooManyError) {
        toggleTooManyError();
      }
      toggleSelectPage({
        ...page,
        documentFileName: page?.documentFileName,
      });
    }
  }, [splitInProgress, areMaximumPagesForSplitSelected, isPageSelected, page, tooManyError]);

  const width = useMemo(
    () => `${Math.max(String(page.pageNumber).length * 8, 32)}px`,
    [page.pageNumber],
  );

  return !showThumbnails ? (
    <Box style={customStyle} sx={{ display: 'flex' }} id={`page-${page.id}`}>
      <Card sx={[smallPageCareStyles.pageCard, isCurrentPage && smallPageCareStyles.selected]}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '50px',
          }}
        >
          {isInTimelineView &&
          (mergeInProgress ||
            (splitInProgress && timelineIDForSplitCheckboxes === page.entryID)) ? (
            <Checkbox
              sx={{
                width: 15,
                height: 15,
                '& .MuiSvgIcon-root': {
                  fontSize: 16,
                },
              }}
              checked={isPageSelected}
              onClick={(e) => {
                e.preventDefault();
                handlePageSelection();
              }}
            />
          ) : (
            <PageViewedControl page={page} handleToggleViewed={handleToggleViewed} />
          )}

          <Typography
            sx={[
              {
                fontSize: '0.8rem',
                mx: '0.2rem',
                color: theme.palette.modernGrey.default,
                width: width,
              },
              isCurrentPage && smallPageCareStyles.selectedText,
            ]}
          >
            {page.pageNumber}
          </Typography>
        </Box>

        <LinkWithQuery
          timelineID={currentTimelineID}
          documentID={currentDocumentID}
          to={
            isInTimelineView
              ? `${page.entryID}/${page.id}`
              : `${currentPageID ? '../' : ''}${page.documentID}/${page.id}`
          }
          style={{
            cursor: 'pointer',
            textDecoration: 'none',
            display: 'flex',
            width: '70%',
            height: '100%',
            marginTop: 'auto',
            flex: '1',
            justifyContent: 'left',
            overflow: 'hidden',
            marginLeft: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: showCheckboxes && isInTimelineView ? '1.0rem' : '.5rem',
              width: '100%',
              justifyContent: 'left',
              height: '100%',
            }}
          >
            <Truncate maxWidth="85%">
              {isInTimelineView ? (
                <OverflowText
                  placement="right"
                  tooltipTitle={page?.documentFileName}
                  sx={{ color: theme.palette.modernGrey.default }}
                >
                  <Typography
                    sx={{ display: 'block', fontSize: '0.75rem' }}
                    variant={isCurrentPage ? 'pageCardDocNameSelected' : 'pageCardDocName'}
                  >
                    {page?.documentFileName}
                  </Typography>
                </OverflowText>
              ) : (
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    color: '#344054',
                    fontWeight: isCurrentPage && 600,
                  }}
                >
                  {pageFileStatus === 'PROCESSED' || isFileProcessor ? pageSourceObj?.name : ''}
                </Typography>
              )}
            </Truncate>
          </Box>
        </LinkWithQuery>

        <Box
          name="page-icons-box"
          sx={{
            display: 'inline-flex',
            flexDirection: 'row',
            minWidth: '26px',
            justifyContent: 'flex-end',
            paddingLeft: 0.5,
          }}
        >
          {isPageUnconfirmedDuplicate(caseVersion, page) && (
            <PageDuplicateControl
              pageID={page.id}
              caseID={caseID}
              timelineID={currentTimelineID}
              isUnconfirmedDuplicate={isPageUnconfirmedDuplicate(caseVersion, page)}
            />
          )}
          {Boolean(page.isDuplicate) && (
            <Tooltip title="Page marked as duplicate" placement="top">
              <Box sx={{ display: 'flex' }}>
                <PageCardDuplicate width="0.9rem" height="0.9rem" />
              </Box>
            </Tooltip>
          )}
          <PageNoteControl
            page={page}
            noteCount={noteCount}
            documentID={page.documentID}
            pageNumber={page.pageNumber}
          />
          {!isFileProcessor && (
            <PageFlagControl
              sx={[
                {
                  color: theme.palette.modernGrey.default,
                  height: '14px',
                  display: 'flex',
                },
                isCurrentPage && smallPageCareStyles.selectedText,
              ]}
              pageID={page.id}
              isFavourite={page.isFavourite}
              onClick={handleToggleFavourite}
            />
          )}
        </Box>
      </Card>
    </Box>
  ) : (
    <ThumbnailPage
      page={page}
      caseID={caseID}
      timelineID={currentTimelineID}
      pageSourceObj={pageSourceObj}
      isCurrentPage={isCurrentPage}
      isInTimelineView={isInTimelineView}
      handlePageSelection={handlePageSelection}
      mergeInProgress={mergeInProgress}
      splitInProgress={splitInProgress}
      timelineIDForSplitCheckboxes={timelineIDForSplitCheckboxes}
      isPageSelected={isPageSelected}
      pageControls={{ handleToggleViewed, handleToggleFavourite }}
      showFlagAsImportant={!isFileProcessor}
    />
  );
}

export function SmallPageCardSkeleton() {
  return (
    <Box display="flex" alignItems="center" height={26} mx="1rem">
      <Box flex="18%" display="flex" alignItems="center" px="6px">
        <Skeleton variant="circular" width={8} height={8} />
        <Skeleton variant="text" width={20} height={18} sx={{ mx: '0.25rem' }} />
      </Box>
      <Box flex="74%">
        <Skeleton variant="text" width={210} height={18} />
      </Box>
      <Box flex="8%" display="flex" alignItems="center">
        <Skeleton variant="rectangular" width={48} height={12} sx={{ mx: '6px' }} />
      </Box>
    </Box>
  );
}

export default memo(SmallPageCard);
