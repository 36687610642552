export function ConfigureIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
      <path
        d="M1.5 3.6665L11.5 3.6665M11.5 3.6665C11.5 5.04722 12.6193 6.1665 14 6.1665C15.3807 6.1665 16.5 5.04722 16.5 3.6665C16.5 2.28579 15.3807 1.1665 14 1.1665C12.6193 1.1665 11.5 2.28579 11.5 3.6665ZM6.5 10.3332L16.5 10.3332M6.5 10.3332C6.5 11.7139 5.38071 12.8332 4 12.8332C2.61929 12.8332 1.5 11.7139 1.5 10.3332C1.5 8.95246 2.61929 7.83317 4 7.83317C5.38071 7.83317 6.5 8.95246 6.5 10.3332Z"
        stroke="#475467"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
