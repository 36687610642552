import { gql, useApolloClient } from '@apollo/client';
import { Box, Stack, Button } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { useIsFileProcessor } from '../../containers/AccountSettings/useFileProcessing';
import { useUserGroup } from '../../containers/MyCases/useCases';
import useChangeEntityMutation from '../../containers/Timeline/gql/useUpdateEntity';
import ToolbarEntitySelect, { Option } from '../../containers/Timeline/ToolbarEntitySelect';
import useTimelineStore from '../../containers/Timeline/useTimelineStore';
import { ChangeEntityInput } from '../../__generated__/graphql';
import { Page as PageObject } from '../../containers/Timeline/types/timelineTypes';
import useBulkUpdatePageTags from '../../containers/Timeline/gql/useBulkUpdatePageTags';
import { useUpdateDocumentTags } from './useTimelineEntryTags';
import useEntities from '../../containers/Timeline/gql/useEntities';
import { useCachedTimelineEntry } from './hooks/useCachedTimelineEntry';

export default function FileProcessorToolbar({ page }: { page: PageObject }) {
  const isFileProcessor = useIsFileProcessor();
  const { data: userGroup } = useUserGroup();
  const processorOrLabeller = isFileProcessor || userGroup === 'Labeller';
  const params = useParams();
  const caseID = params.caseID!;

  const [bulkUpdatePageTags] = useBulkUpdatePageTags();
  const { mutateAsync: updateDocumentTags } = useUpdateDocumentTags();

  const { timelineEntry, refreshTimelineEntry } = useCachedTimelineEntry();

  const hasPageTags = timelineEntry?.pages?.some((page) => page.tags?.length > 0);

  const handleClearAllPageTags = () => {
    bulkUpdatePageTags({
      where: { timeline_entry_id: timelineEntry?.id },
      data: {
        tagsIds: [],
      },
      newPagesState: timelineEntry?.pages?.map((page) => ({
        __typename: 'PageObject',
        id: page.id,
        tags: [],
      })),
      onCompleted: () => {
        toast.success(
          'Content labels cleared.',
          isFileProcessor
            ? { position: 'bottom-right', autoClose: 500 }
            : {
                autoClose: 500,
                hideProgressBar: true,
              },
        );
        refreshTimelineEntry();
      },
      onError: () => {
        toast.error('Error clearing content labels.');
      },
    });
  };

  const handleClearAllDocumentTags = async () => {
    await updateDocumentTags({
      entryID: page.entryID,
      tags: [],
      tagType: ['Content', 'Specialist'],
    });
    refreshTimelineEntry();
  };

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-evenly', height: '36px' }}>
      {processorOrLabeller && (
        <Button
          onClick={() => handleClearAllDocumentTags()}
          disabled={timelineEntry?.document_tags?.length === 0 ?? true}
          sx={{
            cursor: 'pointer',
            color: 'primary.light',
            fontSize: '0.85em',
            fontFamily: 'Inter',
            fontWeight: 700,
          }}
        >
          Clear All Document Tags
        </Button>
      )}
      {processorOrLabeller && <EntitySelection page={page} caseID={caseID} />}
      {processorOrLabeller && (
        <Button
          onClick={() => handleClearAllPageTags()}
          disabled={!hasPageTags}
          sx={{
            cursor: 'pointer',
            color: 'primary.light',
            fontSize: '0.85em',
            fontFamily: 'Inter',
            fontWeight: 700,
          }}
        >
          Clear All Page Tags
        </Button>
      )}
    </Stack>
  );
}

function EntitySelection({ page, caseID }: { page: PageObject; caseID: string }) {
  const [changeEntityMutation, entityState]: any = useChangeEntityMutation();
  const client = useApolloClient();
  const queryClient = useQueryClient();

  const setEntity = async (type: string, entity: Option) => {
    if (entityState.loading) {
      return;
    }
    const data: ChangeEntityInput = {
      caseID: caseID,
      entryID: timelineEntry.id,
      origin: 'PROCESSOR',
      current_page_id: page.id,
    };

    if (type === 'author') {
      if (entity.id === timelineEntry.author_id) {
        return;
      }
      data.author = entity;
      await changeEntityMutation({ data });
    } else if (type === 'organization') {
      if (entity.id === timelineEntry.org_id) {
        return;
      }
      data.organization = entity;
      await changeEntityMutation({ data });
    }
    queryClient.invalidateQueries(['documentTags'], timelineEntry.id);
  };

  const timelineEntry = client.readFragment({
    id: client.cache.identify({
      id: page.entryID,
      __typename: 'TimelineEntryObject',
    }),
    fragment: gql`
      fragment TimelineEntryWithTaggedPages2 on TimelineEntryObject {
        id
        author_id
        org_id
        pages {
          id
          tags {
            id
            name
            type
            parent_tag_id
            origin
          }
        }
      }
    `,
  });

  const {
    sortedOrgsForCase,
    sortedOrgsForEntry,
    allOrgs,
    sortedAuthorsForCase,
    sortedAuthorsForEntry,
    allAuthors,
    isLoading,
  } = useEntities(caseID, page?.entryID);

  const { hiddenEntities } = useTimelineStore(
    (state) => ({
      hiddenEntities: state.hiddenEntities,
    }),
    shallow,
  );

  const filteredAuthors = useMemo(() => {
    return sortedAuthorsForCase.filter((author) => {
      return !hiddenEntities.includes(author.value) && !author.hidden;
    });
  }, [sortedAuthorsForCase, hiddenEntities]);

  const filteredOrgs = useMemo(() => {
    return sortedOrgsForCase.filter((org) => {
      return !hiddenEntities.includes(org.value) && !org.hidden;
    });
  }, [sortedOrgsForCase, hiddenEntities]);

  return (
    <Box
      id="entitiesToolbarContainer"
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        justifyContent: 'right',
        height: '36px',
        backgroundColor: 'selectedGrey.main',
        marginRight: '.3rem',
        lineHeight: '28px',
      }}
    >
      {allAuthors && sortedAuthorsForEntry && (
        <ToolbarEntitySelect
          entityName="author"
          valueID={timelineEntry?.author_id}
          optionsForCase={filteredAuthors}
          optionsForEntry={sortedAuthorsForEntry}
          allOptions={allAuthors}
          setValues={setEntity}
          allowNewOptions={true}
          loading={entityState.loading || !timelineEntry || isLoading}
          enableUnknown={false}
          onFocus={() => {}}
          onBlur={() => {}}
        />
      )}
      {allOrgs && sortedOrgsForEntry && (
        <ToolbarEntitySelect
          entityName="organization"
          valueID={timelineEntry?.org_id}
          optionsForCase={filteredOrgs}
          optionsForEntry={sortedOrgsForEntry}
          allOptions={allOrgs}
          setValues={setEntity}
          allowNewOptions={true}
          loading={entityState.loading || !timelineEntry || isLoading}
          enableUnknown={false}
          onFocus={() => {}}
          onBlur={() => {}}
        />
      )}
    </Box>
  );
}
