import { useQuery } from '@tanstack/react-query';
import { authenticatedRequest } from '../../../api';

export type TimelineEntryRow = {
  id: string;
  entry_date: string;
  organization_name: string | null;
  organization_origin: string | null;
  org_id: string | null;
  author_name: string | null;
  author_origin: string | null;
  author_id: string | null;
  content_tags: {
    id: string;
    value: string;
  }[];
  first_page_id: string;
  source_id: string;
  source_name: string;
  timeline_id: string;
  page_count: number;
  document_name: string;
  all_pages_marked_duplicate: boolean;
  monetary_total: string;

  marked_important: boolean;
  tag_names?: string;
  file_id: string;
};

async function fetchTimelineEntries(
  caseID: string,
  allowedContentIds: string[],
  contentIdsToExclude?: string[],
  shouldHideDuplicates?: boolean,
  uploadDates?: string[],
): Promise<TimelineEntryRow[]> {
  const res = await authenticatedRequest<TimelineEntryRow[]>({
    method: 'GET',
    url: `/timeline-entry/getTimelineEntriesForReportSection/${caseID}`,
    params: {
      allowedContentIds: allowedContentIds.join(','),
      contentIdsToExclude: contentIdsToExclude?.join(','),
      shouldHideDuplicates,
      uploadDates,
    },
  });

  return res.data;
}

export function useGetTimelineEntriesForReportSection(
  caseID: string,
  allowedContentIds: string[],
  contentIdsToExclude?: string[],
  shouldHideDuplicates?: boolean,
  uploadDates?: string[],
): {
  data: TimelineEntryRow[] | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { data, isLoading, isError } = useQuery(
    [
      'getTimelineEntriesForReportSection',
      caseID,
      allowedContentIds ?? null,
      contentIdsToExclude,
      shouldHideDuplicates,
      uploadDates,
    ],
    () =>
      fetchTimelineEntries(
        caseID,
        allowedContentIds,
        contentIdsToExclude,
        shouldHideDuplicates,
        uploadDates,
      ),
    {
      enabled: !!caseID, // Only run query if caseID is provided
      refetchOnWindowFocus: false,
    },
  );

  return { data, isLoading, isError };
}

//fetchTimelineEntriesForDocument
async function fetchTimelineEntryDocumentDetails(
  caseID: string,
  documentID: string,
): Promise<TimelineEntryRow> {
  const res = await authenticatedRequest<TimelineEntryRow>({
    method: 'GET',
    url: `/api/v1/case/${caseID}/documents/${documentID}/details`,
    params: {
      caseID,
    },
  });

  return res.data;
}

export function useFetchTimelineEntryDocumentDetails(
  caseID: string,
  documentID: string,
): {
  data: TimelineEntryRow | undefined;
  isFetching: boolean;
  isError: boolean;
} {
  const { data, isFetching, isError } = useQuery(
    ['getTimelineEntryDetails', caseID, documentID],
    () => fetchTimelineEntryDocumentDetails(caseID, documentID),
    {
      enabled: !!documentID,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return { data, isFetching, isError };
}
