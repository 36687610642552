import { Routes, Route } from 'react-router-dom';
// import IndexCustomization from './IndexCustomization';
import CasesManagement from './CasesManagement';

function AdminContainer() {
  return (
    <Routes>
      <Route path="/cases" element={<CasesManagement />} />
      {/* <Route path="/index" element={<IndexCustomization />} />  //disabled until feature is
      implemented */}
    </Routes>
  );
}

export default AdminContainer;
