import { useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import {
  GetCaseContentFiltersQuery,
  GetCaseSourceFiltersQuery,
  useGetCaseContentFiltersQuery,
  useGetCaseSourceFiltersQuery,
} from '../../../__generated__/graphql';
import { toObjectMap } from '../../../utils/arrayUtils';
import useDocumentSearchStore, { initialDocumentSearchStoreState } from '../useDocumentSearchStore';
import useCaseFiles from '../../Files/useCaseFiles';
import useCaseDocumentTagFilters, {
  DocumentTagFilter,
} from '../api-queries/useCaseDocumentTagFilters';

type SourceList = DocumentTagFilter[];
type ContentList = DocumentTagFilter[];
type SubContentTags = NonNullable<ContentList[number]['subItems']>;
type FileList = { value: string; label: string }[];

export function updateSubTags(
  contentList: ContentList,
  subTagsToUpdate: SubContentTags,
  isValueSelected: boolean | ((tag: string) => boolean),
) {
  const result: Record<string, Record<string, boolean>> = {};

  const contentMap = toObjectMap(contentList, (item) => String(item.tag_id));
  subTagsToUpdate.forEach((contentTag) => {
    const parentTag = contentMap[1000 + contentTag.category_id];
    result[parentTag?.value] ??= {};
    result[parentTag?.value][contentTag.value] =
      typeof isValueSelected === 'function' ? isValueSelected(contentTag.value) : isValueSelected;
  });

  return result;
}

type TagSelection = {
  source: { items: Record<string, boolean> };
  content: { items: Record<string, boolean>; subItems: Record<string, Record<string, boolean>> };
  file: { items: Record<string, boolean> };
};

export function mapTags(
  sourceList: SourceList,
  contentList: ContentList,
  fileList: FileList,
  data: TagSelection,
) {
  const sourcesMap = Object.fromEntries(sourceList.map((item) => [item.value, item]));
  const contentTypesMap = Object.fromEntries(contentList.map((item) => [item.value, item]));
  const subContentTypesMap = Object.fromEntries(
    contentList
      .filter((content) => content.subItems)
      .flatMap((content) => content.subItems!)
      .map((item) => [item.value, item]),
  );
  const fileMap = Object.fromEntries(fileList.map((item) => [item.value, item]));

  const selectedSources = Object.entries(data.source.items ?? {})
    .filter(([, value]) => value)
    .map(([key]) => sourcesMap[key])
    .filter((item) => item != null);

  const selectedContentTypes = Object.entries(data.content?.items ?? {})
    .filter(([, value]) => value)
    .map(([key]) => contentTypesMap[key])
    .filter((item) => item != null);

  const selectedSubContentTypes = Object.values(data.content?.subItems ?? {})
    .flatMap((subItem) => Object.entries(subItem))
    .filter(([, value]) => value)
    .map(([key]) => subContentTypesMap[key])
    .filter((item) => item != null);

  const selectedDocuments = Object.entries(data.file?.items ?? {})
    .filter(([, value]) => value)
    .map(([key]) => fileMap[key])
    .filter((item) => item != null);

  return {
    selectedSources,
    selectedContentTypes,
    selectedSubContentTypes,
    selectedDocuments,
  };
}

export function useResetTimelineFilters() {
  const [filters, setFilters, setSearchStr, setGoingToSource] = useDocumentSearchStore(
    (state) => [state.filters, state.setFilters, state.setSearchStr, state.setGoingToSource],
    shallow,
  );
  const { caseID } = useParams();
  const { data: caseTags } = useCaseDocumentTagFilters({ caseID: caseID! });
  const sourceList = caseTags?.sourceTags!;
  const contentList = caseTags?.contentTags!;

  const { data: caseFiles } = useCaseFiles(caseID ?? '');
  const fileList =
    caseFiles?.map((file) => ({ value: file.documentID, label: file.docFileName })) || [];

  return () => {
    setSearchStr('');
    setFilters({
      ...initialDocumentSearchStoreState,
      documentOrder: filters.documentOrder,
      ...mapTags(sourceList, contentList, fileList, {
        source: {
          items: Object.fromEntries(sourceList.map((source) => [source.value, true])),
        },
        content: {
          items: Object.fromEntries(contentList.map((content) => [content.value, true])),
          subItems: updateSubTags(
            contentList,
            contentList
              .filter((content) => content.subItems)
              .flatMap((content) => content.subItems!),
            true,
          ),
        },
        file: {
          items: Object.fromEntries(fileList.map((file) => [file.value, true])),
        },
      }),
    });
    setGoingToSource(true);
  };
}
