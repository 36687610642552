import { useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import useDocumentSearchStore from './useDocumentSearchStore';
import useCaseFiles from '../Files/useCaseFiles';
import useCaseDocumentTagFilters from './api-queries/useCaseDocumentTagFilters';
/**
 *
 * Resets the filters when the caseID changes
 */
export default function FilterController({ children }) {
  const { caseID } = useParams();
  const { data: caseList } = useCaseFiles(caseID);

  const fileList =
    caseList?.map((file) => ({ value: file.documentID, label: file.docFileName })) || [];

  const documentList = useMemo(() => fileList.map((file) => file.value) || null, [fileList]);

  const [currentCaseID, setCurrentCaseID] = useDocumentSearchStore((state) => [
    state.currentCaseID,
    state.setCurrentCaseID,
  ]);
  const [filters, setSearchStr, setFilters, _areFiltersUpdating, setAreFiltersUpdating] =
    useDocumentSearchStore(
      (state) => [
        state.filters,
        state.setSearchStr,
        state.setFilters,
        state.areFiltersUpdating,
        state.setAreFiltersUpdating,
      ],
      shallow,
    );

  const { state } = useLocation();

  const { data: caseTags } = useCaseDocumentTagFilters({ caseID });
  const sourceList = useMemo(() => caseTags?.sourceTags ?? null, [caseTags?.sourceTags]);
  const contentList = useMemo(() => caseTags?.contentTags ?? null, [caseTags?.contentTags]);

  const subContents = useMemo(() => {
    const subContents =
      contentList
        ?.filter((content) => content.subItems.length > 0)
        .map((content) => content?.subItems)
        .flatMap((subItem) => subItem) ?? null;
    return subContents;
  }, [contentList]);

  useEffect(() => {
    if (state?.resetFilters) {
      setAreFiltersUpdating(true);
    }
  }, [state?.resetFilters]);

  useEffect(() => {
    if (currentCaseID !== caseID) {
      setAreFiltersUpdating(true);
      setSearchStr('');
    }
  }, [caseID]);

  useEffect(() => {
    if (
      (currentCaseID !== caseID || state?.resetFilters) &&
      sourceList != null &&
      documentList != null &&
      contentList != null &&
      subContents != null
    ) {
      setFilters({
        ...filters,
        sources: sourceList,
        documentID: documentList,
        contentTypes: contentList,
        subContentTypes: subContents,
        // default state is hideDuplicates: true, however go to source can be triggered
        // on a confirmed duplicate, so we must show duplicates when a reset is triggered manually
        ...(state?.resetFilters && { hideDuplicates: false }),
      });
      setCurrentCaseID(caseID);
      setAreFiltersUpdating(false);
    }
  }, [caseID, sourceList, contentList, documentList, subContents, state?.resetFilters]);

  return children;
}
