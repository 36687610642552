import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grow,
  Paper,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { CloseOutlined, KeyboardArrowDown, AddCircleOutline } from '@mui/icons-material';
import { gql, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import Loading from '../../../components/common/Loading';
import useBulkUpdatePageTags from '../gql/useBulkUpdatePageTags';
import { useUserGroup } from '../../MyCases/useCases';
import Theme from '../../../theme';

function AutoCompleteContentTypes(props) {
  const { autoCompleteOptions, currentlySelectedOptions, isList, handleChangeLabel } = props;

  const handleChange = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      <Autocomplete
        disablePortal
        options={autoCompleteOptions.sort((a, b) =>
          b.label.toLowerCase() < a.label.toLowerCase() ? 1 : -1,
        )}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box
            key={option.id}
            sx={{
              mx: '0.2rem',
              my: '0.1rem',
              display: 'inline-block',
            }}
          >
            <Chip
              label={option.label}
              onClick={() => handleChangeLabel(option, true)}
              variant="outlined"
              disabled={Boolean(currentlySelectedOptions?.find((item) => item.id === option.id))}
              classes="small-page-card"
              className="unmask"
            />
          </Box>
        )}
        popupIcon={<KeyboardArrowDown />}
        freeSolo
        forcePopupIcon
        onChange={handleChange}
        ListboxProps={{
          sx: {
            maxHeight: '22rem',
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            name="content-search"
            onClick={(e) => {
              e.stopPropagation();
            }}
            placeholder="Search or select tags to add"
          />
        )}
        sx={{
          ...editContentMenuStyles.autocomplete,
          width: isList && '21.2rem',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '27rem',
        }}
      >
        {currentlySelectedOptions?.map((item) => (
          <Box key={item.id} sx={{ mx: '0.2rem', my: '0.1rem' }}>
            <Tooltip title={item.label}>
              <Chip
                label={item.label}
                deleteIcon={<CloseOutlined sx={editContentMenuStyles.deleteContentIcon} />}
                onDelete={() => handleChangeLabel(item, false)}
                variant="outlined"
                sx={{ cursor: 'pointer', maxWidth: '18rem' }}
                classes="small-page-card"
                className="unmask"
              />
            </Tooltip>
          </Box>
        ))}
      </Box>
    </>
  );
}

function EditContentButton(props) {
  const {
    type,
    autoCompleteOptions = [],
    currentlySelectedOptions = [],
    isList,
    handleChangeLabel,
    menuOpen,
    setMenuOpen,
  } = props;

  let isLoading;
  const anchorRef = React.useRef(null);

  //Toggle the menu open/closed
  const handleToggle = (e) => {
    e.preventDefault();
    setMenuOpen(true);
  };

  //Close the menu and drop the location ref
  const handleClose = () => {
    setMenuOpen(false);
  };

  if (isList) {
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <AutoCompleteContentTypes
          type={type}
          isList={true}
          autoCompleteOptions={autoCompleteOptions}
          currentlySelectedOptions={currentlySelectedOptions}
          handleChangeLabel={handleChangeLabel}
        />
      </ClickAwayListener>
    );
  }

  return (
    <>
      <div
        ref={anchorRef}
        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        style={{ display: 'inline', marginTop: 2, marginBottom: -3, marginLeft: 2 }}
      >
        <AddCircleOutline
          onClick={handleToggle}
          sx={{
            fontSize: '1.2rem',
            marginBottom: '3px',
            color: Theme.palette.primary.light,
            cursor: 'pointer',
          }}
        />
      </div>

      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        sx={{ zIndex: 2000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper variant="outlined">
              <ClickAwayListener onClickAway={handleClose}>
                {isLoading ? (
                  <Loading />
                ) : (
                  <Box sx={{ p: '12px' }}>
                    <Typography sx={{ fontSize: '14px', pb: '8px' }}>{`Edit ${type}s`}</Typography>
                    <Divider />
                    <AutoCompleteContentTypes
                      autoCompleteOptions={autoCompleteOptions}
                      currentlySelectedOptions={currentlySelectedOptions}
                      handleChangeLabel={handleChangeLabel}
                    />
                  </Box>
                )}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default EditContentButton;

const editContentMenuStyles = {
  autocomplete: {
    py: '8px',
    '& .MuiInputBase-root': {
      fontSize: '0.8rem',
      margin: '0.6rem 0 0 0',
      marginBottom: 0,
      padding: 0,
      paddingLeft: 0.7,
    },
    '& .MuiAutocomplete-endAdornment svg': {
      color: '#667085',
    },
    '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
      fontSize: '12px',
    },
  },
  deleteContentIcon: {
    '&&': {
      color: '#667085 !important',
      fontSize: '0.75rem !important',
      marginLeft: '-0.2rem',
    },
  },
};
