import { useQuery } from '@tanstack/react-query';
import {
  getCaseContentFilters,
  getCaseSourceFilters,
  DocumentTagFilter,
  DocumentTagCategoriesMap,
} from '../../../api';

async function fetchTags(caseID: string) {
  if (!caseID) {
    return;
  }
  const { data: sourceTags } = (await getCaseSourceFilters(caseID)) as {
    data: DocumentTagFilter[];
  };
  let { data: contentTags } = (await getCaseContentFilters(caseID)) as {
    data: DocumentTagFilter[];
  };

  contentTags = sortIntoCategories(contentTags);
  const sourceList =
    sourceTags?.map((tag) => ({
      ...tag,
      value: tag.tag_id,
      label: tag.name,
    })) ?? [];

  const contentList =
    contentTags?.map((tag) => ({
      ...tag,
      value: tag.tag_id,
      label: tag.name,
      parent_tag_id: null,
      subItems:
        tag?.subItems?.map((t) => ({
          ...t,
          value: t.tag_id,
          label: t.name,
          parent_tag_id: tag.tag_id,
        })) ?? [],
    })) ?? [];

  return {
    sourceTags: [
      ...sourceList,
      { tag_id: -1, value: -1, label: 'No Source', parent_tag_id: null, subItems: [] },
    ] as DocumentTagFilter[],
    contentTags: [
      ...contentList,
      { tag_id: -1, value: -1, label: 'No Label', parent_tag_id: null, subItems: [] },
    ] as DocumentTagFilter[],
  };
}

export default function useCaseDocumentTagFilters({ caseID }: { caseID: string }) {
  return useQuery(['case-document-tags', caseID], () => fetchTags(caseID), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!caseID,
  });
}

// For each index in DocumentTagCategoriesMap that isn't 1 or 2, add a tag for the category
// For each tag in contentTags that has that category, add it to the new category tag as subItems: [tag]

function sortIntoCategories(contentTags: DocumentTagFilter[]): DocumentTagFilter[] {
  // Create a map to hold category tags with subItems
  const categorizedTags: { [key: number]: DocumentTagFilter } = {};

  // Initialize category tags for categories other than 1 (Source) and 2 (Content)
  for (const [key, category] of Object.entries(DocumentTagCategoriesMap)) {
    const categoryId = Number(key);
    if (categoryId !== 1 && categoryId !== 2) {
      categorizedTags[categoryId] = {
        tag_id: 1000 + categoryId,
        name: category,
        category_id: categoryId,
        subItems: [],
      };
    }
  }

  // Group contentTags into their respective category tags based on the category
  contentTags.forEach((tag) => {
    const category = tag.category_id; // Assuming `category` is a field in DocumentTag indicating the tag's category tag_id
    if (category && categorizedTags[category]?.subItems) {
      categorizedTags[category].subItems?.push(tag);
    }
  });

  // Extract the categorized tags and filter out empty categories
  const categorizedTagsArray = Object.values(categorizedTags).filter(
    (tag) => tag.subItems && tag.subItems.length > 0,
  );

  // Optionally, remove grouped tags from the original contentTags array if needed
  const ungroupedTags = contentTags.filter(
    (tag) => !(tag.category_id && categorizedTags[tag.category_id]),
  );

  // Combine ungrouped tags and categorized tags
  return [...ungroupedTags, ...categorizedTagsArray];
}
