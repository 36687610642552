/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  Divider,
  Button,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { formatFormalDate } from '../../library/utilities/useDates';
import CaseSettings from './CaseSettings';
import DialogModal from '../../components/common/DialogModal';
import useFeatureFlags from '../../config/useFeatureFlags';
import useNavigationStore from '../Timeline/useNavigationStore';
import { useCaseAssignmentMutation } from './useCaseAssignment';
import InputWithIcon from '../../components/common/HTML_components/InputWithIcon/InputWithIcon';
import SearchIcon from '../../components/icons/SearchIcon';

const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

const caseTableStyles = {
  root: {
    width: '100%',
    cursor: 'pointer',
  },
  paper: (theme) => ({
    width: '100%',
    marginBottom: theme.spacing(2),
  }),
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
};

function CaseTableView({ caseList, userList, searchTerm, onUploadFilesClick }) {
  const navigate = useNavigate();
  const [clickedCase, setClickedCase] = useState(null);
  const { data: featureFlags, isLoading: featureFlagsLoading } = useFeatureFlags();

  const FileProcessingEnabled = !featureFlagsLoading && featureFlags?.fileProcessingEnabled;
  const hideCaseNumbersFromUsers = !featureFlagsLoading && featureFlags?.hideCaseNumbersFromUsers;
  const [order, setOrder] = React.useState(window.localStorage.getItem('Order') || 'desc');
  const [orderBy, setOrderBy] = React.useState(
    window.localStorage.getItem('OrderBy') || 'lastOpened',
  );
  const [eventAlert, setEventAlert] = useState('');
  const [caseProcessingModal, setCaseProcessingModal] = useState(false);

  const setCurrentView = useNavigationStore((state) => state.setCurrentView);

  useEffect(() => {
    const OrderBy = window.localStorage.getItem('OrderBy');

    if (OrderBy === null || OrderBy === undefined) {
      window.localStorage.setItem('OrderBy', orderBy);
    } else if (OrderBy !== orderBy) {
      setOrderBy(OrderBy);
    }
  }, [orderBy, setOrderBy]);

  useEffect(() => {
    const Order = window.localStorage.getItem('Order');
    if (Order === null || Order === undefined) {
      window.localStorage.setItem('Order', order);
    } else if (Order !== order) {
      setOrder(Order);
    }
  }, [order, setOrder]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    window.localStorage.setItem('OrderBy', property);
    window.localStorage.setItem('Order', newOrder);
    setOrder(newOrder);
    setOrderBy(property);
  };

  const onProcessingRowClick = () => {
    setCaseProcessingModal(true);
  };

  useEffect(() => {
    if (clickedCase) {
      if (clickedCase.caseStatus === 'READY') {
        setCurrentView('Timeline');
        navigate(`/case/${clickedCase.caseID}`);
      } else {
        setCurrentView('Document');
        navigate(`/case/${clickedCase.caseID}/files`);
      }
    }
  }, [clickedCase]);

  const filteredCaseList = caseList?.filter(
    (caseInstance) => !caseInstance.archived_at && !caseInstance.archived_by,
  );

  return (
    <Box sx={caseTableStyles.root}>
      <Paper sx={caseTableStyles.paper}>
        <TableContainer>
          <Table
            sx={caseTableStyles.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              sx={[
                caseTableStyles.root,
                caseTableStyles.paper,
                caseTableStyles.table,
                caseTableStyles.visuallyHidden,
              ]}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredCaseList?.length}
              hideCaseNumbersFromUsers={hideCaseNumbersFromUsers}
            />
            <TableBody>
              {stableSort(filteredCaseList, getComparator(order, orderBy)).map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <CaseTableRow
                    key={row.caseID}
                    caseInstance={row}
                    labelId={labelId}
                    setEventAlert={setEventAlert}
                    isFileProcessingEnabled={FileProcessingEnabled}
                    onUploadFilesClick={onUploadFilesClick}
                    onProcessingRowClick={onProcessingRowClick}
                    setClickedCase={setClickedCase}
                    searchTerm={searchTerm}
                    hideCaseNumbersFromUsers={hideCaseNumbersFromUsers}
                    userList={userList}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={eventAlert !== ''}
        autoHideDuration={3000}
        onClose={() => setEventAlert('')}
      >
        <Alert severity="success" onClose={() => setEventAlert('')}>
          {eventAlert}
        </Alert>
      </Snackbar>

      <DialogModal
        open={caseProcessingModal}
        onClose={() => setCaseProcessingModal(false)}
        header={<Typography variant="subtitle1">Processing Case</Typography>}
        content={
          <>
            <p>Your case is currently being processed and has been temporarily locked.</p>
            <p>
              We appreciate your patience and will provide an update as soon as your case is ready.
            </p>
          </>
        }
      />
    </Box>
  );
}

function descendingComparator(a, b, orderBy) {
  const statusA = getRowStatusText(a.caseStatus, a.pagesViewed);
  const statusB = getRowStatusText(b.caseStatus, b.pagesViewed);
  if (b[orderBy] === null && a[orderBy === null]) {
    return 0;
  }
  if (b[orderBy] === null) {
    return -1;
  }
  if (a[orderBy] === null) {
    return 1;
  }

  if (orderBy === 'ref_id' || orderBy === 'pageCount') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  if (orderBy === 'caseStatus') {
    if (statusB.toLowerCase() < statusA.toLowerCase()) {
      return -1;
    }
    if (statusB.toLowerCase() > statusA.toLowerCase()) {
      return 1;
    }
  }
  if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
    return -1;
  }
  if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
    return 1;
  }
  return 0;
}

const getRowStatusText = (caseStatus) => {
  switch (caseStatus) {
    case null:
      return 'NEW';
    case 'REQUIRES_PROCESSOR':
      return 'PROCESSING';
    case 'READY':
      return 'READY';
    default:
      return caseStatus;
  }
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = (hideCaseNumbersFromUsers) =>
  [
    !hideCaseNumbersFromUsers && {
      id: 'ref_id',
      numeric: true,
      disablePadding: false,
      label: 'Case #',
      width: '10%',
    },
    {
      id: 'caseName',
      numeric: false,
      disablePadding: false,
      label: 'Case Name',
      width: '21%',
    },
    {
      id: 'fullName',
      numeric: true,
      disablePadding: false,
      label: 'Claimant',
      width: '14%',
    },
    {
      id: 'assignedTo',
      numeric: false,
      disablePadding: false,
      label: 'Assigned To',
      width: '12%',
    },
    {
      id: 'pageCount',
      numeric: true,
      disablePadding: false,
      label: 'Pages',
      width: '6%',
    },
    {
      id: 'dueDate',
      numeric: true,
      disablePadding: false,
      label: 'Due Date',
      width: '19%',
    },
    {
      id: 'caseStatus',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      width: '13%',
    },
    {
      id: 'Actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ].filter(Boolean);

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, hideCaseNumbersFromUsers } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells(hideCaseNumbersFromUsers).map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            {headCell.id !== 'Actions' && headCell.id !== 'assignedTo' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="h4">{headCell.label}</Typography>
                {orderBy === headCell.id ? (
                  <span style={caseTableStyles.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography variant="h4" sx={{ marginTop: 0.4 }}>
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function CaseTableRow({
  caseInstance,
  hideCaseNumbersFromUsers,
  setEventAlert,
  setClickedCase,
  searchTerm,
  userList,
}) {
  const {
    ref_id,
    caseID,
    caseName,
    dueDate,
    fullName,
    pageCount = 0,
    caseStatus,
    userAssigned,
  } = caseInstance;

  const [searchValue, setSearchValue] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(userList ?? []);

  useEffect(() => {
    if (searchValue) {
      const filteredList = userList.filter((user) =>
        `${user?.given_name} ${user?.family_name}`
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
      );
      setFilteredUsers(filteredList);
    } else {
      setFilteredUsers(userList);
    }
  }, [searchValue, userList]);

  const filteredCaseStatus = getRowStatusText(caseStatus);

  const { mutateAsync: assignUserToCase } = useCaseAssignmentMutation();

  const onTableRowClick = () => {
    return setClickedCase(caseInstance);
  };

  const statusColor = statusColorMap[filteredCaseStatus];
  const statusText = caseStatusTextMap[filteredCaseStatus];

  const highlightText = (text, highlight) => {
    if (!highlight) {
      return text;
    }
    const parts = (text || '').split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: 'yellow' }}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  return (
    <TableRow key={caseID || 'new case'} onClick={() => onTableRowClick()} hover tabIndex={-1}>
      {!hideCaseNumbersFromUsers && (
        <TableCell align="left" key={`${ref_id ?? 'null'} 1`}>
          {ref_id || '-'}
        </TableCell>
      )}

      <TableCell
        key={`${caseID} 1`}
        component="th"
        scope="row"
        align="left"
        style={{
          paddingLeft: '1rem',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
        className="fs-exclude"
      >
        {highlightText(caseName, searchTerm)}
      </TableCell>

      <TableCell
        key={`${caseID} 2`}
        component="th"
        scope="row"
        align="left"
        style={{
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
        className="fs-exclude"
      >
        {fullName ? highlightText(fullName, searchTerm) : '-'}
      </TableCell>

      <TableCell
        key={`${caseID} 3`}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Select
          displayEmpty
          autoWidth
          sx={{
            width: '100%',
            boxShadow: 'none',
            '.MuiSelect-select': { paddingLeft: 0 },
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
          }}
          renderValue={() => {
            const currentUser = userList.find((user) => user.username === userAssigned);
            if (currentUser) {
              return `${currentUser.given_name} ${currentUser.family_name}`;
            }
            return 'Unassigned';
          }}
          size="small"
          IconComponent={KeyboardArrowDown}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiMenuItem-root.Mui-selected': {
                  backgroundColor: 'rgba(16, 36, 73, 0.2)',
                },
                '& .MuiMenuItem-root:hover': {
                  backgroundColor: 'rgba(16, 36, 73, 0.1)',
                },
                '& .MuiMenuItem-root.Mui-selected:hover': {
                  backgroundColor: 'rgba(16, 36, 73, 0.15)',
                },
              },
            },
          }}
        >
          <Box onKeyDown={(e) => e.stopPropagation()}>
            <InputWithIcon
              Icon={<SearchIcon />}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              inputProps={{
                placeholder: 'Search',
              }}
              containerProps={{
                style: {
                  margin: '0.5rem',
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                },
              }}
            />
            {userAssigned && (
              <Button
                sx={{
                  cursor: 'pointer',
                  color: 'primary.light',
                  fontSize: '0.85em',
                  fontFamily: 'Inter',
                  fontWeight: 600,
                  marginLeft: '10px',
                }}
                onClick={() => assignUserToCase({ caseID, userID: null })}
              >
                Unassign
              </Button>
            )}
            <Divider />
          </Box>
          {filteredUsers?.map((user) => (
            <MenuItem
              selected={user.username === userAssigned}
              key={user.username}
              onClick={() => assignUserToCase({ caseID, userID: user.username })}
            >
              {`${user?.given_name} ${user?.family_name}`}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell align="left" scope="string" sortDirection={false} className="" key={`${caseID} 4`}>
        {pageCount}
      </TableCell>
      <TableCell align="left" key={`${caseID} 5`}>
        {dayjs(dueDate).year() > 1970 ? formatFormalDate(dueDate) : '-'}
      </TableCell>
      <TableCell align="left" key={`${caseID} 6`} sx={{ color: statusColor }}>
        {statusText}
      </TableCell>

      <TableCell
        align="right"
        key={`${caseID} 8`}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <CaseSettings {...caseInstance} setEventAlert={setEventAlert} />
      </TableCell>
    </TableRow>
  );
}

export default CaseTableView;

const statusColorMap = {
  NEW: 'duplicatesByDocumentRed.main',
  PROCESSING: 'themeOrange.main',
  READY: 'status.success',
  CLOSED: 'modernGrey.main',
  ERROR: 'error.main',
};

const caseStatusTextMap = {
  NEW: 'Files Required',
  PROCESSING: 'Processing',
  READY: 'Open',
  CLOSED: 'Closed',
  ERROR: 'Error',
};
