import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { authenticatedRequest } from '../../../api';
import { getUser } from '../../Case/CaseDisplays/useCaseLocks';
import { useUser } from '../../../library/contexts/AuthContext';

async function checkProcessorGroupingLock({ fileID, caseID }: { fileID: string; caseID?: string }) {
  const res = await authenticatedRequest({
    method: 'GET',
    url: `/case/${caseID}/lock/grouping`,
    params: { file_id: fileID },
  });
  return res.data;
}

async function removeGroupingLock({ fileID, caseID }: { fileID: string; caseID?: string }) {
  const res = await authenticatedRequest({
    method: 'DELETE',
    url: `/case/${caseID}/lock/grouping`,
    params: { file_id: fileID },
  });
  return res.data;
}

export function useGroupingLock(caseID: string, fileID: string) {
  const { user } = useUser();

  useEffect(
    () => async () => {
      await removeGroupingLock({ fileID, caseID });
    },
    [],
  );

  const { data, isLoading, isError } = useQuery(
    ['checkProcessorGroupingLock', fileID],
    async (): Promise<{ access: boolean; currentUser: any }> => {
      const groupingLock = await checkProcessorGroupingLock({ fileID, caseID });
      return {
        access: groupingLock?.access,
        currentUser: !groupingLock?.access
          ? await getUser(user, caseID, groupingLock?.userInCase)
          : undefined,
      };
    },
    {
      refetchInterval: 60 * 1000, //1 minute
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
      enabled: !!caseID && !!fileID,
    },
  );

  const [refreshLoading, setRefreshLoading] = useState(false);
  const queryClient = useQueryClient();

  const refresh = async () => {
    setRefreshLoading(true);
    await queryClient.invalidateQueries(['checkProcessorGroupingLock', fileID]);
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  return {
    access: data?.access,
    currentUser: data?.currentUser
      ? `${data.currentUser.given_name} ${data.currentUser.family_name}`
      : '',
    accessLoading: isLoading,
    isError,
    refresh,
    refreshLoading,
  };
}
