import { useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  useUpdateEntityMutation,
  UpdateEntityMutationVariables,
} from '../../../__generated__/graphql';

export default function useChangeEntityMutation() {
  const [mutation, state] = useUpdateEntityMutation();
  const queryClient = useQueryClient();

  const wrappedMutation = useCallback(
    (params: UpdateEntityMutationVariables) => {
      try {
        return mutation({
          variables: {
            data: params.data,
          },
          update: () => {
            queryClient.invalidateQueries(['entities', params.data?.caseID, params.data?.entryID]);
          },
          onCompleted: (data) => {
            if (data) {
              toast.success(
                `${params?.data?.author ? 'Author' : 'Organization'} successfully updated`,
                params?.data?.origin === 'PROCESSOR'
                  ? { position: 'bottom-right', autoClose: 500 }
                  : undefined,
              );
            }
          },
        });
      } catch (error) {
        toast.error(
          'A problem occurred while updating entities. If issues persist, please try refreshing the page.',
        );
        Sentry.captureException(error);
        throw error;
      }
    },
    [mutation, queryClient],
  );
  return useMemo(() => [wrappedMutation, state], [wrappedMutation, state]);
}
