import React from 'react';
import { Tabs, Tab } from '@mui/material';

type DocumentTabsProps = {
  selectedTab: string;
  onChangeTab: (event: React.SyntheticEvent, newValue: string) => void;
  hasDocuments: boolean;
  totalTagging: number;
  totallQaRequired: number;
  totalApproved: number;
};

function DocumentTabs({
  selectedTab,
  onChangeTab,
  hasDocuments,
  totalTagging = 0,
  totallQaRequired = 0,
  totalApproved = 0,
}: DocumentTabsProps) {
  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={onChangeTab}
        variant="standard"
        indicatorColor="primary"
        textColor="primary"
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'primary.main',
          },
          '& .MuiTab-root': {
            textTransform: 'none',
            fontWeight: 'bold',
            textAlign: 'left',
            fontSize: '.7rem',
            color: 'dropdown.subgroupHeader',
            '&.Mui-selected': {
              color: 'primary.main',
            },
            '&:hover': {
              color: 'primary.light',
            },
            '&:focus': {
              outline: 'none',
            },
          },
        }}
      >
        <Tab
          sx={{ paddingLeft: '2px', paddingRight: '2px' }}
          label={`Tagging (${totalTagging})`}
          value="TAGGING"
        />
        <Tab
          sx={{ paddingLeft: '2px', paddingRight: '2px' }}
          label={`QA Required (${totallQaRequired})`}
          value="QA_REQUIRED"
        />
        <Tab
          sx={{ paddingLeft: '2px', paddingRight: '2px' }}
          label={`Approved (${totalApproved})`}
          value="APPROVED"
        />
      </Tabs>
      {!hasDocuments && (
        <div style={{ textAlign: 'center', marginTop: '1rem', color: 'gray' }}>
          No Documents Available
        </div>
      )}
    </>
  );
}

export default DocumentTabs;
