/* eslint-disable no-restricted-syntax */
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { shallow } from 'zustand/shallow';

import {
  buildGroupingsForCaseFile,
  bulkUpdateDocumentEdgesStatuses,
  DocumentEdge,
  getPagesByCaseFile,
  PageObject,
  resetDocumentEdgesForCaseFile,
} from '../../../api';
import { useAsync } from '../../../hooks/useAsync';
import { compareEdges, diffEdges, EdgeDiff, removeDismissed } from './documentParser';
import { DocumentNode, parsePages, EdgeError } from './DocumentTree';
import useDisplayStore from '../../Timeline/useDisplayStore';
import { retryRequest } from '../../../utils/asyncUtils';

export function useGroupings(caseId: string, fileId: string) {
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'saving' | 'saved' | 'errored' | 'staged'
  >('idle');
  const [pagesResult, refreshPages] = useAsync(() => fetchPages(caseId, fileId), [caseId, fileId]);
  const [pages, setPages] = useState<PageObject[]>([]);
  const [filteredPages, setFilteredPages] = useState<PageObject[]>([]);
  const [pagesById, setPagesById] = useState(new Map<PageObject['id'], PageObject>());
  const [edgeById, setEdgesById] = useState(new Map<DocumentEdge['id'], DocumentEdge>());
  const [groupIDByPageID, setGroupIDByPageID] = useState<Record<string, number>>({});
  const [attachmentIDByPageID, setAttachmentIDByPageID] = useState<Record<string, number>>({});
  const [pageIndexById, setPageIndexById] = useState(new Map<PageObject['id'], number>());
  const [documentTree, setDocumentTree] = useState<DocumentNode | null>(null);
  const [parseError, setParseError] = useState<EdgeError | null>(null);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (pagesResult.status === 'pending') {
      setStatus('loading');
    } else if (pagesResult.status === 'rejected') {
      setStatus('errored');
    } else if (pagesResult.status === 'resolved') {
      const newPagesById = new Map<PageObject['id'], PageObject>();
      const newEdgesById = new Map<DocumentEdge['id'], DocumentEdge>();
      const newPageIndexById = new Map<PageObject['id'], number>();
      const pagesInGrouping = pagesResult.data.filter(
        (page) => page.document_status === 'GROUPING' || page.document_status === 'PENDING',
      );
      setPages(pagesInGrouping);

      for (const [index, page] of pagesInGrouping.entries()) {
        newPagesById.set(page.id, page);
        newPageIndexById.set(page.id, index);

        page.document_edges.sort(compareEdges);

        for (const edge of page.document_edges) {
          newEdgesById.set(edge.id, edge);
        }
      }

      setPagesById(newPagesById);
      setEdgesById(newEdgesById);
      setPageIndexById(newPageIndexById);
      const parsed = parsePages(pagesInGrouping);
      if (parsed.type === 'error') {
        setDocumentTree(null);
        setParseError(parsed.error);
        setStatus('errored');
      } else {
        setDocumentTree(parsed.value);
        setParseError(null);
        setStatus('idle');
      }
    }
  }, [pagesResult]);

  useEffect(() => {
    // todo - we can use the document tree to do this much more eloquently
    function getGroupAndAttachmentIdsForPages(pages: PageObject[]) {
      // document scroller breaks without number ids for pages, so we need to keep this one

      const topEdgeCountByPageID: Record<string, number> = {};
      const groupIDByPageID: Record<string, number> = {};
      const attachmentIDByPageID: Record<string, number> = {};

      let currentDepth = 0;
      let currentGroupID = 1;
      let currentAttachmentID = 1;

      pages.forEach((page, index) => {
        page.document_edges = page.document_edges.filter(removeDismissed).sort(compareEdges);

        let topEdgesForPage = 0;

        page.document_edges.forEach((edge) => {
          if (edge.type === 'Start') {
            currentDepth += 1;
            topEdgesForPage += 1;
          }
        });
        topEdgeCountByPageID[page.id] = topEdgesForPage;
        // current depth should be taken before the end edges of page are processed
        if (index !== 0 && currentDepth > 0 && currentDepth === topEdgeCountByPageID[page.id]) {
          currentGroupID += 1;
        }

        groupIDByPageID[page.id] = currentGroupID;
        if (currentDepth === 2 && topEdgeCountByPageID[page.id] >= 1) {
          currentAttachmentID += 1;
        }
        attachmentIDByPageID[page.id] = currentAttachmentID;

        page.document_edges.forEach((edge) => {
          if (edge.type === 'End') {
            if (currentDepth > 0) {
              currentDepth -= 1;
            }
          }
        });
      });
      return {
        groupIDByPageID,
        attachmentIDByPageID,
      };
    }

    const pagesRemovedDismissedEdges = [];
    for (const page of pages) {
      const edges = page.document_edges
        .filter((edge) => edge.status !== 'Dismissed')
        .sort(compareEdges);

      pagesRemovedDismissedEdges.push({ ...page, document_edges: edges });
    }
    setFilteredPages(pagesRemovedDismissedEdges);
    const { groupIDByPageID: newGroupIDByPageID, attachmentIDByPageID: newAttachmentIDByPageID } =
      getGroupAndAttachmentIdsForPages(pagesRemovedDismissedEdges);
    setGroupIDByPageID(newGroupIDByPageID);
    setAttachmentIDByPageID(newAttachmentIDByPageID);
  }, [pages]);

  const commitEdges = useCallback(
    async (staged: DocumentNode) => {
      setStatus('staged');
      // todo: update edges in the backend and update local state with response
      const edgeDiffs = diffEdges([...documentTree!.allEdges()], [...staged.allEdges()]);
      try {
        const response = await retryRequest(() => updateEdges(caseId, fileId, edgeDiffs), 1);
        if (response.status !== 'resolved') {
          toast.error('There was an error while updating the document edges. Please try again.');
          setStatus('idle');
          return;
        }

        const updatedEdges = response.data;
        if (updatedEdges == null) {
          toast.error('There was an error while updating the document edges. Please try again.');
          setStatus('idle');
          return;
        }

        // we need to iterate through the updated edges, and for each edge
        // ensure the record is updated in pages
        const updatedPages = [...pages];
        for (const edge of updatedEdges) {
          const pageIndex = pageIndexById.get(edge.page_id);
          const currentPage = updatedPages[pageIndex!];
          const currentEdges = currentPage.document_edges;

          const existingEdgeIndex = currentEdges.findIndex((e) => e.id === edge.id);
          if (existingEdgeIndex !== -1) {
            currentEdges[existingEdgeIndex] = edge;
          } else {
            currentEdges.push(edge);
            currentEdges.sort(compareEdges);
          }
        }

        setPages(updatedPages);
        setDocumentTree(staged);
        setStatus('saved');
      } catch (error) {
        toast.error('There was an error while updating the document edges. Please try again.');
        setStatus('idle');
      }
    },
    [documentTree, pages, pageIndexById],
  );

  const canSplitAt = useCallback(
    (pageId: PageObject['id']) => {
      if (status !== 'idle' && status !== 'saved') {
        return false;
      }
      if (!documentTree) {
        return false;
      }
      return documentTree.canSplitAt(pageId);
    },
    [documentTree, status],
  );

  const splitAt = useCallback(
    (pageId: PageObject['id']) => {
      if (!canSplitAt(pageId)) {
        return;
      }

      const staged = documentTree!.clone() as DocumentNode;
      if (staged.splitAt(pageId)) {
        commitEdges(staged);
      }
    },
    [documentTree, canSplitAt, commitEdges],
  );

  const canMergeAt = useCallback(
    (pageId: PageObject['id']) => {
      if (status !== 'idle' && status !== 'saved') {
        return false;
      }
      if (!documentTree) {
        return false;
      }
      return documentTree.canMergeAt(pageId);
    },
    [documentTree, status],
  );

  const mergeAt = useCallback(
    (pageId: PageObject['id']) => {
      if (!canMergeAt(pageId)) {
        return;
      }

      const staged = documentTree!.clone() as DocumentNode;
      if (staged.mergeAt(pageId)) {
        commitEdges(staged);
      }
    },
    [documentTree, canMergeAt, commitEdges],
  );

  const canAttachAt = useCallback(
    (pageId: PageObject['id']) => {
      if (status !== 'idle' && status !== 'saved') {
        return false;
      }
      if (!documentTree) {
        return false;
      }
      return documentTree.canAttachAt(pageId);
    },
    [documentTree, status],
  );

  const attachAt = useCallback(
    (pageId: PageObject['id']) => {
      if (!canAttachAt(pageId)) {
        return;
      }

      const staged = documentTree!.clone() as DocumentNode;
      if (staged.startAttachmentAt(pageId)) {
        commitEdges(staged);
      }
    },
    [documentTree, canAttachAt, commitEdges],
  );

  const canDetachAt = useCallback(
    (pageId: PageObject['id']) => {
      if (status !== 'idle' && status !== 'saved') {
        return false;
      }
      if (!documentTree) {
        return false;
      }
      return documentTree.canDetachAt(pageId);
    },
    [documentTree, status],
  );

  const detachAt = useCallback(
    (pageId: PageObject['id']) => {
      if (!canDetachAt(pageId)) {
        return;
      }

      const staged = documentTree!.clone() as DocumentNode;
      if (staged.endAttachmentAt(pageId)) {
        commitEdges(staged);
      }
    },
    [documentTree, canDetachAt, commitEdges],
  );

  const depthAt = useCallback(
    (pageId: PageObject['id']) => {
      if (!documentTree) {
        return 0;
      }
      // this isn't working right. Returns 3 when depth should be 1
      const calcDepth = documentTree.depthAt(pageId);
      return calcDepth;
    },

    [documentTree],
  );

  const canBuildGroupings = useCallback(
    (pageId?: string) => {
      if (pageId == null || groupIDByPageID == null || pages?.length === 0) {
        return false;
      }

      const groupID = groupIDByPageID[pageId];
      if (groupID > 1) {
        return true;
      }
      return pageId === pages[pages.length - 1].id;
    },
    [groupIDByPageID, pages],
  );

  const { setLastGroupingScrollPosition } = useDisplayStore(
    (state) => ({
      setLastGroupingScrollPosition: state.setLastGroupingScrollPosition,
    }),
    shallow,
  );

  const buildGroupings = useCallback(
    async (startPage: number, endPage: number) => {
      if (caseId == null || fileId == null) {
        return { status: 'rejected' };
      }
      setStatus('saving');
      try {
        const response = await retryRequest(
          () => buildGroupingsForCaseFile(caseId, fileId, startPage, endPage),
          1,
        );
        if (response.status === 'resolved') {
          queryClient.invalidateQueries(['files', caseId]);

          toast.success('Groupings built successfully');
          setStatus('saved');
          setLastGroupingScrollPosition({ documentID: fileId, position: 1 });
          refreshPages();
          return { status: 'resolved' };
        }

        toast.error('Failed to build groupings');
        setStatus('idle');
        return { status: 'rejected' };
      } catch (error) {
        toast.error('Failed to build groupings');
        setStatus('idle');
        return { status: 'rejected' };
      }
    },
    [caseId, fileId],
  );

  const resetEdges = useCallback(
    async (created: 'QA' | 'Processor', start_page: number, end_page: number) => {
      if (caseId == null || fileId == null) {
        return;
      }
      setStatus('saving');
      const response = await resetEdgesCall(caseId, fileId, created, start_page, end_page);
      if (response.status === 'rejected') {
        toast.error('Failed to reset edges');
        setStatus('idle');
        return;
      }
      await refreshPages();
      setStatus('saved');
      toast.success('All edges were reset for file.');
    },
    [caseId, fileId, refreshPages],
  );

  return {
    status,
    pages,
    filteredPages,
    refreshPages,
    parseError,
    canSplitAt,
    splitAt,
    canMergeAt,
    mergeAt,
    canAttachAt,
    attachAt,
    canDetachAt,
    detachAt,
    depthAt,
    buildGroupings,
    resetEdges,
    pageIndexById,
    groupIDByPageID,
    attachmentIDByPageID,
    canBuildGroupings,
  };
}

const fetchPages = async (caseId: string, fileId: string): Promise<PageObject[]> => {
  const response = await retryRequest<PageObject[]>(getPagesByCaseFile, 1, caseId, fileId);
  if (response.status === 'resolved') {
    return response.data!;
  }
  throw new Error('Failed to fetch pages');
};

const updateEdges = async (
  caseId: string,
  fileId: string,
  edgeDiffs: EdgeDiff,
): Promise<{ status: 'resolved' | 'rejected'; data?: DocumentEdge[] }> => {
  return retryRequest<DocumentEdge[]>(
    bulkUpdateDocumentEdgesStatuses,
    1,
    caseId,
    fileId,
    edgeDiffs,
  );
};

const resetEdgesCall = async (
  caseId: string,
  fileId: string,
  created: 'QA' | 'Processor',
  start_page: number,
  end_page: number,
): Promise<{ status: 'resolved' | 'rejected'; data?: DocumentEdge[] }> => {
  return retryRequest<DocumentEdge[]>(resetDocumentEdgesForCaseFile, 1, caseId, fileId, {
    created,
    start_page,
    end_page,
  });
};
