import React, { useCallback, useEffect, useState, createContext, useContext } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { TimelineEntryObject } from '../../../__generated__/graphql';

function useCachedTimelineEntryState(entryID: number) {
  const client = useApolloClient();
  const [timelineEntry, setTimelineEntry] = useState<TimelineEntryObject | null>(null);

  useEffect(() => {
    setTimelineEntry(fetchTimelineEntry(entryID));
  }, [entryID]);

  const fetchTimelineEntry = (entryID: number) =>
    client.readFragment({
      id: client.cache.identify({
        id: entryID,
        __typename: 'TimelineEntryObject',
      }),
      fragment: gql`
        fragment TimelineEntryWithTaggedPages on TimelineEntryObject {
          id
          document_tags {
            tag_id
            name
            category
          }
          pages {
            id
            tags {
              id
              name
              type
              parent_tag_id
              origin
            }
          }
        }
      `,
    });

  const refreshTimelineEntry = useCallback(() => {
    setTimelineEntry(fetchTimelineEntry(entryID));
  }, [entryID]);

  return { timelineEntry, refreshTimelineEntry };
}

export type CachedTimelineEntryState = ReturnType<typeof useCachedTimelineEntryState>;

const CachedTimelineEntryContext = createContext<CachedTimelineEntryState>({} as any);

export type CachedTimelineEntryProps = {
  entryID: number;
};

export function CachedTimelineEntryWrapper({
  entryID,
  children,
}: React.PropsWithChildren<CachedTimelineEntryProps>) {
  const state = useCachedTimelineEntryState(entryID);

  return (
    <CachedTimelineEntryContext.Provider value={state}>
      {children}
    </CachedTimelineEntryContext.Provider>
  );
}

export function useCachedTimelineEntry() {
  return useContext(CachedTimelineEntryContext);
}
