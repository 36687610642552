import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './anchored-dropdown-menu.css';
import { Switch, Divider } from '@mui/material';
import Theme from '../../../../theme';

type MenuPosition = {
  top: number;
  left: number;
};

type Props = {
  anchorComponentRef: React.RefObject<HTMLElement>;
  options: {
    label: string;
    onClick: () => void;
    checked: boolean;
  }[];
  header?: string;
};

export default function SwitchDropdownMenu({ anchorComponentRef, options, header }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState<MenuPosition | null>(null);
  const dropdownRef = useRef(null);
  const dropdownWidth = 260;

  useEffect(() => {
    const anchor = anchorComponentRef.current;
    const toggleDropdown = () => {
      if (!isOpen) {
        const rect = anchor.getBoundingClientRect();
        setMenuPosition({
          top: rect.top + rect.height + window.scrollY,
          left: rect.left + rect.width + window.scrollX - dropdownWidth,
        });
      }
      setIsOpen((prev) => !prev);
    };

    if (anchor) {
      anchor.addEventListener('click', toggleDropdown);
    }

    return () => {
      if (anchor) {
        anchor.removeEventListener('click', toggleDropdown);
      }
    };
  }, [isOpen, anchorComponentRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        anchorComponentRef.current &&
        !anchorComponentRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (!anchorComponentRef.current) {
        return;
      }
      const rect = anchorComponentRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.top + rect.height + window.scrollY,
        left: rect.left + rect.width + window.scrollX - dropdownWidth,
      });
    };

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [anchorComponentRef, isOpen]);

  const dropdownMenu = menuPosition && (
    <div
      className="sm-dropdown-menu-container"
      style={{
        borderRadius: '8px',
        padding: '5px',
        transform: `translate3d(${menuPosition.left}px, ${menuPosition.top}px, 0)`,
        width: `${dropdownWidth}px`,
      }}
      role="menu"
      ref={dropdownRef}
    >
      <div style={{ fontWeight: 600, fontSize: '16px', padding: '0.75rem' }}>Configure</div>

      <Divider />
      {options.map((option) => (
        <div>
          <Switch
            sx={{
              '& .MuiSwitch-switchBase': {
                transform: 'translateX(5px)',
                '&.Mui-checked': {
                  transform: 'translateX(20px)',
                },
              },
              '& .MuiSwitch-thumb': {
                height: 16,
                width: 15,
                marginTop: '4.5px',
                color: 'white',
              },
              '& .MuiSwitch-track': {
                borderRadius: 10,
                backgroundColor: !option.checked ? 'lightgray' : '',
                width: 40,
                height: 20,
                padding: 0,
                opacity: 1,
              },
              '& .Mui-checked + .MuiSwitch-track': {
                backgroundColor: Theme.palette.primary.light,
                opacity: 1,
              },
              '&$checked + $track': {
                backgroundColor: Theme.palette.primary.light,
              },
            }}
            checked={option.checked}
            onClick={() => option.onClick()}
          />
          <span style={{ fontWeight: 500, fontSize: '14px' }}>{option.label}</span>
        </div>
      ))}
    </div>
  );

  if (isOpen && dropdownMenu) {
    return <>{ReactDOM.createPortal(dropdownMenu, document.body)}</>;
  }

  return null;
}
