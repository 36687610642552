import { useState } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { Box, Button, Typography, Checkbox, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import DialogModal from '../../components/common/DialogModal';
import apiUrl from '../../library/utilities/apiUrl';

type File = {
  authorStatus: string;
  fileStatus: string;
  id: string;
  name: string;
  numOfPages: number;
  refID: number;
  type: string;
  uploadDate: string;
  sequence: number;
};

type FilesDialogProps = {
  onClose: () => {};
  files: File[];
  setCopyingFile: (setCopyingFile: { sourceFile: string; targetFile: string }) => void;
  sourceDocument: File;
  caseID: string;
  userID: string;
};

function CopyFilesDialog({
  onClose,
  files,
  setCopyingFile,
  sourceDocument,
  caseID,
  userID,
}: FilesDialogProps) {
  const [targetDocument, setTargetDocument] = useState<File | null>(null);
  const sortedFiles = files
    ?.filter((file: File) => file.id !== sourceDocument?.id)
    .filter((file: File) => file.numOfPages === sourceDocument?.numOfPages);

  const handleCancel = () => {
    setTargetDocument(null);
    onClose();
  };

  const handleSelectTarget = (target: File) => {
    if (targetDocument?.id === target.id) {
      setTargetDocument(null);
    } else {
      setTargetDocument(target);
    }
  };
  const columns = [
    {
      field: 'checkbox',
      headerName: '',
      flex: 0.5,
      headerClassName: 'files-table-header-row',
      sortable: false,
      renderCell: (params: any) => (
        <Checkbox
          checked={targetDocument?.id === params.row.id}
          onChange={() => handleSelectTarget(params.row)}
        />
      ),
    },
    {
      field: 'sequence',
      headerName: '#',
      flex: 0.2,
      headerClassName: 'files-table-header-row',
      sortable: false,
    },

    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      headerClassName: 'files-table-header-row',
      sortable: false,
    },
    {
      field: 'numOfPages',
      headerName: 'Pages',
      flex: 0.75,
      headerClassName: 'files-table-header-row',
      sortable: false,
    },
  ];

  async function copyTimelineEntryDetails(sourceFile: string, targetFile: string) {
    try {
      const result = await axios.post(`${apiUrl}copyEntryDetails`, {
        caseID: caseID,
        source_file_id: sourceFile,
        target_file_id: targetFile,
        userID: userID,
      });
      if (result.status === 200) {
        setCopyingFile({ sourceFile, targetFile });
        handleCancel();
      }
    } catch (error) {
      Sentry.captureException(error);
      toast.error('Failed to copy file');
    }
  }
  const content = (
    <Box>
      <Typography sx={{ fontSize: '15px' }}>
        {`Copying from ${
          sourceDocument?.name ?? ''
        }. Please select the file you would like to change:`}
      </Typography>

      <Box sx={{ maxHeight: '250px', overflow: 'scroll', padding: '12px' }}>
        <DataGrid
          rows={sortedFiles}
          columns={columns}
          autoHeight
          disableColumnMenu
          hideFooter
          onRowClick={(params) => setTargetDocument(params.row)}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: ' none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: ' none',
            },
          }}
        />
      </Box>

      <Box sx={{ padding: '10px', width: '100%', textAlign: 'center' }}>
        <Typography sx={{ paddingBottom: '2px' }}>
          {targetDocument ? `Selected File: ${targetDocument?.name ?? ''}` : 'No File Selected'}
        </Typography>
        <Button
          variant="contained"
          onClick={handleCancel}
          sx={{
            fontWeight: 700,
            fontSize: '0.8rem',
            marginLeft: 'auto',
            marginRight: '1rem',
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (sourceDocument && targetDocument) {
              copyTimelineEntryDetails(sourceDocument.id, targetDocument.id);
            }
          }}
          disabled={!sourceDocument || !targetDocument}
          sx={{
            fontWeight: 700,
            fontSize: '0.8rem',
            marginLeft: 'auto',
          }}
        >
          Copy
        </Button>
      </Box>
    </Box>
  );

  return (
    <DialogModal
      open={sourceDocument !== null}
      header={<Typography>Copy Files</Typography>}
      onClose={handleCancel}
      content={content}
    />
  );
}
export default CopyFilesDialog;
