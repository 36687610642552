import { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  TextField,
  Typography,
  Button,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { Close, KeyboardArrowDown } from '@mui/icons-material';
import Draggable from 'react-draggable';
import { shallow } from 'zustand/shallow';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import useTimelineStore from '../useTimelineStore';
import useSources from '../gql/useSources';
import { useUserGroup } from '../../MyCases/useCases';
import EntitySelect from '../EntitySelect';
import { getQueryParams } from '../LinkWithQuery';
import useEntities from '../gql/useEntities';

export default function MergeSplitTimelineMenu(props) {
  const { mergeSplitMenuVisible, setMergeSplitMenuVisible, caseID, mergeTimeline } = props;

  const sourceTypes = useSources();
  const { data: userGroup } = useUserGroup();
  const processorOrLabeller = userGroup === 'Labeller' || userGroup === 'Processors';

  const [searchParams] = useSearchParams();
  const searchParamsObj = Object.fromEntries(searchParams.entries());
  const { timelineID, pageID, entryID } = useParams();
  const currentTimelineID = searchParams.get('timelineID') || timelineID;
  const navigate = useNavigate();
  const {
    clearSelection,
    splitInProgress,
    selectedPages,
    setShowCheckboxes,
    selectedDate,
    currentSource,
    author,
    organization,
    subject,
    setSource,
    setAuthor,
    setOrganization,
    setSubject,
    setDate,
  } = useTimelineStore(
    (state) => ({
      clearSelection: state.clearSelection,
      splitInProgress: state.splitInProgress,
      selectedPages: state.selectedPages(),
      setShowCheckboxes: state.setShowCheckboxes,
      selectedDate: state.entryDate,
      currentSource: state.source,
      author: state.author,
      organization: state.organization,
      subject: state.subject,
      setSource: state.setSource,
      setAuthor: state.setAuthor,
      setOrganization: state.setOrganization,
      setSubject: state.setSubject,
      setDate: state.setDate,
    }),
    shallow,
  );
  const selectedPagesGroupedByTimelineEntry = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const page of Object.values(selectedPages)) {
    if (!selectedPagesGroupedByTimelineEntry[`${page.entryID}-${page.documentID}`]) {
      selectedPagesGroupedByTimelineEntry[`${page.entryID}-${page.documentID}`] = {
        date: page.timelineEntryDate,
        documentFileName: page.documentFileName,
        pageNumbers: [],
      };
    }
    selectedPagesGroupedByTimelineEntry[`${page.entryID}-${page.documentID}`].pageNumbers.push(
      page.pageNumber,
    );
  }
  const requestObj = {
    timelineID: currentTimelineID,
    entryID: null,
    caseID: caseID,
    pages: Object.keys(selectedPages).map((selectedPageID) => +selectedPageID),
    sourceID: currentSource,
    entryDate: selectedDate || '1900-01-01',
    author,
    organization,
    subject,
    current_page_id: pageID,
  };

  const handleClearAndClose = () => {
    setShowCheckboxes(false);
    clearSelection();
    setSource('');
    setDate('');
    setMergeSplitMenuVisible(false);
  };

  // eslint-disable-next-line consistent-return
  const handleTimelineEntryChanges = async () => {
    const nameChanged = author?.name || organization?.name || subject?.name;
    if (!pageID && processorOrLabeller && (splitInProgress || nameChanged)) {
      return toast.error(
        'The page containing the new values must be opened in order to perform this operation.',
        {
          toastId: 'processorAttemptedMergeSplitWithoutPage',
        },
      );
    }
    handleClearAndClose();
    const response = await mergeTimeline({ data: requestObj });
    const newEntry = response?.data?.mergeSplitTimelineEntries?.newEntry;
    if (newEntry && newEntry.pages?.find((page) => page.id === +pageID)) {
      const searchParamString = getQueryParams(searchParamsObj);
      navigate(`../timeline/${timelineID}/${newEntry.id}/${pageID}${searchParamString}`);
    }
  };

  const getInfo = () => {
    if (splitInProgress) {
      return {
        title: 'Split Timeline Entry',
        description:
          'Please select a source type and content label as well as a date for your new entry. Unselected entries will remain in their current entry',
        selected: `Selected ${Object.keys(selectedPages).length > 1 ? 'Pages' : 'Page'}`,
        button: 'Split',
      };
    }
    return {
      title: 'Merge Timeline Entries',
      description: 'Please select a source type and content for your new entry.',
      selected: 'Selected Timeline Entries',
      button: 'Merge',
    };
  };

  const modalInfo = getInfo();

  useEffect(() => {
    //If merging, use the details from the parent timeline entry
    if (!splitInProgress) {
      if (selectedDate) {
        setDate(selectedDate.split(' ')[0]);
      }
    } else if (splitInProgress) {
      //Get source and date from selected pages
      if (Object.values(selectedPages).length > 0) {
        const pages = Object.values(selectedPages);
        const sourcesCommon = [];
        const pageDates = [];

        pages.forEach((page) => {
          sourcesCommon.push(page.sourceID);
          if (page.pageDate !== '0000-00-00') {
            pageDates.push(page.pageDate);
          }
        });

        const sourceCounts = sourcesCommon.reduce((counts, source) => {
          // eslint-disable-next-line no-param-reassign
          counts[source] = (counts[source] || 0) + 1;
          return counts;
        }, {});

        const pageDateCounts = pageDates.reduce((counts, pageDate) => {
          // eslint-disable-next-line no-param-reassign
          counts[pageDate] = (counts[pageDate] || 0) + 1;
          return counts;
        }, {});

        const mostCommonSource = Object.keys(sourceCounts).reduce((a, b) =>
          sourceCounts[a] > sourceCounts[b] ? a : b,
        );
        setSource(mostCommonSource);

        let mostCommonPageDate;
        if (Object.keys(pageDateCounts).length > 1) {
          Object.keys(pageDateCounts).reduce((a, b) =>
            pageDateCounts[a] > pageDateCounts[b] ? a : b,
          );
        } else if (Object.keys(pageDateCounts).length === 1) {
          [mostCommonPageDate] = Object.keys(pageDateCounts);
        } else {
          mostCommonPageDate = '';
        }

        if (mostCommonPageDate === '') {
          setDate('');
        }

        if (
          mostCommonPageDate !== 'undefined' &&
          mostCommonPageDate !== 'null' &&
          mostCommonPageDate
        ) {
          const formattedDate =
            mostCommonPageDate.length > 10 ? mostCommonPageDate.split(' ')[0] : mostCommonPageDate;
          setDate(`${formattedDate}`);
        }
      }
    }
  }, [Object.values(selectedPages).length]);

  const {
    sortedOrgsForCase,
    sortedOrgsForEntry,
    allOrgs,
    sortedAuthorsForCase,
    sortedAuthorsForEntry,
    allAuthors,
  } = useEntities(caseID, entryID);

  return (
    <Draggable>
      <Dialog
        open={mergeSplitMenuVisible}
        hideBackdrop={true}
        onClose={() => setMergeSplitMenuVisible(false)}
        PaperProps={{
          sx: {
            width: '37%',
            minWidth: 500,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <DialogTitle
          fontSize="1rem"
          sx={{
            paddingBottom: 0,
            marginTop: '2%',
            fontWeight: 600,
          }}
        >
          {modalInfo.title}
        </DialogTitle>
        <Typography
          sx={{
            fontSize: '0.8rem',
            marginLeft: '1.5rem',
            color: '#475467',
            paddingBottom: '1rem',
          }}
        >
          {' '}
          {modalInfo.description}
        </Typography>
        <Divider />
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 0' }}>
              <FormHelperText id="source-label" sx={labelStyles}>
                Source Type
              </FormHelperText>
              <FormHelperText id="date-label" sx={labelStyles}>
                Date
              </FormHelperText>
              {processorOrLabeller && (
                <>
                  <FormHelperText id="author-label" sx={labelStyles}>
                    Author
                  </FormHelperText>
                  <FormHelperText id="org-label" sx={labelStyles}>
                    Organization
                  </FormHelperText>
                  <FormHelperText id="subject-label" sx={labelStyles}>
                    Subject
                  </FormHelperText>
                </>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '2 1 0' }}>
              <FormControl
                sx={{
                  width: '100%',
                  mt: '7%',
                  mb: '1.2rem',
                }}
              >
                <Select
                  value={currentSource}
                  className="unmask"
                  displayEmpty
                  IconComponent={KeyboardArrowDown}
                  labelId="source-label"
                  onChange={(e) => setSource(e.target.value)}
                >
                  {sourceTypes
                    .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
                    .filter((source) => !source.deprecated)
                    .map((source) => (
                      <MenuItem key={source.tag_id} value={source.tag_id}>
                        {source.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '100%', mb: '1.2rem' }}>
                <TextField
                  className="unmask"
                  type="date"
                  size="small"
                  value={
                    selectedDate?.split(' ')[0] !== '1900-01-01' ? selectedDate?.split(' ')[0] : ''
                  }
                  sx={{ width: '50%' }}
                  inputProps={{
                    min: '1000-01-01',
                    max: '2099-12-31',
                    role: 'textbox',
                  }}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
              </FormControl>
              {processorOrLabeller && (
                <>
                  <EntitySelect
                    entityName="author"
                    valueID={author.id}
                    optionsForCase={sortedAuthorsForCase}
                    optionsForEntry={sortedAuthorsForEntry}
                    allOptions={allAuthors}
                    setValues={(e) => setAuthor({ id: e.id, name: e.name })}
                  />
                  <EntitySelect
                    entityName="organization"
                    valueID={organization.id}
                    optionsForCase={sortedOrgsForCase}
                    optionsForEntry={sortedOrgsForEntry}
                    allOptions={allOrgs}
                    setValues={(e) => setOrganization({ id: e.id, name: e.name })}
                  />
                  <EntitySelect
                    entityName="subject"
                    valueID={subject.id}
                    optionsForCase={sortedAuthorsForCase}
                    optionsForEntry={sortedAuthorsForEntry}
                    allOptions={allAuthors}
                    setValues={(e) => setSubject({ id: e.id, name: e.name })}
                  />
                </>
              )}
            </Box>
          </Box>
          <Divider sx={{ mt: '2rem' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="outlined"
              classes="primary"
              onClick={() => {
                setMergeSplitMenuVisible(false);
              }}
              sx={buttonStyles}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              classes="primary"
              onClick={() => {
                handleTimelineEntryChanges();
              }}
              disabled={currentSource === null}
              sx={buttonStyles}
            >
              {modalInfo.button}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <IconButton
            aria-label="close"
            style={{ position: 'absolute', right: 8, top: 8 }}
            onClick={() => {
              setMergeSplitMenuVisible(false);
            }}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    </Draggable>
  );
}

const labelStyles = {
  fontSize: '0.8rem',
  fontWeight: 500,
  marginLeft: 0,
  mt: '2.1rem',
};

const buttonStyles = {
  fontWeight: 600,
  mt: '4%',
  width: '40%',
  mx: 1,
};
