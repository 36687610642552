/* eslint-disable no-nested-ternary */
import {
  CircularProgress,
  Container,
  Typography,
  Button,
  Box,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { ControlPoint, Refresh, FilterList } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import CreateCase from './CreateCase';
import Announcement from '../../components/common/Announcement';
import CaseTableView from './CaseTableView';
import FileProcessorCaseTable from '../FileProcessors/CaseTable';
import { AuthContext } from '../../library/contexts/AuthContext';
import PageHeader from '../../components/common/PageHeader';
import { useGetCasesQuery, GetCasesDocument } from '../../__generated__/graphql';
import CaseFilterPopover from './CaseFilterPopover';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import AnnouncementSafari from '../../components/common/Alert';
import NewSearchBar from '../../components/Searchbar/NewSearchBar';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';
import useFeatureFlags from '../../config/useFeatureFlags';
import { useCaseAssignment } from './useCaseAssignment';

const myCasesStyles = {
  title: {
    fontSize: '1.875rem',
    display: 'inline-block',
    fontWeight: 500,
  },
  subtitle: {
    fontSize: '1rem',
  },
  container: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  casesContainer: {
    marginTop: '2rem',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  displayContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'normal',
    width: '100%',
  },
};

function MyCases() {
  const { givenName } = useContext(AuthContext);
  const isFileProcessor = useIsFileProcessor();
  const logUserActivity = useActivityLog();

  useEffect(() => {
    logUserActivity({
      activity: 'cases',
    });
  }, []);

  if (isFileProcessor == null) {
    return <div />;
  }

  return (
    <Container maxWidth="lg" sx={myCasesStyles.container}>
      <PageHeader title={`Welcome Back, ${givenName}!`} />
      {isFileProcessor ? <FileProcessorCaseTable /> : <ClientCases />}
    </Container>
  );
}

/**
 * My Cases page.
 */
function ClientCases() {
  const client = useApolloClient();
  const anchorRef = React.useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isNotChromeOrEdge =
    !/Chrome/.test(navigator.userAgent) && !/Edge/.test(navigator.userAgent);

  const currentAnnouncement = {
    v: 'announcement-v.1.8',
    title: 'Just a quick heads up!',
    body: `Our default sorting order has been changed to date ascending (earliest to latest). You will see this change across SiftMed any place we have the filter button.
    If there are any issues please contact: support@siftmed.ca `,
  };

  const isNotChromeOrEdgeAnnouncement = {
    v: 'safari-announcement-v.1.0',
    title: 'Attention Users!',
    body: `SiftMed is not optimized for your browser. If you experience any issues please try using one of our supported browsers: Google Chrome or Microsoft Edge.`,
  };

  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if (!cookies[currentAnnouncement?.v] && cookies[currentAnnouncement?.v] !== 'dismissed') {
      setCookie(currentAnnouncement?.v, 'show', { path: '/' });
    }
  }, []);

  useEffect(() => {
    if (
      !cookies[isNotChromeOrEdgeAnnouncement?.v] &&
      cookies[isNotChromeOrEdgeAnnouncement?.v] !== 'dismissed'
    ) {
      setCookie(isNotChromeOrEdgeAnnouncement?.v, 'show', { path: '/' });
    }
  }, []);

  const onCloseAnnouncement = () => {
    setCookie(currentAnnouncement?.v, 'dismissed', { path: '/' });
  };

  const onCloseSaAnnouncement = () => {
    setCookie(isNotChromeOrEdgeAnnouncement?.v, 'dismissed', { path: '/' });
  };

  const { data: queryCaseList, loading, error } = useGetCasesQuery();
  const { isLoading: featureFlagsLoading } = useFeatureFlags();
  const unfilteredCaseList = queryCaseList?.cases;

  const [showAll, setShowAll] = useState(true);
  const [dueDateFilter, setDueDateFilter] = useState(null);
  const [showOpen, setShowOpen] = useState(true);
  const [showFilesRequired, setShowFilesRequired] = useState(true);
  const [showBeingProcessed, setShowBeingProcessed] = useState(true);
  const [showClosed, setShowClosed] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function caseFilter(caseToBeFiltered) {
    const caseName = caseToBeFiltered.caseName || '';
    const patientName = caseToBeFiltered.fullName || '';
    const matchesSearchTerm =
      caseName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patientName.toLowerCase().includes(searchTerm.toLowerCase());

    return (
      (!showAll
        ? !(caseToBeFiltered.caseStatus === 'NEW' && !showFilesRequired) &&
          !(
            (caseToBeFiltered.caseStatus === 'PROCESSING' ||
              caseToBeFiltered.caseStatus === 'REQUIRES_PROCESSOR') &&
            !showBeingProcessed
          ) &&
          !(
            caseToBeFiltered.caseStatus === 'READY' &&
            !showOpen &&
            (caseToBeFiltered.pagesViewed < 1 || caseToBeFiltered.pagesViewed > 0)
          ) &&
          !(caseToBeFiltered.caseStatus === 'CLOSED' && !showClosed)
        : true) &&
      (dueDateFilter
        ? moment(caseToBeFiltered.dueDate).format('YYYY-MM-DD') <= dueDateFilter
        : true) &&
      matchesSearchTerm
    );
  }

  const caseList = unfilteredCaseList ? unfilteredCaseList.filter(caseFilter) : [];
  const { userList } = useCaseAssignment();

  const [tableView, setTableView] = useState(true);
  const [newCaseModalOpen, setModalOpen] = useState(null);
  const [newCaseDetails, setNewCaseDetails] = useState(null);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const currentCaseID = useMemo(() => {
    return newCaseDetails?.id ?? newCaseDetails?.caseID ?? null;
  }, [newCaseDetails?.id, newCaseDetails?.caseID]);

  useEffect(() => {
    const view = window.localStorage.getItem('casesView');
    if (view === null || view === undefined) {
      window.localStorage.setItem('casesView', tableView);
    } else {
      setTableView(JSON.parse(view));
    }
  }, [tableView]);

  const onCreateCase = (newCase = null) => {
    setModalOpen(false);
    setNewCaseDetails(newCase);
    if (newCase) {
      if (location.pathname.indexOf('/cases') > -1) {
        navigate(`/case/${newCase.id}/files`);
      }
    }
  };

  const caseTableRefreshTimer = () => {
    setTimeout(() => {
      refreshCaseTable();
    }, 30000);
  };

  const refreshCaseTable = async () => {
    client.refetchQueries({
      include: [GetCasesDocument],
    });
  };

  caseTableRefreshTimer();

  const handleFilterPopperClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setFiltersOpen(false);
  };

  const renderCases = () => {
    if (error) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open
          autoHideDuration={3000}
        >
          <Alert severity="error">{error.message}</Alert>
        </Snackbar>
      );
    }

    if (loading || featureFlagsLoading) {
      return (
        <Box sx={myCasesStyles.loadingContainer}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box sx={myCasesStyles.casesContainer}>
        <Box
          sx={{
            justifyContent: 'space-between',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'primary.light', display: 'inline' }}
              aria-label="start new case"
              onClick={() => setModalOpen(true)}
              startIcon={
                <ControlPoint
                  fontSize="small"
                  sx={{ marginTop: '-0.1rem', marginRight: '0.2rem' }}
                />
              }
            >
              New Case
            </Button>
            {newCaseModalOpen && <CreateCase onClose={onCreateCase} open={newCaseModalOpen} />}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                padding: '.8rem',
                backgroundColor: 'selectedGrey',
              }}
            >
              <NewSearchBar
                searchStr={searchTerm}
                searchingInProgress={loading}
                handleChange={handleSearchTermChange}
                label="Search Cases"
                customStyles={{ flex: 90 }}
              />
            </Box>

            <Box
              ref={anchorRef}
              onClick={() => {
                setFiltersOpen(true);
              }}
              sx={{
                display: 'inline',
                whiteSpace: 'nowrap',
                width: '6.8rem',
                fontSize: '0.9rem',
                padding: '0.8rem',
                verticalAlign: 'middle',
                marginBottom: '0.5rem',
                borderRadius: 3,
                backgroundColor: 'white',
                fontWeight: 700,
                borderColor: 'primary.light',
                border: 'thin solid !important',
                outline: '1 !important',
                color: 'primary.light',
                cursor: 'pointer',
                '&:selected': {
                  borderColor: 'primary.light',
                  border: 'thin solid !important',
                },
                '&:active': {
                  borderColor: 'primary.light',
                  border: 'thin solid !important',
                },
                '&:focus': {
                  border: 'thin solid !important',
                  borderColor: 'primary.light',
                },
              }}
            >
              Filter
              <FilterList sx={{ display: 'inline', marginLeft: '0.5rem' }} />
            </Box>
            <CaseFilterPopover
              filtersOpen={filtersOpen}
              anchorRef={anchorRef}
              setFiltersOpen={setFiltersOpen}
              showAll={showAll}
              setShowAll={setShowAll}
              dueDate={dueDateFilter}
              setDueDateFilter={setDueDateFilter}
              showFilesRequired={showFilesRequired}
              setShowFilesRequired={setShowFilesRequired}
              showBeingProcessed={showBeingProcessed}
              setShowBeingProcessed={setShowBeingProcessed}
              showClosed={showClosed}
              showOpen={showOpen}
              setShowOpen={setShowOpen}
              setShowClosed={setShowClosed}
              handleFilterPopperClose={handleFilterPopperClose}
            />
            <IconButton sx={{ marginLeft: '0.5rem' }} onClick={() => refreshCaseTable()}>
              <Refresh sx={{ fontSize: '2rem' }} />
            </IconButton>
          </Box>
        </Box>
        <Box sx={myCasesStyles.displayContainer}>
          {caseList.length > 0 ? (
            <CaseTableView
              caseList={caseList}
              userList={userList}
              searchTerm={searchTerm}
              onUploadFilesClick={(caseInstance) => setNewCaseDetails(caseInstance)}
            />
          ) : (
            <Box sx={{ margin: 'auto' }}>
              <Typography variant="h5">No Cases</Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      {currentAnnouncement?.title &&
        (cookies[currentAnnouncement?.v] === 'show' || !cookies[currentAnnouncement?.v]) && (
          <Announcement
            open={true}
            link={currentAnnouncement?.link}
            announcementTitle={currentAnnouncement?.title}
            announcementBody={currentAnnouncement?.body}
            onClose={() => onCloseAnnouncement()}
          />
        )}
      {isNotChromeOrEdge &&
        (cookies[isNotChromeOrEdgeAnnouncement?.v] === 'show' ||
          !cookies[isNotChromeOrEdgeAnnouncement?.v]) && (
          <AnnouncementSafari
            open={true}
            announcementTitle={isNotChromeOrEdgeAnnouncement?.title}
            announcementBody={isNotChromeOrEdgeAnnouncement?.body}
            onClose={() => onCloseSaAnnouncement()}
          />
        )}
      {renderCases()}
    </>
  );
}

export default MyCases;
