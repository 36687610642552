import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Tooltip } from '@mui/material';

import './document-grouping-container.css';
import NavBar from '../../components/common/PdfNavigation/NavBar';
import DocumentScroller from '../Case/DocumentScroller';
import Loading from '../../components/common/Loading';
import CaseContext from '../Case/CaseContext';
import { useTimelineList } from '../Timeline/useTimeline';
import CaseHeader from '../Timeline/CaseHeader';
import { getCaseFile, PageObject } from '../../api';
import { useAsync } from '../../hooks/useAsync';
import UpArrowWithTail from '../../components/icons/UpArrowWithTail';
import { useGroupings } from './utils/documentState';
import Modal from '../../components/common/HTML_components/Modal/Modal';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';
import GroupingLock from './GroupingLock';

export default function DocumentGroupingContainer() {
  const { fileID: caseFileID, pageID, caseID } = useParams();
  const logUserActivity = useActivityLog();
  const [caseFile, refetchCaseFile] = useAsync(
    () => getCaseFile(caseID ?? '', caseFileID ?? ''),
    [caseID, caseFileID],
  );

  const { caseInstance } = useContext(CaseContext);
  const { data: timelineList } = useTimelineList(caseID);
  const [documentScrollerCompatiblePages, setDocumentScrollerCompatiblePages] = useState<
    DocumentScrollerCompatiblePage[]
  >([]);
  const [isMergeAllModalOpen, setIsMergeAllModalOpen] = useState(false);
  const [isBuildGroupingsModalOpen, setIsBuildGroupingsModalOpen] = useState(false);
  const navigate = useNavigate();

  const qAorProcessor: 'QA' | 'Processor' | undefined = useMemo(() => {
    if (caseFile.status !== 'resolved') {
      return undefined;
    }
    if (
      caseFile.data.data?.file_status === 'QA_REQUIRED' ||
      caseFile.data.data?.file_status === 'APPROVED'
    ) {
      return 'QA';
    }
    return 'Processor';
  }, [caseFile]);

  const timelineID = timelineList?.find((timeline) => timeline.isDefault).id;

  const {
    status,
    pages,
    filteredPages,
    refreshPages,
    parseError,
    canSplitAt,
    splitAt,
    canMergeAt,
    mergeAt,
    canAttachAt,
    attachAt,
    canDetachAt,
    detachAt,
    depthAt,
    pageIndexById,
    groupIDByPageID,
    attachmentIDByPageID,
    buildGroupings,
    resetEdges,
    canBuildGroupings,
  } = useGroupings(caseID ?? '', caseFileID ?? '');

  useEffect(() => {
    if (pages.length > 0 && !pageID) {
      const firstPageID = pages[0].id;
      navigate(`${firstPageID}`);
    }
  }, [pages]);

  useEffect(() => {
    const formattedPages = filteredPages?.map((page) => {
      return {
        ...page,
        id: Number(page.id),
        documentID: page.file_id,
        pageNumber: page.page_number,
      };
    });
    setDocumentScrollerCompatiblePages(formattedPages ?? []);
  }, [filteredPages]);

  useEffect(() => {
    if (caseID && caseFileID) {
      logUserActivity({
        activity: 'case:grouping',
        case_id: caseID,
        file_id: caseFileID,
      });
    }
  }, [caseID, caseFileID]);

  const groupingActionOnClicks: GroupingActionOnClicks = {
    splitAt,
    mergeAt,
    attachAt,
    detachAt,
  };

  const getButtonEnabledStatuses: GroupingActionStatuses = {
    canSplitAt,
    canMergeAt,
    canAttachAt,
    canDetachAt,
  };

  const { currentPageIndex, currentPage } = useMemo(() => {
    if (!pageIndexById || !pageID) {
      return { currentPageIndex: 0, currentPage: undefined };
    }

    const currentPageIndex = pageIndexById.get(pageID) ?? 0;
    const currentPage = pages?.[currentPageIndex];
    return { currentPageIndex, currentPage };
  }, [pageIndexById, pageID, pages]);

  if (!pageID || pages == null || pages.length === 0) {
    return <Loading />;
  }

  const onChangePage = (nextIndex: number) => {
    const newPage = pages?.[nextIndex - 1];
    navigate(`${newPage?.id}`);
  };

  const resetEdgesOnClick = async () => {
    if (qAorProcessor === undefined || pages.length === 0) {
      return;
    }

    const start_page = pages[0].page_number;
    const end_page = pages[pages.length - 1].page_number;

    setIsMergeAllModalOpen(false);

    await resetEdges(qAorProcessor, start_page, end_page);
  };

  const buildGroupingsOnClick = async () => {
    if (currentPage && pages[0]) {
      setIsBuildGroupingsModalOpen(false);

      const { status: buildGroupingsStatus } = await buildGroupings(pages[0].page_number, currentPage.page_number);
      if (buildGroupingsStatus === 'rejected') {
        return;
      }
      if (currentPageIndex === pages.length - 1) {
        navigate(`../files`);
      } else {
        navigate(`../files/${caseFileID}/grouping`);
      }
      refetchCaseFile();
    }
  };

  return (
    <GroupingLock caseID={caseID} fileID={caseFileID}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          height: '100%',
        }}
      >
        <Modal isOpen={isMergeAllModalOpen} onClose={() => setIsMergeAllModalOpen(false)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '1rem',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: '1.25rem',
                fontWeight: 500,
                color: 'var(--color-text-primary)',
                marginBottom: '1rem',
              }}
            >
              Merge All Document Groupings?
            </span>
            <span>
              This action will remove all current groupings and merge all pages into a single
              document. Would you like to proceed?
            </span>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: '1rem',
              }}
            >
              <button
                type="button"
                onClick={() => setIsMergeAllModalOpen(false)}
                className="sm-button"
                style={{
                  marginRight: '1rem',
                }}
              >
                Cancel
              </button>
              <button
                onClick={resetEdgesOnClick}
                type="button"
                className="sm-button sm-button-primary"
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={isBuildGroupingsModalOpen}
          onClose={() => setIsBuildGroupingsModalOpen(false)}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '1rem',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: '1.25rem',
                fontWeight: 500,
                color: 'var(--color-text-primary)',
                marginBottom: '1rem',
              }}
            >
              Build Groupings for File?
            </span>
            {currentPageIndex === pages.length - 1 ? (
              <span>
                Building groupings will change any documents in the review tab up to this point in
                the file to reflect the new groupings. This will complete grouping on this file.
                Would you like to proceed?
              </span>
            ) : (
              <span>
                Building groupings will change any documents in the review tab up to this point in
                the file to reflect the new groupings. Would you like to proceed?
              </span>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: '1rem',
              }}
            >
              <button
                type="button"
                onClick={() => setIsBuildGroupingsModalOpen(false)}
                className="sm-button"
                style={{
                  marginRight: '1rem',
                }}
              >
                Cancel
              </button>
              <button
                onClick={buildGroupingsOnClick}
                type="button"
                className="sm-button sm-button-primary"
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
        <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
        <div className="document-grouping-container" id="document-display">
          <NavBar
            currentPage={currentPageIndex + 1}
            page={currentPage}
            numberOfPages={pages ? pages.length : 0}
            onChangePage={onChangePage}
            onPreviousButtonClick={() => {}}
            onNextButtonClick={() => {}}
            nextButtonDisabled={true}
            previousButtonDisabled={true}
            showContentToolbar={false}
            showFileProcessorToolbar={false}
            showZoomRotateToolbar={true}
          />
          <span
            className="sm-back-button"
            onClick={() => {
              navigate('../files');
            }}
            style={{ width: 'fit-content', padding: '0.5rem' }}
          >
            <UpArrowWithTail
              style={{
                color: '#1E407D',
                marginRight: '0.5rem',
              }}
              transform="rotate(270)"
            />
            <span className="sm-back-button-text sm-button-text">Back to files</span>
          </span>
          <DocumentScroller
            pages={documentScrollerCompatiblePages ?? []}
            searchResults={[]}
            currentMode="grouping"
            dismissDocumentEdge={() => {}}
            pageDepthByPageID={depthAt}
            groupingActionOnClicks={groupingActionOnClicks}
            isGrouping
            getGroupingButtonDisabledStatuses={getButtonEnabledStatuses}
            groupIDByPageID={groupIDByPageID}
            attachmentIDByPageID={attachmentIDByPageID}
          />
        </div>

        <Tooltip title="Reset all document edges for file.">
          <div
            style={{
              position: 'fixed',
              top: 63,
              right: 0,
              width: 'fit-content',
              marginRight: '2rem',
              marginBottom: '0.5rem',
            }}
          >
            {!(status === 'staged' || status === 'saving') && (
              <button
                type="button"
                className="sm-button"
                disabled={status === 'loading'}
                onClick={() => setIsMergeAllModalOpen(true)}
                style={{
                  backgroundColor: 'var(--color-primary-main)',
                  color: 'white',
                }}
              >
                Merge All
              </button>
            )}
          </div>
        </Tooltip>

        <Tooltip title="Finalize file groupings">
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              right: 0,
              width: 'fit-content',
              marginRight: '2rem',
              marginBottom: '0.5rem',
            }}
          >
            {status === 'staged' || status === 'saving' ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
                <span
                  style={{
                    marginLeft: '0.5rem',
                  }}
                >
                  {' '}
                  Update In Progress
                </span>
              </div>
            ) : (
              <button
                type="button"
                className="sm-button"
                disabled={
                  status === 'loading' || status === 'errored' || !canBuildGroupings(pageID)
                }
                onClick={() => setIsBuildGroupingsModalOpen(true)}
                style={{
                  backgroundColor: 'var(--color-primary-main)',
                  color: 'white',
                  opacity: !canBuildGroupings(pageID) ? 0.5 : 1,
                }}
              >
                Mark Previous Documents as Done
              </button>
            )}
          </div>
        </Tooltip>
      </div>
    </GroupingLock>
  );
}

export type GroupingActionOnClicks = {
  splitAt: (pageID: string) => void;
  mergeAt: (pageID: string) => void;
  attachAt: (pageID: string) => void;
  detachAt: (pageID: string) => void;
};

export type GroupingActionStatuses = {
  canSplitAt: (pageID: string) => boolean;
  canMergeAt: (pageID: string) => boolean;
  canAttachAt: (pageID: string) => boolean;
  canDetachAt: (pageID: string) => boolean;
};

export type DocumentScrollerCompatiblePage = Omit<PageObject, 'id' | 'file_id' | 'page_number'> & {
  id: number;
  documentID: string;
  pageNumber: number;
};
