export default function convertEnumToFormattedString(enumVal) {
  if (Object.prototype.hasOwnProperty.call(specialFormatting, enumVal)) {
    return specialFormatting[enumVal];
  }
  const words = enumVal?.split('_');
  const capitalizedWords = words?.map((word) => {
    if (word.includes('OCF-') || word.includes('AB-') || word === 'OHIP') {
      return word;
    }
    // eslint-disable-next-line no-param-reassign
    word = word.toLowerCase();
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords?.join(' ');
}

const specialFormatting = {
  OTOLARYNGOLOGIST: 'ENT',
  COLLABERATIVE_CARE_TEAM: 'Collaborative Care Team',
  PHYSIOTHERPY: 'Physiotherapy',
  X_RAY: 'X-Ray',
  ECG: 'ECG',
  PFT: 'PFT',
  EMG: 'EMG',
  EEG: 'EEG',
  MRI: 'MRI',
  CT: 'CT',
  DIME: 'DIME',
  PET_SCAN: 'PET Scan',
  GP_CLINIC_NOTE: 'GP Clinic Note',
  WSIB_FORM: 'WSIB Form',
  ER_REPORT: 'ER Report',
  'GENERAL_PRACTITIONER_(GP)': 'General Practioner (GP)',
};
