import { useMemo } from 'react';
import useFeatureFlags from '../../config/useFeatureFlags';
import { PageType } from '../Page/types/pageTypes';
import { TimelineEntry as TimelineEntryType } from './types/timelineTypes';
import { DocumentTag } from '../../api';

type MissingInfoCheckerProps = {
  entry: TimelineEntryType;
  pages: PageType[];
  documentTags: DocumentTag[];
};

type MissingInfoResult = {
  count: number;
  fields: string[];
};

const MDocRules = ({ entry, pages, documentTags }: MissingInfoCheckerProps): MissingInfoResult => {
  const { data: featureFlagsData } = useFeatureFlags();
  const isAuthorExtractionEnabled = featureFlagsData?.AuthorExtractionEnabled ?? false;

  const { count, fields } = useMemo(() => {
    let missingInfoCount = 0;
    const missingFields: string[] = [];

    if (!entry?.author_id && isAuthorExtractionEnabled) {
      missingInfoCount++;
      missingFields.push('No author specified for this document.');
    }

    if (!entry?.org_id && isAuthorExtractionEnabled) {
      missingInfoCount++;
      missingFields.push('No organization specified for this document.');
    }

    const coverSheetTag =
      pages.length === 1 &&
      pages[0].tags.find(
        (tag: { id: number; name: string; type: string; origin: string }) =>
          tag.id === 142 ||
          (tag.name === 'COVER_SHEET' && tag.type === 'content_type' && tag.origin === 'siftmed'),
      );

    if (documentTags.length === 0) {
      missingInfoCount++;
      // Checks for a document tag if a page is tagged as a cover sheet
      if (coverSheetTag) {
        missingFields.push('The document contains only a cover sheet with no other content.');
      } else {
        missingFields.push('All pages must have at least one document tag.');
      }
    }

    // Check for other document types when an OCF tag is applied
    const hasOtherDocumentTypes = documentTags.some(
      (tags) => !tags.name.toLowerCase().includes('ocf'),
    );
    const uniqueOCFTags = documentTags.filter((tag) => tag.name.toLowerCase().includes('ocf'));
    if (hasOtherDocumentTypes && uniqueOCFTags.length) {
      missingInfoCount++;
      missingFields.push('Cannot have other document types when OCF is selected.');
    }

    if (uniqueOCFTags.length > 1) {
      missingInfoCount++;
      missingFields.push('Cannot select more than one OCF-tag.');
    }

    return { count: missingInfoCount, fields: missingFields };
  }, [entry, pages, isAuthorExtractionEnabled]);

  return { count, fields };
};

export default MDocRules;
export type { MissingInfoResult };
