import { Button, Tooltip } from '@mui/material';
import { Remove, Add } from '@mui/icons-material';
import IconNewLock from '../../components/icons/IconLock';
import IconUnlock from '../../components/icons/IconUnlock';

import NavigationButton from '../../components/common/PdfNavigation/Components/NavigationButton';
import Theme from '../../theme';

type DocumentCompareHeaderProps = {
  notDuplicate: () => void;
  hideResolved: boolean;
  currentSimilarityStatus: 'Duplicate' | 'NotDuplicate' | 'Unset';
};

export default function DocumentCompareHeader({
  notDuplicate,
  hideResolved,
  currentSimilarityStatus,
}: DocumentCompareHeaderProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
      }}
    >
      <span>
        {hideResolved
          ? 'Please select a duplicate document or click “Mark as Not Duplicate”'
          : 'Please review resolved duplicates to make changes if necessary'}
      </span>
      <Button
        sx={{
          backgroundColor: 'caseStatus.processorActionButtonBackground',
          color: 'primary.dark',
          border: '1px solid',
          borderColor: 'caseStatus.processorActionButtonBackground',
          fontFamily: 'Inter',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '18px',
          margin: '8px',
        }}
        onClick={notDuplicate}
        disabled={currentSimilarityStatus === 'NotDuplicate'}
      >
        Mark as Not Duplicate
      </Button>
    </div>
  );
}

type ToolbarZoomProps = {
  zoom: number;
  setZoom: (newZoom: number) => void;
  zoomFactor?: number;
  zoomMax?: number;
  zoomMin?: number;
  style?: {};
};

export function ToolbarZoom({
  zoom,
  setZoom,
  zoomFactor = 0.1,
  zoomMax = 2,
  zoomMin = 0.5,
  style,
}: ToolbarZoomProps) {
  return (
    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          ...style,
        }}
      >
        <NavigationButton
          id="navbar-zoom-in"
          onClick={() => setZoom(zoom + zoomFactor)}
          sx={{
            borderRadius: '5px 5px 0px 0px',
            minWidth: '41px',
            minHeight: '41px',
            padding: '0',
            boxSizing: 'border-box',
            border: '1px solid',
            borderColor: Theme.palette.borderGrey.main,
          }}
          icon={
            <Add
              sx={{
                fontSize: '1rem',
                color: zoom >= zoomMax ? 'inherit' : '#344054',
              }}
            />
          }
          disabled={zoom >= zoomMax}
          position="bottom"
        />
        <div
          style={{
            border: '1px solid',
            borderColor: Theme.palette.borderGrey.main,
            fontSize: '0.6rem',
            fontWeight: 600,
            padding: '4px 5px',
            lineHeight: '2rem',
            textAlign: 'center',
            minWidth: '41px',
            boxSizing: 'border-box',
            borderTop: 'none',
            borderBottom: 'none',
          }}
        >
          {Math.round(zoom * 100)}%
        </div>
        <NavigationButton
          id="navbar-zoom-out"
          onClick={() => setZoom(zoom - zoomFactor)}
          sx={{
            borderRadius: ' 0px 0px 5px 5px',
            minWidth: '41px',
            minHeight: '41px',
            padding: '0',
            boxSizing: 'border-box',
            border: '1px solid',
            borderColor: Theme.palette.borderGrey.main,
          }}
          icon={
            <Remove
              sx={{
                fontSize: '1rem',
                color: zoom <= zoomMin ? 'inherit' : '#344054',
              }}
            />
          }
          disabled={zoom <= zoomMin}
          position="top"
        />
      </div>
    </div>
  );
}

export function ZoomAndToolbar({
  zoom,
  setZoom,
  areDocumentScrollsSynced,
  setAreDocumentScrollsSynced,
}: Zoomandtoolbar) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: '3.125rem',
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
        alignItems: 'center',
      }}
    >
      <div>
        <ToolbarZoom zoom={zoom} setZoom={setZoom} />
        <Tooltip title="Sync Document Scrolling">
          <Button
            onClick={() => setAreDocumentScrollsSynced(!areDocumentScrollsSynced)}
            sx={{
              marginTop: '.5rem',
              marginBottom: 'auto',
              lineHeight: 1,
              backgroundColor: areDocumentScrollsSynced ? 'primary.main' : 'white',
              color: areDocumentScrollsSynced ? 'white' : 'primary.main',
              border: '1px solid',
              borderColor: 'primary.main',
              minWidth: '41px',
              height: '41px',
              padding: '0',
              boxSizing: 'border-box',
            }}
          >
            {areDocumentScrollsSynced ? <IconNewLock /> : <IconUnlock />}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}

type Zoomandtoolbar = {
  zoom: number;
  setZoom: (newZoom: number) => void;
  areDocumentScrollsSynced: boolean;
  setAreDocumentScrollsSynced: (synced: boolean) => void;
};
