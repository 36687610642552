import { useEffect, useMemo, useRef } from 'react';
import { isEqual } from 'lodash';
import useDocumentSearchStore from '../useDocumentSearchStore';
import { useSearchQuery } from '../../../library/utilities/useSearch';
import {
  combinePageWhereInputWithKeywordSearchResult,
  createAllowedTagIdsFromFilters,
  createKeywordSearchFilter,
  createOrderByFromFilters,
  createPageWhereInputFromFilters,
  isTagFilterApplied,
  processorKeywordSearchFilter,
} from './utils/timelineGqlUtils';
import {
  TimelineEntryWhereInput,
  useGetTimelineEntriesDescriptorsQuery,
} from '../../../__generated__/graphql';
import { ListPagesSortByEnum } from '../types/timelineTypes';
import { isEmpty } from '../../../utils/other';
import { useUser } from '../../../library/contexts/AuthContext';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import { DocumentTagFilter } from '../../../api';

type UseTimelineEntryDescriptorsParams = {
  timelineID: string;
  caseID: string;
  documentID?: string[];
  startPageNumber?: number;
  endPageNumber?: number;
};

export const useTimelineEntryDescriptors = (params: UseTimelineEntryDescriptorsParams) => {
  const { caseID, timelineID, documentID, startPageNumber, endPageNumber } = params;

  const prevDocumentIDRef = useRef('');
  const prevFiltersRef = useRef();

  const auth = useUser();
  const isFileProcessor = useIsFileProcessor();

  const [filters, searchStr] = useDocumentSearchStore((state) => [state.filters, state.searchStr]);
  const keywordSearchResult = useSearchQuery(caseID, searchStr, (value: any) => value);

  const keywordSearchFilter = useMemo(
    () => createKeywordSearchFilter(keywordSearchResult.data),
    [keywordSearchResult.data],
  );

  const pagesWhere = useMemo(() => {
    const pageWhere = createPageWhereInputFromFilters(filters, auth.userId);

    if (isFileProcessor) {
      const keywordSearchWhere = processorKeywordSearchFilter(keywordSearchFilter);

      return { ...keywordSearchWhere };
    }

    const keywordSearchWhere = combinePageWhereInputWithKeywordSearchResult(
      pageWhere,
      keywordSearchFilter,
    );
    return {
      ...pageWhere,
      ...keywordSearchWhere,
    };
  }, [filters, keywordSearchFilter, isFileProcessor]);
  const timelineEntriesWhere = useMemo((): TimelineEntryWhereInput => {
    if (isFileProcessor) {
      return {
        timeline_id: timelineID,
        document_id: documentID ?? null,
        startPageNumber: startPageNumber ?? null,
        endPageNumber: endPageNumber ?? null,
        keyword_search_result: {
          keyword: searchStr,
          matches: keywordSearchFilter.matches,
          total_results: keywordSearchFilter.total_results as number,
        },
      };
    }
    const allowedSources = filters?.sources?.map((item: DocumentTagFilter) => item.tag_id) ?? null;

    const allowedTagIds = isTagFilterApplied(filters)
      ? createAllowedTagIdsFromFilters(filters)
      : null;
    return {
      allowedDocumentTags: allowedTagIds,
      allowedSourceTags: allowedSources,
      showFavourited: filters?.showFavourited,
      hideViewed: filters?.hideViewed,
      hideDuplicates: filters?.hideDuplicates,
      beforeDate: filters?.beforeDate ?? null,
      afterDate: filters?.afterDate ?? null,
      timeline_id: timelineID,
      document_id: filters?.documentID ?? null,
      keyword_search_result: {
        keyword: searchStr,
        matches: keywordSearchFilter.matches,
        total_results: keywordSearchFilter.total_results as number,
      },
    };
  }, [
    filters,
    timelineID,
    searchStr,
    keywordSearchFilter,
    isFileProcessor,
    documentID,
    startPageNumber,
    endPageNumber,
  ]);

  const fetchPolicy = useMemo(() => {
    if (
      documentID !== prevDocumentIDRef.current ||
      (filters && !isEqual(filters, prevFiltersRef.current))
    ) {
      return 'network-only';
    }
    return 'cache-first';
  }, [documentID, filters]);

  const query = useGetTimelineEntriesDescriptorsQuery({
    variables: {
      timelineEntriesWhere,
      pagesWhere,
      timelineEntriesOrderBy: createOrderByFromFilters(
        isFileProcessor ? { documentOrder: ListPagesSortByEnum.Document } : filters,
      ),
    },
    fetchPolicy,
    skip: isEmpty(timelineID) || isFileProcessor == null,
  });

  useEffect(() => {
    if (documentID !== prevDocumentIDRef.current) {
      prevDocumentIDRef.current = documentID;
    }
  }, [documentID]);

  useEffect(() => {
    if (filters && !isEqual(filters, prevFiltersRef.current)) {
      prevFiltersRef.current = filters;
    }
  }, [filters]);

  return query;
};
