import React, { useState } from 'react';
import { Box, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OverflowText from '../common/OverflowText';
import AnchoredDropdownMenu from '../common/HTML_components/AnchoredDropdownMenu/AnchoredDropdownMenu';
import LinkWithQuery from '../../containers/Timeline/LinkWithQuery';
import useDocumentSearchStore from '../../containers/Timeline/useDocumentSearchStore';

type Props = {
  isEditing: boolean;
  editedFileName: string;
  setEditedFileName: (value: string) => void;
  handleRenameFile: (selectedFile: File, e: any) => void;
  fileStatus: string;
  isFileProcessor: boolean;
  fileName: string;
  fileID: string;
  timelineID: number;
  handleFileStatusChange: (fileID: string, fileStatus: string) => void;
  selectedFile: File;
};

type File = {
  authorStatus: string;
  fileStatus: string;
  id: string;
  name: string;
  numOfPages: number;
  refID: number;
  type: string;
  uploadDate: string;
} | null;

export default function FilesTableNameCell({
  isEditing,
  editedFileName,
  setEditedFileName,
  handleRenameFile,
  fileStatus,
  isFileProcessor,
  fileName,
  fileID,
  timelineID,
  handleFileStatusChange,
  selectedFile,
}: Props) {
  const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const componentRef = React.useRef(null);

  const [filters, setFilters] = useDocumentSearchStore((state) => [
    state.filters,
    state.setFilters,
  ]);

  const navigateToReviewTab = () => {
    if (fileStatus === 'PENDING') {
      handleFileStatusChange(fileID, fileStatus);
    }
    if (!isFileProcessor) {
      setFilters({
        ...filters,
        documentID: [fileID],
      });
      navigate(`../timeline/${timelineID}`);
    } else {
      navigate(`../timeline/${timelineID}?documentID=${fileID}`);
    }
  };

  const navigateToGroupingTab = async () => {
    if (fileStatus === 'PENDING') {
      handleFileStatusChange(fileID, fileStatus);
    }
    navigate(`${fileID}/grouping`);
  };

  const navigateToTaggingTab = () => {
    navigate(`tagging?fileID=${fileID}`);
  };

  const options = [
    {
      label: 'Review Tab',
      onClick: navigateToReviewTab,
    },
    ...(fileStatus === 'GROUPING' || fileStatus === 'PENDING'
      ? [
          {
            label: 'Grouping Tab',
            onClick: navigateToGroupingTab,
          },
        ]
      : []),
    ...(fileStatus !== 'PENDING'
      ? [
          {
            label: 'Tagging Tab',
            onClick: navigateToTaggingTab,
          },
        ]
      : []),
  ];

  if (!isFileProcessor) {
    return (
      <Stack direction="row" alignItems="center" sx={{ width: '80%' }}>
        {isEditing ? (
          <TextField
            defaultValue={editedFileName}
            onChange={(e) => setEditedFileName(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'Enter') {
                handleRenameFile(selectedFile, e.target.value);
              }
            }}
            autoFocus
            sx={{ mt: 0.8 }}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              '&:hover': {
                '& svg': {
                  opacity: 1,
                },
              },
            }}
          >
            {fileStatus === 'COMPLETE' ? (
              <LinkWithQuery
                to={`../timeline/${timelineID}`}
                documentID={isFileProcessor ? fileID : null}
                onClick={navigateToReviewTab}
              >
                <OverflowText>{fileName}</OverflowText>
              </LinkWithQuery>
            ) : (
              fileName
            )}
          </Box>
        )}
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" sx={{ width: '80%' }}>
      {isEditing ? (
        <TextField
          defaultValue={editedFileName}
          onChange={(e) => setEditedFileName(e.target.value)}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === 'Enter') {
              handleRenameFile(selectedFile);
            }
          }}
          autoFocus
          sx={{ mt: 0.8 }}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            '&:hover': {
              '& svg': {
                opacity: 1,
              },
            },
          }}
        >
          {fileStatus === 'COMPLETE' || (isFileProcessor && fileStatus !== 'UPLOADING') ? (
            <span ref={componentRef} onClick={() => setIsMenuDropdownOpen(!isMenuDropdownOpen)}>
              <OverflowText
                sx={{
                  color: 'var(--color-link-blue)',
                }}
              >
                <span
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {fileName}
                </span>
              </OverflowText>
              <AnchoredDropdownMenu anchorComponentRef={componentRef} options={options} />
            </span>
          ) : (
            fileName
          )}
        </Box>
      )}
    </Stack>
  );
}
