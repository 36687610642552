import { Popover, IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Theme from '../../theme';

export default function CaseProgressDialog({
  open,
  setOpen,
  position,
  files,
}: {
  open: boolean;
  setOpen: (set: boolean) => void;
  position: { top: number; left: number };
  files: {
    total: number;
    notStarted: number;
    complete: number;
    inProgress: number;
    grouping: number;
    tagging: number;
    qaRequired: number;
    currentPageCount: number;
  };
}) {
  return (
    <Popover
      open={open}
      onClose={() => setOpen(false)}
      sx={{ position: 'absolute', top: position.top, left: position.left }}
    >
      <div style={{ width: '400px', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px',
          }}
        >
          <div style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '1rem' }}>
            Case Progress
          </div>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '20px',
            marginTop: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexBasis: 'calc(33.33% - 20px)',
            }}
          >
            Total Files
            <div
              style={{
                fontSize: '40px',
                fontWeight: 'bold',
                color: Theme.palette.caseStatus.processorActionButtonText,
              }}
            >
              {files?.total}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexBasis: 'calc(33.33% - 20px)',
            }}
          >
            Not Started
            <div
              style={{ fontSize: '40px', fontWeight: 'bold', color: Theme.palette.status.error }}
            >
              {files?.notStarted}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexBasis: 'calc(33.33% - 20px)',
            }}
          >
            Complete
            <div
              style={{ fontSize: '40px', fontWeight: 'bold', color: Theme.palette.status.success }}
            >
              {files?.complete}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flexBasis: '90%',
            }}
          >
            <div>
              <div style={{ textAlign: 'center', marginLeft: '18px' }}>Files In Progress</div>
              <div
                style={{
                  fontSize: '40px',
                  fontWeight: 'bold',
                  color: Theme.palette.caseStatus.processorActionButtonText,
                  textAlign: 'center',
                }}
              >
                {files?.inProgress}
              </div>
            </div>
            <div>
              <div style={{ textAlign: 'center', marginLeft: '18px' }}>Pages Processing</div>
              <div
                style={{
                  fontSize: '40px',
                  fontWeight: 'bold',
                  color: Theme.palette.caseStatus.processorActionButtonText,
                  textAlign: 'center',
                }}
              >
                {files?.currentPageCount}
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexBasis: 'calc(33.33% - 20px)',
              marginBottom: '20px',
              fontSize: '.85rem',
            }}
          >
            Grouping
            <div
              style={{ fontSize: '30px', fontWeight: 'bold', color: Theme.palette.status.warning }}
            >
              {files?.grouping}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexBasis: 'calc(33.33% - 20px)',
              marginBottom: '20px',
              fontSize: '.85rem',
            }}
          >
            Tagging
            <div
              style={{ fontSize: '30px', fontWeight: 'bold', color: Theme.palette.status.warning }}
            >
              {files?.tagging}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexBasis: 'calc(33.33% - 20px)',
              marginBottom: '20px',
              fontSize: '.85rem',
            }}
          >
            QA Required
            <div
              style={{ fontSize: '30px', fontWeight: 'bold', color: Theme.palette.status.success }}
            >
              {files?.qaRequired}
            </div>
          </div>
        </div>
      </div>
    </Popover>
  );
}
