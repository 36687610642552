import { useQuery } from '@tanstack/react-query';
import { getCaseFiles, FileRawObject, FileStatus } from '../../api';

async function fetchFilesStatus(caseID: string) {
  const files = (await getCaseFiles(caseID)).data as FileRawObject[];
  return countFilesStatus(files);
}

export default function useCaseFilesStatus(caseID: string) {
  return useQuery(['filesStatus', caseID], () => fetchFilesStatus(caseID), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
    enabled: !!caseID,
  });
}

const countFilesStatus = (files: FileRawObject[]) => {
  const counts = files.reduce(
    (acc, file) => {
      const { file_status: fileStatus, number_of_pages: numberOfPages } = file;
      if (acc[fileStatus]) {
        acc[fileStatus] += 1;
      } else {
        acc[fileStatus] = 1;
      }
      if (fileStatus !== 'COMPLETE') {
        acc.pageCount += numberOfPages ?? 0;
      }
      return acc;
    },
    { pageCount: 0 } as Record<FileStatus, number> & { pageCount: number },
  );

  return {
    total: files.length,
    notStarted: (counts.UPLOADING ?? 0) + (counts.PENDING ?? 0),
    complete: (counts.COMPLETE ?? 0) + (counts.APPROVED ?? 0),
    inProgress: (counts.GROUPING ?? 0) + (counts.TAGGING ?? 0) + (counts.QA_REQUIRED ?? 0),
    grouping: counts.GROUPING ?? 0,
    tagging: counts.TAGGING ?? 0,
    qaRequired: counts.QA_REQUIRED ?? 0,
    currentPageCount: counts.pageCount ?? 0,
  };
};
